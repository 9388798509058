<template>
  <auth-layout>
    <template v-slot:header>
      <div class="text-center">
        <i v-if="!email_sent" class="fas fa-lock header-icon" />
        <i v-else class="fas fa-envelope header-icon" />
      </div>
    </template>
    <template v-if="!email_sent">
      <h4 class="text-center">{{ $t("auth.title-reset-password") }}</h4>
      <p class="border rounded border-secondary p-2 mt-3 bg-light">
        {{ $t(`auth.reset-note-1`) }}
      </p>
      <form @submit.prevent="submit">
        <div>
          <text-field
            :label="$t(`auth.label-email`)"
            type="email"
            v-model="data.email"
            placeholder="e.g. username@mail.com"
            required
            @input="error_msg = ''"
            :error="error_msg"
          />
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary btn-min">
            {{ $t(`auth.button-reset`) }}
          </button>
        </div>
      </form>
    </template>
    <div v-else>
      <h4 class="text-center">{{ $t("auth.title-reset-email-sent") }}</h4>
      <p>{{ $t(`auth.reset-email-sent-title`) }}</p>
      <div class="border rounded border-secondary p-2 mt-3 bg-light">
        <ol class="mb-0">
          <li>{{ $t(`auth.reset-email-note-1`) }}</li>
          <li>{{ $t(`auth.reset-email-note-2`) }}</li>
        </ol>
      </div>
    </div>
    <template v-slot:footer>
      <div class="d-flex justify-content-around px-3 mt-n1">
        <router-link class="small" to="login">{{
          $t(`auth.label-login`)
        }}</router-link>
      </div>
    </template>
  </auth-layout>
</template>

<script>
import authApi from "@/api/auth";
import AuthLayout from "@/layouts/AuthLayout";
import TextField from "@/components/form-fields/TextField";

export default {
  components: { AuthLayout, TextField },
  data() {
    return {
      error_msg: "",
      email_sent: false,
      data: {
        email: ""
      }
    };
  },
  methods: {
    async submit() {
      try {
        const reset = await authApi.createReset(this.data);
        if (!reset.status.success) {
          if (!reset.status.exists) {
            this.error_msg = "  ";
            this.$toast.error(this.$t("auth.non-exists-email"), {
              position: "top"
            });
          } else {
            this.$toast.error(this.$t("common.error-generic"), {
              position: "top"
            });
          }
          return;
        }
        this.email_sent = true;
      } catch (e) {
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    }
  }
};
</script>
