<template>
  <div>
    <h1 class="h3 mb-2 text-gray-800">
      {{ $t("dashboard-common.title-localized-filter") }}
    </h1>
    <p>{{ $t("dashboard-filter.instruction") }}</p>
    <form @submit.prevent="saveFilter">
      <div class="px-3 pt-3 pb-2 mb-3 border rounded">
        <div>
          <span>
            {{ $t("dashboard-filter.label-on-off") }}
          </span>
          <toggle-button
            :disabled="!hasAccess('filter_setting.localized_filter.edit')"
            :labels="{ checked: 'ON', unchecked: 'OFF' }"
            :sync="true"
            :width="70"
            v-model="local.value.visibility"
            :color="{checked: $theme.purple, unchecked: $theme.red}"
            class="ml-1"
          />
        </div>
        <table class="table-list">
          <tbody>
          <template v-if="local.facility_localized_category.length < 1">
            <tr>
              <td colspan="3">
                <b-alert variant="secondary" show>
                  {{ $t("common.word-empty") }}
                </b-alert>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="px-0">
                <ul class="filter-list mb-0">
                  <li
                    v-for="(filter, index) in local.facility_localized_category"
                    :key="`fl_${index}`"
                    class="py-1"
                  >
                    <div class="no-wrap filter-block">
                      {{ $t("common.word-filter") }} # {{ index + 1 }}
                    </div>
                  </li>
                </ul>
              </td>
              <td class="px-0">
                <draggable 
                  tag="ul"
                  ghost-class="drag-ghost"
                  handle=".drag-handle"
                  v-model="local.facility_localized_category"
                  forceFallback="true"
                  class="filter-list mb-0"
                  @change="changeOrder"
                >
                  <li
                    v-for="(filter, index) in local.facility_localized_category"
                    :key="`fi_${index}`"
                    class="py-1"
                  >
                    <div class="no-wrap filter-block">
                      <template
                        v-if="local.value.visibility && hasAccess('filter_setting.localized_filter.edit')"
                      >
                        <span class="fas fa-grip-vertical drag-handle" />
                      </template>
                      <span v-else class="fas fa-grip-vertical" style="opacity: 0; margin-right: 5px" />
                      <input
                        type="text"
                        class="form-control filter-text mr-1"
                        v-model="filter.name"
                        maxlength="32"
                        @input="validateInput"
                        required
                        :id="`lfilter_${index}`"
                        :disabled="filter.status !== 1 || !local.value.visibility"
                        :readonly="!hasAccess('filter_setting.localized_filter.edit')"
                      />
                      <button
                        v-if="filter.id && filter.status !== 1"
                        type="button"
                        title="Restore"
                        class="btn btn-round btn-outline-success d-inline-block"
                        :disabled="
                          !local.value.visibility ||
                          !hasAccess('filter_setting.localized_filter.edit')
                        "
                        @click="() => restoreFilter(index)"
                      >
                        <i class="fas fa-plus" />
                      </button>
                      <button
                        v-else
                        type="button"
                        title="Remove"
                        class="btn btn-round btn-outline-danger d-inline-block"
                        :disabled="
                          !local.value.visibility ||
                          !hasAccess('filter_setting.localized_filter.edit')
                        "
                        @click="() => removeFilter(index)"
                      >
                        <i class="fas fa-minus" />
                      </button>
                    </div>
                  </li>
                </draggable>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div class="text-center">
          <button
            v-if="hasAccess('filter_setting.localized_filter.create')"
            type="button"
            class="btn btn-primary btn-sm btn-min"
            :disabled="
              local.facility_localized_category.length > 4 ||
              !local.value.visibility
            "
            @click="addFilter"
          >
            <i class="fas fa-plus mr-1" />
            {{ $t("dashboard-common.add-more") }}
          </button>
        </div>
      </div>
      <div class="text-right">
        <button
          ref="filter_form_submit"
          v-if="
            hasAccess('filter_setting.localized_filter.edit') ||
            hasAccess('filter_setting.localized_filter.create')
          "
          type="submit"
          href="#"
          class="btn btn-warning btn-wide"
        >
          <i class="fas fa-save mr-1" />
          {{ $t("dashboard-filter.button-save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
.filter-list {
  list-style-type: none;
  display: block;
  padding-inline-start: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0;
  li {
    display: block;
  }
  .filter-block {
    height: 38px;
    &:first-child {
      line-height: 38px;
    }
  }
}
.form-control.filter-text {
  width: calc(100% - 58px);
  display: inline-block;
}
.table-list {
  width: 100%;
  td {
    vertical-align: middle;
    padding: 8px;
  }
}
</style>

<script>
import filtersApi from "@/api/localized-filters";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";
import draggable from "vuedraggable";

export default {
  components: { draggable },
  async mounted() {
    this.updateActiveMenu("filters");
    try {
      const filters = await filtersApi.show(this.facility.id);
      this.remote = filters;
      this.local = { ...this.local, ...filters };
    } catch (e) {
      this.$toast.error($("dashboard-common.error-generic"));
    }
  },
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  data() {
    return {
      remote: {
        id: null,
        name: "localized-filter",
        value: { visibility: false },
        facility_id: null,
        facility_localized_category: []
      },
      local: {
        id: null,
        name: "localized-filter",
        value: { visibility: false },
        facility_id: null,
        facility_localized_category: []
      },
      touched: false,
      hasError: false
    };
  },
  mixins: [uac],
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    removeFilter(index) {
      const categories = this.local.facility_localized_category;
      const identified = categories[index];
      if (identified.id) identified.status = 0;
      else categories.splice(index, 1);
    },
    restoreFilter(index) {
      const categories = this.local.facility_localized_category;
      categories[index].status = 1;
    },
    addFilter() {
      this.clearValidations();
      const hasDuplicate = this.preventDuplicates();
      if (hasDuplicate) return this.$refs.filter_form_submit.click();
      const newIndex = this.local.facility_localized_category.length + 1;
      this.local.facility_localized_category.push({
        facility_id: this.facility.id,
        id: null,
        name: `${this.$t("common.word-filter")} # ${newIndex}`,
        status: 1,
        order: this.local.facility_localized_category.length,
        created_at: null,
        updated_at: null
      });
    },
    changeOrder(e) {
      const filters = this.local.facility_localized_category;
      const ordered = [];
      for (let i in filters) {
        const filter = { ...filters[i], order: i};
        ordered.push(filter);
      }
      this.local.facility_localized_category = ordered;
    },
    async saveFilter() {
      try {
        this.local.facility_id = this.facility.id;
        const result = await filtersApi.update(this.facility.id, this.local);
        this.remote = result;
        this.local = result;
        this.$toast.success(this.$t("dashboard-filter.message-success"), {
          position: "top"
        });
      } catch (e) {
        const { status, data } = e.response;
        if (status == 422) {
          if (data.errors) {
            const message = data.errors.facility_localized_category[0];
            this.$toast.error(message, { position: "top" });
          } else {
            this.$toast.error(this.$t("dashboard-common.error-generic"), {
              position: "top"
            });
          }
          return;
        }
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    validateInput(e) {
      this.clearValidations();
      const value = e.target.value.trim().toLowerCase();
      let matchCount = 0;
      for (let filter of this.local.facility_localized_category) {
        if (filter.name.trim().toLowerCase() == value) {
          if (matchCount > 1) break;
          matchCount++;
        }
      }
      if (matchCount > 1) {
        e.target.setCustomValidity(this.$t("validations.already-taken"));
      } else {
        e.target.setCustomValidity("");
      }
      e.target.checkValidity();
    },
    clearValidations() {
      for (let index in this.local.facility_localized_category) {
        const field = document.getElementById(`lfilter_${index}`);
        field.setCustomValidity("");
      }
    },
    preventDuplicates() {
      let hasError = false;
      const filters = {};
      for (let index in this.local.facility_localized_category) {
        const field = document.getElementById(`lfilter_${index}`);
        const value = field.value.trim().toLowerCase();
        if (filters[value]) {
          filters[value]++;
          if (filters[value] > 1) {
            field.setCustomValidity(this.$t("validations.already-taken"));
            hasError = true;
            break;
          }
        } else filters[value] = 1;
      }
      return hasError;
    }
  }
};
</script>