export default {
  en: {
    male: "Male",
    female: "Female"
  },
  ja: {
    male: "男性",
    female: "女性"
  },
  ko: {
    male: "남성",
    female: "여성"
  },
  'zh-CN': {
    male: "男性",
    female: "女性"
  },
  'zh-TW': {
    male: "男性",
    female: "女性"
  }
}