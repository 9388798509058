<template>
  <div class="form-group" :id="id" :class="{ errored: error.length > 0 }">
    <label v-if="label" class="flex-center-label">
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
      <small class="form-message d-inline ml-2 text-danger">{{ labelRightComments }}</small>
    </label>
    <div class="d-flex w-100">
      <select
        class="form-control flex-fill"
        :value="value"
        :id="id"
        :class="{
          'border-primary': error.length < 1,
          'border-danger': error.length > 0,
        }"
        :required="required"
        @input="(e) => $emit('input', e.target.value)"
      >
        <option v-if="!customEmptyFieldValue" :value="emptyFieldValue">{{ form_mask ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`) : '--' }}</option>
        <option
          v-for="(opt, idx) in options"
          :value="typeof opt === 'object' ? opt.value : opt"
          :key="idx"
        >
          {{ typeof opt === "object" ? opt.text : opt }}
        </option>
      </select>
      <a
        v-if="copyButton"
        type="button"
        class="btn btn-primary btn-round text-white ml-1"
        v-b-tooltip.hover
        :title="$t('check-in-form.tip-copy-from-main')"
        @click="() => $emit('copy')"
      >
        <i class="far fa-copy" />
      </a>
    </div>
    <small class="form-message text-danger">
    {{ underComments }}
    </small>
    <small
      v-if="!noErrorMessage & (error.length > 0)"
      class="form-message text-danger"
      >{{ error }}</small
    >
  </div>
</template>


<script>
export default {
  props: {
    copyButton: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    required: { type: Boolean, default: false },
    error: { type: String, default: "" },
    noErrorMessage: { type: Boolean, default: false },
    customEmptyFieldValue: { type: Boolean, default: false},
    emptyFieldValue: { type: String | Number, default: ""},
    underComments: { type: String, default: ""},
    labelRightComments: { type: String, default: ""},
    form_mask: { type: Boolean, default: false},
    id: String
  },
  methods: {
    onInputChange({ target }) {
      this.$emit("input", target.value);
    }
  }
};
</script>
