<template>
  <div>
    <form @submit.prevent="updateStatus">
      <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
        <p>{{ $t("dashboard-settings.instruction-maintenance") }}</p>
        <div class="d-flex justify-content-center w-100 mb-3">
          <div class="px-2">
            {{ $t("dashboard-settings.tab-maintenance") }}
          </div>
          <div class="px-2">
            <toggle-button
              v-model="enabled"
              :labels="{
                checked: 'ON',
                unchecked: 'OFF',
              }"
              :width="70"
              :value="enabled"
              :sync="true"
              :color="{checked: $theme.purple, unchecked: $theme.red}"
            />
          </div>
        </div>
      </div>
      <div class="text-right">
        <button
          type="submit"
          class="btn btn-warning btn-wide"
          :disabled="!changed"
        >
          <i class="fas fa-save mr-1" />
          {{ $t("dashboard-settings.button-save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
.table {
  td,
  th {
    vertical-align: middle !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  td.controls {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import settingsApi from "@/api/settings";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  data() {
    return {
      initValue: null,
      changed: false,
      enabled: false,
      data: {
        facility_id: null,
        name: "maintenance_mode",
        value: 0
      }
    };
  },
  beforeMount() {
    this.data.facility_id = this.facility.id;
  },
  async mounted() {
    const data = await this.getStatus();
    this.initValue = data;
    console.log('initValue', data);
  },
  methods: {
    async getStatus() {
      try {
        const response = await settingsApi.getMaintenanceSettings(this.facility.id);
        let value = 0;
        if (response.total > 0) value = response.data[0].value ?? 0;
        this.data.value = value;
        this.enabled = value == 1 ? true : false;
        console.log('value');
        return value;
      } catch (e) {
        console.error("MaintenanceSetting -> submit()", e.message);
        this.$toast.error(this.$t(`common.error-generic`), {
          position: "top"
        });
      }
    },
    async updateStatus() {
      try {
        this.data.value = this.enabled ? 1 : 0;
        const response = await settingsApi.save(this.data);
        this.initValue = this.data.value;
        this.changed = false;
        this.$toast.success(this.$t("dashboard-common.saved-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error("MaintenanceSetting -> updateStatus()", e.message);
        this.$toast.error(this.$t(`common.error-generic`), {
          position: "top"
        });
      }
    }
  },
  watch: {
    enabled(value) {
      if (Number.isSafeInteger(this.initValue)) {
        if (value != this.initValue) this.changed = true;
        else this.changed = false;
      }
    }
  }
};
</script>