<template>
  <div class="form-group" :id="id" :class="{ errored: error.length > 0 }">
    <label>
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
    </label>
    <div class="d-flex w-100">
      <input
        type="date"
        class="form-control"
        :min="min"
        :required="required"
        :placeholder="placeholder"
        :value="value"
        :class="{
          'border-primary': error.length < 1,
          'border-danger': error.length > 0,
          'background-image-none': !requiredIcon
        }"
        @input="(e) => $emit('input', e.target.value)"
      />
      <a
        v-if="copyButton"
        type="button"
        class="btn btn-primary btn-round text-white ml-1"
        v-b-tooltip.hover
        :title="$t('check-in-form.tip-copy-from-main')"
        @click="() => $emit('copy')"
      >
        <i class="far fa-copy" />
      </a>
    </div>
    <small v-if="!noErrorMessage && error.length > 0" class="text-danger">{{
      error
    }}</small>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    label: { type: String, defalt: "label" },
    required: { type: Boolean, default: false },
    noErrorMessage: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    copyButton: { type: Boolean, default: false },
    min: { type: String, default: ""},
    error: { type: String, default: "" },
    requiredIcon: { type: Boolean, default: true },
    id: String
  }
};
</script>
