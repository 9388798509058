<template>
  <!-- Step 4: Take photo of face -->
  <div>
    <div class="nav-buttons">
      <circle-nav-button direction="prev" @click="goBack" />
    </div>
    <div class="d-flex justify-content-center py-3">
      <checkin-stepper step="input" />
    </div>
    <header class="checkin-form-header font-weight-bold bg-white sticky-top">
      <div class="d-flex justify-content-between">
        <span>{{ $t("check-in-form.title-face-capture") }}</span>
        <span></span>
      </div>
    </header>
    <div>
      <p>{{ $t("check-in-form.instruction-face-capture") }}</p>
    </div>
    <div class="text-center" :class="{ 'skip-capture': skipFaceCapture }">
      <input
        type="file"
        accept="image/*"
        ref="face_camera"
        style="display: none"
        capture
        @change="inputChanged"
      />
      <div class="aspect-ratio ratio-5x3">
        <figure
          class="figure border border-primary rounded rounded-2 mb-3"
          @click="showCamera"
        >
          <div class="bottom-notice text-center text-white">
            <span>{{ $t("check-in-form.flow-face-capture-change") }}</span>
          </div>
          <img
            v-show="face_image_data && !skipFaceCapture"
            :src="face_image_data"
            class="img-contain rounded rounded-2"
            title="tap to change"
          />
          <p
            v-show="!face_image_data && !skipFaceCapture"
            class="text-white mb-0"
          >
            <i class="fas fa-camera text-white" style="font-size: 36px"></i><br />
            <span>{{ $t("check-in-form.flow-face-capture-take") }}</span>
          </p>
          <p v-show="skipFaceCapture" class="text-white mb-0">
            <i class="fas fa-user-secret text-white" style="font-size: 36px"></i>
          </p>
        </figure>
      </div>
      
      <div class="form-check p-0 mb-3">
        <b-alert
          variant="danger"
          :show="captureError.length > 0"
          dismissible
          class="text-center"
          @dismissed="captureError = ''"
        >
          {{ captureError }}
        </b-alert>
        <b-form-checkbox id="skip_face_capture" v-model="skip_face_capture">
          <span class="ml-1">{{ $t("check-in-form.flow-skip-step") }}</span>
        </b-form-checkbox>
      </div>

      <button type="button" class="btn btn-primary btn-min" @click="goNext">
        {{ $t("check-in-form.word-next") }}
      </button>
    </div>
    <b-modal
      size="lg"
      id="face_capture_modal"
      hide-header
      @show="webcamStart"
      @hide="webcamStop"
    >
      <div>
        <web-cam
          ref="webcam_screen"
          selectFirstDevice
          @notsupported="enableWebcamError"
        />
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-primary btn-round mx-2"
            style="width: 48px; height: 48px"
            @click="webcamCapture"
          >
            <i class="fas fa-camera fa-lg" />
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-round mx-2"
            style="width: 48px; height: 48px"
            @click="() => $bvModal.hide('face_capture_modal')"
          >
            <i class="fas fa-times fa-lg" />
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";
.skip-capture {
  .bottom-notice {
    display: none !important;
  }
  .figure {
    cursor: default !important;
  }
}
.figure {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  img {
    width: 100%;
    max-height: 380px;
  }
  p {
    opacity: 0.8;
  }
  &:hover p {
    opacity: 1;
  }
  .bottom-notice {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 12px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );
  }
}

video {
  background-color: black;
}
</style>

<script>
import CheckinStepper from "@/components/CheckinStepper";
import CircleNavButton from "@/components/CircleNavButton";
import { WebCam } from "vue-web-cam";

import { mapActions, mapState } from "vuex";

export default {
  beforeMount() {
    if (!this.facility.id) this.$router.replace({ path: "/error-404" });
  },
  mounted() {
    this.isMobile =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
    this.detectFaceRecognitionShow();
  },
  components: { CheckinStepper, CircleNavButton, WebCam },
  data() {
    return {
      error: false,
      loading: false,
      captureError: "",
      isMobile: false,
      showWebcam: false
    };
  },
  computed: {
    ...mapState("checkin", [
      "facility",
      "faceImageData",
      "skipFaceCapture",
      "enabledFaceCapture",
      "requiredFaceCapture",
      "enabledSignature",
      "questions"
    ]),
    skip_face_capture: {
      get() {
        return this.skipFaceCapture;
      },
      set(value) {
        if (value) this.updateFaceImageData("");
        this.updateSkipFaceCapture(value);
      }
    },
    face_image_data: {
      get() {
        return this.faceImageData;
      },
      set(value) {
        this.updateFaceImageData(value);
      }
    }
  },
  methods: {
    ...mapActions("checkin", ["updateFaceImageData", "updateSkipFaceCapture"]),
    showCamera() {
      if (!this.isMobile) {
        this.$bvModal.show("face_capture_modal");
      } else {
        this.$refs.face_camera.click();
      }
    },
    enableWebcamError() {
      this.$toast.error(this.$t(`check-in-form.error-camera-notfound`));
    },
    webcamCapture(e) {
      const data = this.$refs.webcam_screen.capture();
      this.updateFaceImageData(data);
      this.webcamStop();
      this.$bvModal.hide("face_capture_modal");
    },
    webcamStart() {
      this.showWebcam = true;
    },
    webcamStop() {
      this.$refs.webcam_screen.stop();
      this.showWebcam = false;
    },
    goBack() {
      this.$router.replace({ name: "checkin-step-3" });
    },
    goNext() {
      //// requiredがfalseの場合は顔認証をチェックを入れなくてもスキップできるようにしたい場合は長い方のifを使う
      //   長い方のifを使わない場合は顔認証のrequiredはとくに意味なし
      // if (this.skipFaceCapture || !this.requiredFaceCapture || this.faceImageData.length > 5) {
      if (this.skipFaceCapture || this.faceImageData.length > 5) {
        if (this.enabledSignature) this.$router.replace({ name: "checkin-step-5" });
        else this.$router.replace({ name: "checkin-step-6" });
      } else {
        this.captureError = this.$t("check-in-form.error-field-required");
        this.$toast.error(this.$t("check-in-form.error-form-check"), {
          position: "top"
        });
      }
    },
    inputChanged(e) {
      if (e.target.files.length < 1) return;
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.imageData = e.target.result;
        this.updateFaceImageData(e.target.result);
      };
      reader.onerror = e => {
        console.error("Unsupported image format.");
        this.$toast.error(this.$t("check-in-form.error-unsupported-format"), {
          position: "top"
        });
      };
      reader.readAsDataURL(file);
    },
    detectFaceRecognitionShow() {
      if (!this.enabledFaceCapture) {
        this.$router.replace({ name: "checkin-step-5" });
      }
    }
  }
};
</script>