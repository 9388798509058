import http, { httpmock } from '@/utils/http';

const endpoint = 'auth';

export default {
  async login (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/login`, { ...data }, config);
    return response.data;
  },
  async logout (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/logout`, { ...data }, config);
    return response.data;
  },
  async createReset (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/generate-reset-password`, { ...data }, config);
    return response.data;
  },
  async verifyResetToken (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/verify-reset-password`, { ...data }, config);
    return response.data;
  },
  async updatePassword (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/change-password`, { ...data }, config);
    return response.data;
  },
  async resetIdleTime (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${endpoint}/reset-idle-time`, { ...data }, config);
    if(response.data.new_token){
      const auth = $storage.get("auth");
      const new_auth = {...auth, token: response.data.new_token.access_token};
      $storage.set("auth", new_auth );
    }
    return response.data;
  },
}