<template>
  <div v-if="hasAccess('setting.picture.download')">
    <h1 class="h3 mb-2 text-gray-800">
      {{ $t("dashboard-downloads.title") }}
    </h1>
    <p>{{ $t("dashboard-downloads.intro") }}</p>

    <form class="mb-4" @submit.prevent="submit">
      <div class="border rounded p-3 mb-3">
        <div class="row">
          <div class="col-lg-5 mb-2 mb-lg-0">
            <div class="d-flex align-items-center">
              <span class="mr-2">{{ $t("dashboard-downloads.label-from") }}</span>
              <b-form-datepicker
                hide-header
                v-model="data.from_date"
                offset="-1"
                :date-format-options="date_format"
                :label-no-date-selected="$t('common.text-nodate')"
                required
              />
            </div>
          </div>
          <div class="col-lg-5 mb-2 mb-lg-0">
            <div class="d-flex align-items-center">
              <span class="mr-2">{{ $t("dashboard-downloads.label-to") }}</span>
              <b-form-datepicker
                hide-header
                v-model="data.to_date"
                offset="-1"
                :date-format-options="date_format"
                :label-no-date-selected="$t('common.text-nodate')"
                required
              />
            </div>
          </div>
          <div class="col-lg-2 text-center">
            <button
                type="submit"
                class="btn btn-warning btn-wide btn-block"
                :disabled="!hasDates"
            >
              <i class="fas fa-download"></i>
              {{ $t("dashboard-common.word-download") }}
            </button>
          </div>
        </div>
      </div>

    </form>

    <table class="table table-bordered hoverable">
      <thead>
        <tr>
          <th>{{ $t("dashboard-downloads.column-id") }}</th>
          <th>{{ $t("dashboard-downloads.column-date") }}</th>
          <th>{{ $t("dashboard-downloads.column-period") }}</th>
        </tr>
      </thead>
      <tbody v-if="history.length > 0">
        <tr v-for="(log, index) in history" :key="`log_${index}`">
          <td>{{ log.user.username }}</td>
          <td>{{ formatDate(log.created_at) || formatDate }}</td>
          <td>{{ log.value_new.from_date }} - {{ log.value_new.to_date }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3" class="text-center">
            {{ $t("dashboard-downloads.message-empty") }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.date-initial[readonly] {
  background-color: initial !important ;
}
</style>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import logsApi from "@/api/logs";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";
export default {
    mixins: [uac],
  computed: {
    ...mapGetters("dashboard", ["facility"]),
    hasDates() {
      return this.data.from_date && this.data.to_date;
    }
  },
  data() {
    return {
      history: [],
      date_format: { year: 'numeric', month: '2-digit', day: '2-digit' },
      data: {
        facility_id: null,
        from_date: null,
        to_date: null
      }
    };
  },
  filters: {
    formatDate(value) {
      return moment().format("YYYY-MM-DD");
    }
  },
  async mounted() {
    this.data.facility_id = this.facility.id;
    this.updateActiveMenu("downloads");
    await this.getHistory();
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    async submit() {
      try {
        const response = await logsApi.downloadGovID(this.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `IDs_${this.data.from_date}_${this.data.to_date}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        await this.getHistory();
      } catch (e) {
        this.$toast.error(this.$t("common.error-generic"), {
          position: "top"
        });
      }
    },
    async getHistory() {
      try {
        const response = await logsApi.getDownloadHistory();
        this.history = response.data;
      } catch (e) {
        this.$toast.error(this.$t("common.error-generic"), {
          position: "top"
        });
      }
    },
    formatDate(date) {
      return moment.tz(date, "utc").format("YYYY/MM/DD");
    }
  }
};
</script>