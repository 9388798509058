// Modified ISO 639-1 & ISO 15924
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// https://en.wikipedia.org/wiki/ISO_15924
export default {
  "en": "English",
  "ja": "日本語",
  "ko": "한국",
  "zh-Hans": "简体中文",
  "zh-Hant": "繁体中文"
}

export const languages = {
  "en": {
    "en": "English",
    "ja": "Japanese",
    "ko": "Korean",
    "zh-Hans": "Simplified Chinese",
    "zh-Hant": "Traditional Chinese"
  },
  "ja": {
    "en": "英語",
    "ja": "日本語",
    "ko": "韓国語",
    "zh-Hans": "簡体字中国語",
    "zh-Hant": "繁体字中国語"
  }
}

export const languageMaps = {
  "universal": {
    "en": "English",
    "ja": "日本語",
    "ko": "한국",
    "zh-CN": "简体中文",
    "zh-SG": "简体中文",
    "zh-HK": "繁体中文",
    "zh-TW": "繁体中文"
  },
  "en": {
    "en": "English",
    "ja": "Japanese",
    "ko": "Korean",
    "zh-CN": "Simplified Chinese",
    "zh-SG": "Simplified Chinese",
    "zh-HK": "Traditional Chinese",
    "zh-TW": "Traditional Chinese"
  },
  "ja": {
    "en": "英語",
    "ja": "日本語",
    "ko": "韓国語",
    "zh-CN": "簡体字中国語",
    "zh-SG": "簡体字中国語",
    "zh-HK": "繁体字中国語",
    "zh-TW": "繁体字中国語"
  }
}