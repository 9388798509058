export default {
  en: {
    "0": "--",
    "1": "Guest Name",
    "2": "Company Name"
  },
  ja: {
    "0": "--",
    "1": "宿泊者氏名",
    "2": "会社名"
  },
  ko: {
    "0": "--",
    "1": "숙박자 이름",
    "2": "회사 명"
  },
  'zh-CN': {
    "0": "--",
    "1": "客人的姓名",
    "2": "公司名称"
  },
  'zh-TW': {
    "0": "--",
    "1": "客人的姓名",
    "2": "公司名稱"
  }
}