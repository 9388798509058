<template>
  <div>
    <h1 class="h3 mb-2 text-gray-800">{{ $t("dashboard-cms.title") }}</h1>
    <p>{{ $t("dashboard-cms.description") }}</p>

    <h6>{{ $t(`dashboard-cms.title-tag-display`) }}</h6>
    <div class="p-3 border rounded bg-light mb-4">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">{{ $t(`dashboard-cms.tag-guest-name`) }}</div>
            <div class="col-6 text-monospace text-danger">[guest_name]</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t(`dashboard-cms.tag-checkin-link`) }}</div>
            <div class="col-6 text-monospace text-danger">[link]</div>
          </div>
          <!-- <div class="row">
            <div class="col-6">{{ $t(`dashboard-cms.tag-qr-code`) }}</div>
            <div class="col-6 text-monospace text-danger">[qr_code]</div>
          </div> -->
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">{{ $t(`dashboard-cms.tag-checkin-date`) }}</div>
            <div class="col-6 text-monospace text-danger">[checkin_date]</div>
          </div>
          <div class="row">
            <div class="col-6">{{ $t(`dashboard-cms.tag-checkout-date`) }}</div>
            <div class="col-6 text-monospace text-danger">[checkout_date]</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Email Guide Settings -->
    <template v-for="(emailGuideDatum, index) in emailGuideData">
      <form class="p-4m" @submit.prevent="saveEmailGuideText(index)">
        <h5>{{ $t(`dashboard-cms.label-pci-email-guide`) + ' ' + String.fromCharCode(65 + index) }}</h5>
        <div class="row">
          <div class="col-sm-12">
            <div class="px-3 pt-3 pb-1 border rounded">
              <quill-editor 
              v-model="emailGuideDatum.value"
              :options="editorOptions"
              :disabled="!hasAccess('notice_setting.email.edit')"
              />
              <!-- <vue-editor
                v-model="emailGuideDatum.value"
                :editorOptions="editorOptions"
                :editor-toolbar="customToolbar"
                :disabled="!hasAccess('notice_setting.email.edit')"
              /> -->
              <div class="d-flex pt-2 pb-1 justify-content-between">
                <div>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary btn-min"
                    @click="() => showSendEmailModal('guide', index)"
                  >
                    {{ $t(`dashboard-cms.text-send-sample`) }}
                  </button>
                </div>
                <div class="editor-footnote">
                  <small>
                    {{ $t(`dashboard-cms.text-updated`) }}:
                    {{ emailGuideDatum.updated_at | formatDate }}
                    {{ emailGuideDatum.author }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2" v-if="hasAccess('notice_setting.email.edit')">
          <div class="col-12" align="right">
            <button type="submit" class="btn btn-warning btn-wide">
              <i class="fas fa-save mr-1"></i>
              {{ $t("dashboard-common.word-save") }}
            </button>
          </div>
        </div>
      </form>
    </template>

    <!-- Email Confirm Settings -->
    <form class="p-4m" @submit.prevent="saveEmailConfirmText">
      <h5>
        {{ $t(`dashboard-cms.label-pci-email-confirm`) }}
      </h5>
      <div class="row">
        <div class="col-sm-12">
          <div class="px-3 pt-3 pb-1 border rounded">
            <quill-editor 
            v-model="emailConfirmData.value"
              :disabled="!hasAccess('notice_setting.email.edit')"
            />
            <!-- <vue-editor
              v-model="emailConfirmData.value"
              :editor-toolbar="customToolbar"
              :disabled="!hasAccess('notice_setting.email.edit')"
            /> -->
            <div class="d-flex pt-2 pb-1 justify-content-between">
              <div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary btn-min"
                  @click="() => showSendEmailModal('confirm')"
                >
                  {{ $t(`dashboard-cms.text-send-sample`) }}
                </button>
              </div>
              <div class="editor-footnote">
                <small>
                  {{ $t(`dashboard-cms.text-updated`) }}:
                  {{ emailConfirmData.updated_at | formatDate }}
                  {{ emailConfirmData.author }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="hasAccess('notice_setting.email.edit')">
        <div class="col-12" align="right">
          <button type="submit" class="btn btn-warning btn-wide">
            <i class="fas fa-save mr-1"></i>
            {{ $t("dashboard-common.word-save") }}
          </button>
        </div>
      </div>
    </form>

    <!-- SMS Settings -->
    <template v-for="(smsDatum, index) in smsData">
      <form class="p-4m" @submit.prevent="saveSMSText(index)">
        <h5>{{ $t(`dashboard-cms.label-sms`) + ' ' + String.fromCharCode(65 + index) }}</h5>
        <div class="row">
          <div class="col-sm-12">
            <div class="px-3 pt-3 pb-1 border rounded">
              <p class="bg-light d-inline-block px-2 py-1 rounded border">
                <a class="tip text-primary mr-1" style="vertical-align: middle">
                  <i class="fas fa-info-circle"></i>
                </a>
                <small>{{ $t("dashboard-cms.text-no-tags") }}</small>
              </p>
              <p class="pre-wrap">
                <small>{{ $t("dashboard-cms.text-description") }}</small>
              </p>
              <textarea
                :disabled="!hasAccess('notice_setting.sms.edit')"
                class="form-control"
                style="min-height: 100px"
                :value="smsDatum.value"
                :maxlength="smsState.maxlength"
                @input.prevent="(e)=>inputSms(e, index)"
                @paste="(e)=>inputSms(e, index)"
              />
              <div class="d-flex pt-2 pb-1 justify-content-between">
                <div class="editor-footnote">
                  <small> {{ typeof smsDatum.value === "string" ? smsDatum.value.length : 0 }} / {{ smsState.maxlength }} </small>
                </div>
                <div class="editor-footnote">
                  <small>
                    {{ $t(`dashboard-cms.text-updated`) }}:
                    {{ smsDatum.updated_at | formatDate }}
                    {{ smsDatum.author }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2" v-if="hasAccess('notice_setting.sms.edit')">
          <div class="col-12" align="right">
            <button type="submit" class="btn btn-warning btn-wide">
              <i class="fas fa-save mr-1"></i>
              {{ $t("dashboard-common.word-save") }}
            </button>
          </div>
        </div>
      </form>
    </template>

    <!-- Landing Page Settings -->
    <form class="p-4m" @submit.prevent="saveLandingText">
      <h5>
        {{ $t(`dashboard-cms.label-landing-page2`) }}
        <a
          tabindex="1"
          class="tip text-primary"
          v-b-tooltip.focus
          :title="$t('dashboard-cms.tip-landing-page2')"
        >
          <i class="far fa-question-circle"></i>
        </a>
        <toggle-button
          :disabled="!hasAccess('notice_setting.landing.edit')"
          class="ml-1 pb-1"
          v-model="announceData.value"
          :labels="{
            checked: 'ON',
            unchecked: 'OFF',
          }"
          :width="70"
          :value="announceData.value"
          :sync="true"
          :color="{ checked: $theme.purple, unchecked: $theme.red }"
        />
      </h5>
      <div class="row">
        <div class="col-sm-12">
          <div>
            <b-tabs>
              <b-tab
                v-for="(lang, index) in languages"
                :key="`lang_${index}`"
                :title="$languages[lang.code]"
                :active="landingState.lang == lang.code"
                @click="() => showLandingText(lang.code)"
              ></b-tab>
            </b-tabs>
          </div>
          <div class="px-3 pt-3 pb-1 border rounded-bottom editor-container">
            <p class="bg-light d-inline-block px-2 py-1 rounded border">
              <a class="tip text-primary mr-1" style="vertical-align: middle">
                <i class="fas fa-info-circle"></i>
              </a>
              <small>{{ $t("dashboard-cms.text-no-tags") }}</small>
            </p>
            <div v-if="!landingState.preview">
              <!-- <vue-editor
                :disabled="!hasAccess('notice_setting.landing.edit')"
                v-if="!landingState.html_mode"
                v-model="landingData.value"
                :editor-toolbar="customToolbar"
              /> -->
              <quill-editor 
              :disabled="!hasAccess('notice_setting.landing.edit')"
                v-if="!landingState.html_mode"
                v-model="landingData.value"
              />
              <prism-editor
                v-if="landingState.html_mode"
                class="border p-3 rounded basic-box"
                v-model="landingData.value"
                :highlight="highlighter"
                line-number
              />
            </div>
            <div
              v-else
              class="border rounded p-3 basic-box"
              v-html="landingData.value"
            ></div>
            <div class="d-flex pt-2 pb-1 justify-content-between">
              <div>
                <button
                  type="button"
                  class="btn btn-sm btn-primary btn-min"
                  @click="landingState.preview = !landingState.preview"
                >
                  {{
                    landingState.preview
                      ? $t("dashboard-common.word-edit")
                      : $t("dashboard-common.word-preview")
                  }}
                </button>
                <toggle-button
                  v-show="!landingState.preview"
                  :disabled="!hasAccess('notice_setting.landing.edit')"
                  class="ml-1 pb-1"
                  :width="70"
                  v-model="landingState.html_mode"
                  :labels="{
                    checked: 'HTML',
                    unchecked: 'LIVE',
                  }"
                  :value="landingState.html_mode"
                  :sync="true"
                  :color="{ checked: $theme.purple, unchecked: $theme.red }"
                />
              </div>
              <div class="editor-footnote">
                <small>
                  {{ $t(`dashboard-cms.text-updated`) }}:
                  {{ landingData.updated_at | formatDate }}
                  {{ landingData.author }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="hasAccess('notice_setting.landing.edit')">
        <div class="col-12" align="right">
          <button type="submit" class="btn btn-warning btn-wide">
            <i class="fas fa-save mr-1"></i>
            {{ $t("dashboard-common.word-save") }}
          </button>
        </div>
      </div>
    </form>

    <!-- Email Prompt Modal -->
    <b-modal
      v-model="emailModal.show"
      :title="$t(`dashboard-cms.title-send-sample`)"
      centered
      :no-close-on-esc="emailModal.loading"
      :no-close-on-backdrop="emailModal.loading"
      :header-class="{ 'modal-loading': emailModal.loading }"
    >
      <form @submit.prevent="sendSampleEmail">
        <label class="">
          {{ $t("dashboard-users.label-email") }}
          <a
            tabindex="1"
            class="tip text-primary"
            v-b-tooltip.focus
            :title="$t('dashboard-cms.tip-send-email')"
          >
            <i class="far fa-question-circle"></i>
          </a>
        </label>
        <div>
          <input
            type="text"
            class="form-control"
            v-model="emailModal.value"
            placeholder="email1@site.com, email2@site.com, email3@site.com"
            @input="validateEmailList"
            required
            :disabled="emailModal.loading"
          />
        </div>
        <input type="submit" class="d-none" ref="email_sample_modal_submit" />
      </form>
      <template v-slot:modal-footer>
        <div class="text-right">
          <button
            @click="
              () => {
                $refs.email_sample_modal_submit.click();
              }
            "
            class="btn btn-warning btn-min ml-3 text-white"
            :disabled="emailModal.loading"
          >
            <span
              v-if="emailModal.loading"
              class="spinner-border spinner-border-sm"
            />
            <i v-else class="fas fa-check mr-1" />
            {{ $t("common.word-submit") }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.basic-box {
  min-height: 249px;
}
.editor-footnote {
  line-height: 31px;
}
</style>

<style>
.prism-editor__textarea:focus {
  outline: none !important;
}
</style>

<script>
import {
  highlight,
  languages as prismLang
} from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";
import { PrismEditor } from "vue-prism-editor";
// import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment-timezone";
import cmsApi from "@/api/cms";
// import settingsApi from "@/api/settings";
import emailsApi from "@/api/emails";
import uac from "@/mixins/uac";
import QuillEditor from '@/components/QuillEditor'

// 指定した数値を元にガイドメールの数を決める
// 26であればA-Zの26通のガイドメールが作成される
// 27以上は想定しない（"Zの次になるためガイドメール["のようになる）
const NUMBER_OF_EMAIL_GUIDES = 2;
const NUMBER_OF_SMS_GUIDES = 2;

export default {
  components: {
    // VueEditor,
    PrismEditor,
    QuillEditor
  },
  mixins: [uac],
  beforeMount() {
    // emailのガイドメールのデータを初期化
    [...Array(NUMBER_OF_EMAIL_GUIDES)].map((num, index) => {
      this.emailGuideData.push(
            {
              // 2つ目以降はB,C,D...という接尾語をつける
              name: "pre-email" + (index === 0 ? '' : `-${String.fromCharCode(65 + index)}`),
              value: "",
              author: "",
              updated_at: ""
            },
        );
      }
    );

    // SMSのガイドメールのデータを初期化
    [...Array(NUMBER_OF_SMS_GUIDES)].map((num, index) => {
      this.smsData.push(
            {
              // 2つ目以降はB,C,D...という接尾語をつける
              name: "message-sms" + (index === 0 ? '' : `-${String.fromCharCode(65 + index)}`),
              value: "",
              author: "",
              updated_at: ""
            },
        );
      }
    );
  },
  async mounted() {
    this.updateActiveMenu("cms");
    this.showEmailText(this.$i18n.locale);
    this.showSMSText(this.$i18n.locale);
    this.showLandingText(this.$i18n.locale);
    await this.showAnnounceState();
    this.announceState.init = true;
  },
  computed: {
    ...mapState(["languages"]),
    ...mapGetters("dashboard", ["facility"]),
  },
  data() {
    return {
      emailGuideData: [],
      emailConfirmData: {
        name: "",
        value: "",
        author: "",
        updated_at: ""
      },
      emailState: {
        lang: "",
        preview: false
      },
      smsData: [],
      smsState: {
        lang: "",
        maxlength: 174
      },
      landingData: {
        name: "",
        value: "",
        author: "",
        updated_at: ""
      },
      landingState: {
        lang: "",
        preview: false,
        html_mode: false,
        enabled: false
      },
      announceData: {
        name: `state-landing2-enabled`,
        value: true
      },
      announceState: {
        init: null
      },
      emailModal: {
        show: false,
        loading: false,
        mode: "guide",
        value: "",
        index: 0
      },
      editorOptions: {
        bounds: ".quillWrapper"
      },
      customToolbar: [
        [
          { font: [] },
          { size: ["small", false, "large", "huge"] },
          { color: [] },
          { background: [] },
          { align: [] }
        ],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "clean"]
      ]
    };
  },
  filters: {
    formatDate(date) {
      const match = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(date);
      if (match) return moment.tz(date, "utc").format("YYYY/MM/DD");
      return "";
    }
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    inputSms(e, index) {
      const text = e.target.value;
      if (text.length <= this.smsState.maxlength) {
        this.smsData[index].value = text;
      }
      this.$set(this.smsData, index, this.smsData[index]);
    },
    async showEmailText(lang) {
      this.emailState.lang = lang;
      const result = await cmsApi.getEmailGuide(this.facility.id);
      result.forEach((emailGuideDatum) => {
        // 取得したvalueを復号化する
        emailGuideDatum.value = cmsApi.restoreEquals(emailGuideDatum.value);
        // データベースにはnameがpre-email, pre-email-B, pre-email-C...という名前で保存されている
        // これを'', 'B', 'C'...という名前に変換する
        const alphabet = emailGuideDatum.name.replace('pre-email', '').replace('-', '');
        // ''は0, 'B'は1, 'C'は2...というように変換する
        const arrayIndex = alphabet ? alphabet.codePointAt() - 65 : 0;
        if(arrayIndex >= NUMBER_OF_EMAIL_GUIDES) return;
        this.emailGuideData[arrayIndex] = emailGuideDatum;
      });
      const emailConfirmData = await cmsApi.getEmailConfirm(this.facility.id);
      emailConfirmData.value = cmsApi.restoreEquals(emailConfirmData.value);
      this.emailConfirmData = emailConfirmData;
    },
    showSendEmailModal(mode, index = 0) {
      this.emailModal.mode = mode;
      this.emailModal.show = true;
      this.emailModal.index = index;
    },
    async sendSampleEmail() {
      const data = {
        facility_id: this.facility.id,
        emails: String(this.emailModal.value).split(","),
        template: ""
      };

      if (this.emailModal.mode == "guide") {
        try {
          this.emailModal.loading = true;
          data.template = this.emailGuideData[this.emailModal.index].value;
          await emailsApi.sendPciGuide(data, true);
          this.emailModal.loading = false;
          this.emailModal.show = false;
          setTimeout(() => {
            this.$toast.success(this.$t("dashboard-cms.alert-email-sent"), {
              position: "top"
            });
          }, 150);
        } catch (e) {
          console.error("cms -> sendSampleEmail", e.message);
          this.$toast.error(this.$t("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      } else {
        try {
          this.emailModal.loading = true;
          data.template = this.emailConfirmData.value;
          await emailsApi.sendPciConfirm(data, true);
          this.emailModal.loading = false;
          this.emailModal.show = false;
          setTimeout(() => {
            this.$toast.success(this.$t("dashboard-cms.alert-email-sent"), {
              position: "top"
            });
          }, 150);
        } catch (e) {
          console.error("cms -> sendSampleEmail", e.message);
          this.$toast.error(this.$t("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      }
    },
    async showSMSText(lang) {
      this.smsState.lang = lang;
      const result = await cmsApi.getSMS(this.facility.id);
      result.forEach((smsDatum) => {
        smsDatum.value = cmsApi.restoreEquals(smsDatum.value);
        // データベースにはnameがmessage-email, message-email-B, message-email-C...という名前で保存されている
        // これを'', 'B', 'C'...という名前に変換する
        const alphabet = smsDatum.name.replace('message-sms', '').replace('-', '');
        // ''は0, 'B'は1, 'C'は2...というように変換する
        const arrayIndex = alphabet ? alphabet.codePointAt() - 65 : 0;
        if(arrayIndex >= NUMBER_OF_SMS_GUIDES) return;
        this.smsData[arrayIndex] = smsDatum;
      });
    },
    async showLandingText(lang) {
      this.landingState.lang = lang;
      const landingData = await cmsApi.getLanding(this.facility.id, lang);
      landingData.value = cmsApi.restoreEquals(landingData.value);
      this.landingData = landingData;
    },
    async showAnnounceState() {
      this.announceData = await cmsApi.getLanding2State(this.facility.id);
    },
    async saveEmailGuideText(index) {
      try {
        this.emailGuideData[index]['facility_id'] = this.facility.id;
        const response = await cmsApi.save(this.emailGuideData[index]);

        this.$set(this.emailGuideData, index, response);
        this.$toast.success(this.$t("dashboard-cms.alert-email-saved"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async saveEmailConfirmText() {
      try {
        this.emailConfirmData['facility_id'] = this.facility.id;
        this.emailConfirmData = await cmsApi.save(this.emailConfirmData);
        this.$toast.success(this.$t("dashboard-cms.alert-email-saved"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async saveSMSText(index) {
      try {
        this.smsData[index]['facility_id'] = this.facility.id;
        const response = await cmsApi.save(this.smsData[index]);
        this.$set(this.smsData, index, response);
        this.$toast.success(this.$t("dashboard-cms.alert-sms-saved"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async saveLandingText() {
      try {
        this.landingData['facility_id'] = this.facility.id;
        this.landingData = await cmsApi.save(this.landingData);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async saveAnnounceState() {
      try {
        await cmsApi.save(this.announceData);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error("cms -> saveAnnounceState()", e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    validateEmailList(e) {
      if (!e.target.value) {
        e.target.setCustomValidity(this.$t(`validations.required`));
        e.checkValidity();
        return;
      }
      let hasError = false;
      const emailList = String(e.target.value).split(",");
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (let email of emailList) {
        const clean = email.toLowerCase().trim();
        if (!re.test(clean)) {
          e.target.setCustomValidity(this.$t(`validations.invalid-email`));
          hasError = true;
          e.target.checkValidity();
          break;
        }
      }
      if (!hasError) {
        e.target.setCustomValidity("");
        e.target.checkValidity();
      }
    },
    highlighter(code) {
      return highlight(code, prismLang.html); //returns html
    }
  },
  watch: {
    "announceData.value"(yes) {
      if (this.announceState.init) {
        this.saveAnnounceState();
      } else {
        this.announceState.init = true;
      }
    }
  }
};
</script>
