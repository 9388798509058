import DashboardIndex from '@/pages/dashboard/index.vue'
import List from '@/pages/dashboard/list/list.vue'
import Form from '@/pages/dashboard/form/form.vue'
import Filters from '@/pages/dashboard/filters/filters.vue'
import Downloads from '@/pages/dashboard/downloads/downloads.vue'
import Settings from '@/pages/dashboard/settings/settings.vue'
import Users from '@/pages/dashboard/users/users.vue'
import Terms from '@/pages/dashboard/terms/terms.vue'
import Cms from '@/pages/dashboard/cms/cms.vue'
import Notifications from '@/pages/dashboard/notifications/notifications.vue'

export default [
  {
    path: `/dashboard`,
    component: DashboardIndex,
    children: [
      { path: `/`, name: 'dashboard-home', component: List, },
      { path: `form`, name: 'dashboard-questions', component: Form },
      { path: `filters`, name: 'dashboard-filters', component: Filters },
      { path: `settings`, name: 'dashboard-settings', component: Settings },
      { path: `terms`, name: 'dashboard-toc', component: Terms },
      { path: `users`, name: 'dashboard-users', component: Users },
      { path: `cms`, name: 'dashboard-cms', component: Cms },
      { path: `notifications`, name: 'dashboard-notifications', component: Notifications },
      { path: `downloads`, name: 'dashboard-downloads', component: Downloads },
    ],
  }
]