<template>
  <router-view />
</template>

<style lang="scss">
@import "~@/assets/scss/variables";
.header-icon {
  color: $primary;
  font-size: 72px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import settingsApi from "@/api/settings";
import { languageMaps } from "@/utils/mockups/language-options";
// import t from "typy";

export default {
  beforeMount() {
    this.languages = languageMaps.universal;
  },
  async mounted() {
    this.detectLanguage();
  },
  computed: {
    ...mapState("checkin", ["facility", "loading"]),
    ...mapGetters("checkin", ["facilityName"]),
    locale() {
      return this.$i18n.locale;
    }
  },
  data() {
    return {
      langloading: true,
      languages: {},
      languageCodeSupport: ["ja"]
    };
  },
  methods: {
    changeLanguage(code) {
      this.$i18n.locale = code;
    },
    async getLanguageSupport(facility_id) {
      try {
        this.langloading = true;
        const response = await settingsApi.getLanguages(facility_id, true);
        const languages = response.data[0].value;
        const codeSupport = [];
        for (let language of languages) codeSupport.push(language.code);
        this.languageCodeSupport = codeSupport;
        this.langloading = false;
      } catch (e) {
        this.$toast.error(this.$("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    detectLanguage() {
      const langCode = /^en-[A-Z]{2}$/.test(navigator.language)
        ? "en"
        : navigator.language;
      this.$i18n.locale = langCode;
    }
  }
};
</script>