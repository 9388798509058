<template>
  <!-- Step-6: Confirm -->
  <div>
    <div class="nav-buttons">
      <circle-nav-button direction="prev" @click="goBack" />
    </div>
    <div class="d-flex justify-content-center py-3">
      <checkin-stepper step="confirm" />
    </div>
    <header class="checkin-form-header font-weight-bold bg-white sticky-top">
      <div class="d-flex justify-content-between">
        <span>{{ $t('check-in-form.title-confirmation') }}</span>
        <span></span>
      </div>
    </header>
    <div>
      <p>{{ $t('check-in-form.instruction-confirmation') }}</p>
    </div>
    <div class="mb-3">
      <b-card class="">
        <table class="confirm-table table-inner-stripe w-100">
          <!-- Email -->
          <tr>
            <td>{{ $t('check-in-form.word-email') }}</td>
            <td>
              <div class="question-label">
                {{ $t('check-in-form.word-email') }}
              </div>
              <div class="answer">
                <template v-if="form_mask_method == 1 && !savedAnswers.email">
                  {{
                    $t(
                      `dashboard-settings.checkin-form-mask-masked-placeholder`
                    )
                  }}
                </template>
                <template v-else>
                  {{ savedAnswers.email }}
                </template>
              </div>
            </td>
          </tr>
          <!-- Common questions -->
          <tr
            v-for="(answer, index) in savedAnswers.other_form"
            :key="`o_${index}`"
          >
            <td>{{ translateLabel(index) }}</td>
            <!-- Choices -->
            <td v-if="questionMap[index].input_type == 'choices'">
              <div class="question-label">{{ translateLabel(index) }}</div>
              <div class="answer">
                <template v-if="Array.isArray(answer)">
                  <b-badge
                    v-for="(item, iindex) in answer"
                    :key="`itm_${iindex}`"
                    pill
                    class="mr-1"
                    variant="primary"
                  >
                    {{ item }}
                  </b-badge>
                </template>
                <span v-else>{{ translateAnswer(index, answer) }}</span>
              </div>
            </td>
            <!-- Image -->
            <td
              v-else-if="questionMap[index].input_type == 'image_file_upload'"
            >
              <div class="question-label">{{ translateLabel(index) }}</div>
              <div class="answer">
                <img
                  v-if="answer.length > 0"
                  :src="answer"
                  class="img-contain"
                  style="max-height: 400px"
                />
                <span v-else></span>
              </div>
            </td>
            <!-- Nationality -->
            <!-- <td v-else-if="questionMap[index].system_name == 'nationality'">
              <div class="question-label">{{ translateLabel(index) }}</div>
              <div class="answer">
                {{ nationalityNames[answer][$i18n.locale] }}
              </div>
            </td> -->
            <td v-else>
              <div class="question-label">{{ translateLabel(index) }}</div>
              <div class="answer">{{ translateAnswer(index, answer) }}</div>
            </td>
          </tr>
          <!-- Companion Pills -->
          <tr v-show="savedAnswers.companions.length > 0">
            <td>{{ $t('check-in-form.word-companion') }}</td>
            <td>
              <div class="question-label">
                {{ $t('check-in-form.word-companion') }}
              </div>
              <div class="answer">
                <button
                  v-for="(companion, index) in savedAnswers.companions"
                  type="button"
                  class="btn btn-primary mr-1 btn-min"
                  :key="`c_${index}`"
                  :title="
                    `${$t('check-in-form.word-companion')} # ${index + 1}`
                  "
                  @click="() => viewCompanionDetails(companion, index)"
                >
                  <i class="far fa-user-circle"></i>
                  &nbsp;
                  <span>&nbsp;#&nbsp;{{ index + 1 }}</span>
                </button>
              </div>
            </td>
          </tr>
          <!-- Face Capture -->
          <tr v-show="faceImageData.length > 5">
            <td>{{ $t('check-in-form.label-face-picture') }}</td>
            <td>
              <div class="question-label">
                {{ $t('check-in-form.label-face-picture') }}
              </div>
              <div class="answer">
                <img
                  :src="faceImageData"
                  class="img-contain"
                  style="max-height: 400px"
                />
              </div>
            </td>
          </tr>
          <!-- Signature -->
          <tr v-show="signatureImageData.length > 5">
            <td>{{ $t('check-in-form.word-signature') }}</td>
            <td>
              <div class="question-label">
                {{ $t('check-in-form.word-signature') }}
              </div>
              <div class="answer">
                <img
                  :src="signatureImageData"
                  class="img-contain"
                  style="max-height: 400px"
                />
              </div>
            </td>
          </tr>
        </table>
      </b-card>
    </div>
    <!-- Terms & Conditions -->
    <template v-if="!enabledSignature">
      <div class="d-flex justify-content-center mb-2">
        <a href="" @click.prevent="$emit('show-terms')">
          <small>{{ $t('check-in-form.terms-conditions') }}</small>
        </a>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <b-form-checkbox v-model="acceptTerms">
          <span class="ml-1">{{
            $t('check-in-form.terms-accept-confirm')
          }}</span>
        </b-form-checkbox>
      </div>
    </template>
    <!-- Back & Next Buttons -->
    <div class="text-center">
      <button
        type="button"
        class="btn btn-outline-primary btn-min mx-1"
        @click="goRedirect('checkin-step-3')"
      >
        {{ $t('check-in-form.flow-back-to-form') }}
      </button>
      <button
        type="button"
        class="btn btn-primary btn-min mx-1"
        @click="goNext"
        :disabled="!acceptTerms"
      >
        {{ $t('check-in-form.word-complete') }}
      </button>
    </div>
    <!-- Companion Details Modal -->
    <companion-details-modal
      id="show-companion-details"
      :qmap="questionMap"
      :data="currentCompanionDetails"
      :number="currentCompanionNumber"
      :nationality-names="nationalityNames"
      :prefecture-names="prefectureNames"
    />
  </div>
</template>

<style lang="scss">
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.confirm-table {
  .question-label {
    padding: 4px 8px;
    display: none;
    background-color: lighten($gray-200, 1%);
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
}

@media (max-width: 540px) {
  .confirm-table {
    td {
      padding: 0 !important;
      border-bottom-style: none !important;
    }
    td:first-child {
      display: none;
    }
  }
  .question-label {
    display: block !important;
  }
  .answer {
    padding: 4px 8px;
    display: inline-block;
    margin-bottom: 4px;
  }
}
</style>

<script>
import nationalities from '@/utils/mockups/nationalities';
import prefectures from '@/utils/mockups/prefectures';
import genders from '@/utils/mockups/genders';
import occupations from '@/utils/mockups/occupations';
import yesno from '@/utils/mockups/yes-no';
import receiptAddresses from '@/utils/mockups/receipt_addresses';

import CheckinStepper from '@/components/CheckinStepper';
import CircleNavButton from '@/components/CircleNavButton';
import CompanionDetailsModal from './CompanionDetailsModal';

import moment from 'moment-timezone';
import checkinApi from '@/api/checkin';
import { mapState } from 'vuex';

export default {
  beforeMount() {
    if (!this.facility.id) this.$router.replace({ path: '/error-404' });
    this.buildNationalityNames();
    this.buildPrefectureNames();
    if (this.enabledSignature) this.acceptTerms = true;
    const facility_setting = this.facility.facility_setting.find(
      (d) => d.name == 'form'
    );
    this.form_mask_method = facility_setting.value.form_mask_method;
    console.log(this.form_mask_method);
  },
  components: {
    CheckinStepper,
    CircleNavButton,
    CompanionDetailsModal,
  },
  computed: {
    ...mapState('checkin', [
      'bookingData',
      'facility',
      'faceImageData',
      'enabledSignature',
      'enabledFaceCapture',
      'signatureImageData',
      'savedAnswers',
      'savedRoomLeaders',
      'questions',
      'questionMap',
    ]),
  },
  data() {
    return {
      currentCompanionDetails: {},
      currentCompanionNumber: 0,
      nationalityNames: {},
      acceptTerms: false,
      form_mask_method: false,
    };
  },
  methods: {
    buildNationalityNames() {
      const nationalityMap = {};
      for (const i in nationalities.en) {
        const enItem = nationalities.en[i];
        const jaItem = nationalities.ja[i];
        const hasEnDefined = typeof nationalityMap[enItem.CountryCode];
        const hasJaDefined = typeof nationalityMap[jaItem.CountryCode];
        if (hasEnDefined == 'undefined') {
          nationalityMap[enItem.CountryCode] = { en: null, ja: null };
        }
        if (hasJaDefined == 'undefined') {
          nationalityMap[jaItem.CountryCode] = { en: null, ja: null };
        }
        nationalityMap[
          enItem.CountryCode
        ].en = `${enItem.Nationality} (${enItem.CountryCode})`;
        nationalityMap[
          enItem.CountryCode
        ].ko = `${enItem.Nationality} (${enItem.CountryCode})`;
        nationalityMap[enItem.CountryCode][
          'zh-TW'
        ] = `${enItem.Nationality} (${enItem.CountryCode})`;
        nationalityMap[enItem.CountryCode][
          'zh-CN'
        ] = `${enItem.Nationality} (${enItem.CountryCode})`;
        nationalityMap[
          jaItem.CountryCode
        ].ja = `${jaItem.Nationality} (${jaItem.CountryCode})`;
      }
      this.nationalityNames = nationalityMap;
    },
    buildPrefectureNames() {
      const prefectureList = {};
      for (const prefecture of prefectures) {
        const code = Number(prefecture.code.replace('JP-', ''));
        prefectureList[code] = {
          en: prefecture.en,
          ja: prefecture.ja,
        };
      }
      this.prefectureNames = prefectureList;
    },
    goRedirect(name) {
      this.$router.replace({ name: name });
    },
    goBack() {
      if (this.enabledSignature)
        this.$router.replace({ name: 'checkin-step-5' });
      else if (this.enabledFaceCapture)
        this.$router.replace({ name: 'checkin-step-4' });
      else this.$router.replace({ name: 'checkin-step-3' });
    },
    async goNext() {
      const submitted = await this.submitForm();
      if (submitted) {
        this.$router.replace({ name: 'checkin-step-7' });
        this.$toast.success(this.$t('check-in-form.alert-checkin-success'), {
          position: 'top',
        });
      } else {
        this.$toast.error(this.$t('check-in-form.error-generic'), {
          position: 'top',
        });
      }
    },
    translateLabel(questionId) {
      const question = this.questionMap[questionId];
      if (question.is_core != 1) return question.label;
      return this.$t(`system-questions.${question.system_name}`);
    },
    translateAnswer(questionId, answerText) {
      const question = this.questionMap[questionId];
      const answer = answerText?.trim() ?? '';
      const metaQuestions = [
        'full_name',
        'full_name_for_katakana',
        'zip_code',
        'prefecture',
        'address',
        'phone',
        'nationality',
        'email',
      ];
      if (
        this.form_mask_method == 1 &&
        this.bookingData.need_masking_keys?.includes(question.system_name)
      ) {
        if (answer.length < 1)
          return this.$t(
            `dashboard-settings.checkin-form-mask-masked-placeholder`
          );
      }
      if (question.system_name == 'gender') {
        if (answer.length < 1) return '';
        return genders[this.$i18n.locale][answer.toLocaleLowerCase()];
      }
      if (question.system_name == 'occupation') {
        if (answer.length < 1) return '';
        if (
          typeof occupations[this.$i18n.locale][answer.toLocaleLowerCase()] ==
          'undefined'
        )
          return '';
        return occupations[this.$i18n.locale][answer.toLocaleLowerCase()];
      }
      if (question.system_name == 'nationality') {
        if (answer.length < 1) return '';
        const nationalityObj = this.nationalityNames[answer];
        if (typeof nationalityObj == 'undefined') return '';
        if (typeof nationalityObj[this.$i18n.locale] == 'undefined') return '';
        return nationalityObj[this.$i18n.locale];
      }
      if (question.system_name == 'prefecture') {
        if (answer.length < 1) return '';
        if (
          typeof this.prefectureNames[answer.toLocaleLowerCase()] == 'undefined'
        )
          return '';
        return this.prefectureNames[answer.toLocaleLowerCase()][
          this.$i18n.locale
        ];
      }
      if (question.system_name == 'receipt_address') {
        return receiptAddresses[this.$i18n.locale][answer];
      }
      return answer;
    },
    async submitForm() {
      const baseData = {
        facility_id: this.facility.id,
        timezone: moment.tz.guess(),
        booking_details: this.bookingData,
        tour_leaders: this.savedRoomLeaders,
        user_id: null,
        first_name: null,
        last_name: null,
        address: null,
        phone_number: null,
        nationality: null,
        other_form: {},
        confirmBooking: this.facility.confirmBooking,
        form_mask_method: this.form_mask_method,
        selected_language: this.$i18n.locale,
      };
      if (this.enabledSignature) {
        this.questions.forEach((question) => {
          if (question.system_name === 'signature') {
            baseData.other_form[question.id] = this.signatureImageData;
          }
        });
      }
      if (this.enabledFaceCapture && !this.skipFaceCapture) {
        this.questions.forEach((question) => {
          if (question.system_name === 'face_recognition') {
            baseData.other_form[question.id] = this.faceImageData;
          }
        });
      }
      baseData.other_form = {
        ...baseData.other_form,
        ...this.savedAnswers.other_form,
      };
      baseData.companions = this.savedAnswers.companions;
      baseData.email = this.savedAnswers.email;

      // Process answers for main guest.
      for (let qid of Object.keys(baseData.other_form)) {
        const question = this.questionMap[qid];
        // Convert array type answers to string.
        if (Array.isArray(baseData.other_form[qid])) {
          baseData.other_form[qid] = baseData.other_form[qid].toString();
        }
        // Process phone number
        if (question.system_name == 'phone') {
          baseData.other_form[qid] = String(baseData.other_form[qid]).replace(
            /[^\d+]/g,
            ''
          );
        }
        // Process zip code
        if (question.system_name == 'zip_code') {
          baseData.other_form[qid] = String(baseData.other_form[qid]).replace(
            /[^\d+A-Z]/g,
            ''
          );
        }
      }

      // Process answers for companions.
      for (let qindex of Object.keys(baseData.companions)) {
        const cdata = baseData.companions[qindex];
        for (let qid of Object.keys(cdata.other_form)) {
          const question = this.questionMap[qid];
          // Convert array type answers to string.
          if (Array.isArray(cdata.other_form[qid])) {
            baseData.companions[qindex].other_form[qid] = baseData.companions[
              qindex
            ].other_form[qid].toString();
          }
          // Process phone number
          if (question.system_name == 'phone') {
            baseData.companions[qindex].other_form[qid] = String(
              baseData.companions[qindex].other_form[qid]
            ).replace(/[^\d+]/g, '');
          }
          // Process zip code
          if (question.system_name == 'zip_code') {
            baseData.companions[qindex].other_form[qid] = String(
              baseData.companions[qindex].other_form[qid]
            ).replace(/[^\d+A-Z]/g, '');
          }
        }
      }

      try {
        const response = await checkinApi.post(baseData);
        sessionStorage.setItem('qr_data', response.status.qrCode);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    viewCompanionDetails(details, companionIndex) {
      this.currentCompanionDetails = details;
      this.currentCompanionNumber = Number(companionIndex) + 1;
      this.$bvModal.show('show-companion-details');
    },
  },
};
</script>
