import http, { httpmock } from '@/utils/http';

function normalizeData (raw) {
  const $t = $i18n.messages[$i18n.locale];
  const yes = $t.common['word-yes'];
  const no = $t.common['word-no'];
  const normalized = [];
  for (let item of raw) {
    let entry = {}
    const logdata = item.facility_booking_log;
    entry['status'] = item.facility_booking_log_name ? item.facility_booking_log_name : '--';
    entry['status_log_id'] = logdata ? logdata.id : null;
    entry['booking_status'] = logdata ? logdata.booking_status : null;
    entry['reservation_id'] = item.pms_reservation_no ? item.pms_reservation_no : '--';
    entry['has_face_picture'] = item.has_face_picture ? 'yes' : 'no';
    entry['has_passport_picture'] = item.has_passport_picture ? 'yes' : 'no';
    entry['check_in_date'] = `${item.checkin_date} ${item.checkin_time}`;
    entry['check_out_date'] = `${item.checkout_date} ${item.checkout_time}`;
    entry['name'] = item.metadata?.full_name;
    entry['name_kana'] = item.metadata?.full_name_for_katakana;
    entry['phone_number'] = item.metadata?.phone;
    entry['email'] = `${item.metadata?.email_address}`;
    entry['address'] = `${item.metadata?.full_address}`;
    entry['has_log'] = item.facility_visitor_log_id ? true : false;
    entry['log_id'] = item.facility_visitor_log_id;
    entry['facility_visitor_log'] = item.facility_visitor_log;
    entry['receipt_address'] = item.metadata?.receipt_address;
    entry['guide_mail_check'] = false;
    entry['stop_send_guide_mail'] = item.metadata?.stop_send_guide_mail;
    entry['past_send_method'] = item.metadata?.past_send_method;
    normalized.push(entry);
  }
  return normalized;
}

export default {
  async getCheckinStatus (params) {
    const response = await http.get(`facility-booking/validate-booking`, { params });
    return response.data;
  },
  async get (pagination, filters) {
    const facility = $store.getters['dashboard/facility'];
    const params = { facility_id: facility.id, ...filters, ...pagination }
    const response = await http.get(`facility-booking`, { params });
    const formated = { ...response.data }
    formated.data.data = normalizeData(response.data.data.data);
    return formated;
  },
  async getStatuses () {
    const params = { type: 'filter' }
    const response = await http.get(`booking-statuses`, { params });
    return response.data.data;
  },
  async checkPhone ({ code, number }, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config['exclude-status'] = [429];
    const facility = $store.state.checkin.facility;
    const data = { facility_id: facility.id, mode: 'phone', search: `${code}|${number}` }
    const response = await http.post(`facility-booking/list`, data, { ...config, params });
    return response.data;
  },
  async confirmBooking (phoneNumber, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config['exclude-status'] = [429];
    const facility = $store.state.checkin.facility;
    const data = { facility_id: facility.id, ...params }
    const response = await http.post(`facility-booking/confirm`, phoneNumber, { ...config, params });
    return response.data;
  },
  async checkConfirmLock (no_loader){
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config['exclude-status'] = [429];
    const response = await http.get(`facility-booking/check-confirm-lock` , { ...config });
    return response.data;
  },
  async createStatusLog (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`facility-booking-logs`, data, { ...config });
    return response.data;
  },
  async deleteStatusLog (id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.delete(`facility-booking-logs/${id}`, { ...config });
    return response.data;
  },
}
