import http, { httpmock } from '@/utils/http';

const dashboard_endpoint = "user-managements";
const public_endpoint = "user";

export default {
  async list (params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.get(`${dashboard_endpoint}`, { params }, config);
    return response.data;
  },
  async create (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${dashboard_endpoint}`, { ...data }, config);
    return response.data;
  },
  async update (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.put(`${dashboard_endpoint}/${data.id}`, { ...data }, config);
    return response.data;
  },
  async delete (id, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.delete(`${dashboard_endpoint}/${id}`, config);
    return response.data;
  },
  async verifyEmailExist (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${public_endpoint}/exists`, { ...data }, config);
    return response.data;
  },
  async verifyUserIdExist (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${public_endpoint}/exists/uid`, { ...data }, config);
    return response.data;
  },
  async sendVerification (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${public_endpoint}/send-email-verification`, { ...data }, config);
    return response.data;
  },
  async register (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${public_endpoint}/store`, { ...data }, config);
    return response.data;
  },
}