import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/pages/Index'

import auth from '@/router/routes.auth'
import checkin from '@/router/routes.checkin'
import dashboard from '@/router/routes.dashboard'

import Error401 from '@/pages/error-401'
import Error404 from '@/pages/error-404'
import Error422 from '@/pages/error-422'
import Error429 from '@/pages/error-429'
import Error503 from '@/pages/error-503'
import Tac  from '@/pages/tac'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', component: Index },
    ...auth,
    ...checkin,
    ...dashboard,
    { path: '/error-401', component: Error401 },
    { path: '/error-422', component: Error422 },
    { path: '/error-429', component: Error429 },
    { path: '/error-503', component: Error503 },
    { path: '/terms-and-condition', component: Tac },
    { path: '*', component: Error404 },
  ]
})

export default router
