<template>
  <b-card
    class="accordion-card"
    :class="{ collapsed: collapsed, 'mb-2': !last }"
  >
    <template v-slot:header>
      <div class="click-overlay" @click="collapsed = !collapsed"></div>
      <span class="text-info"><i class="far fa-user-circle"/></span>
      {{ title }}
      <div class="controls px-2">
        <button
          type="button"
          class="btn text-primary"
          title="toggle"
          style="margin-top: 5px"
          @click="collapsed = !collapsed"
        >
          <i class="toggle-icon fas fa-angle-up" />
        </button>
      </div>
    </template>
    <div class="container">
      <div class="row mt-2">
        <!-- Basic Information -->
        <div class="col-12 col-lg-6 mb-3">
          <h5 class="text-primary">
            <i class="fas fa-address-card" />
            {{ $t('dashboard-list.basic-information') }}
          </h5>
          <div class="ghost-fill border rounded-bottom px-2 py-1">
            <table class="w-100 table table-sm hoverable mb-0">
              <!-- 同伴者の領収書宛名は表示しない -->
              <template
                v-for="(detail, index) in data.basic.filter(
                  (detail) => detail.question.system_name != 'receipt_address'
                )"
              >
                <!-- Filter out display of personal info -->
                <tr
                  v-if="
                    personal_info.includes(detail.question.system_name) &&
                      hasAccess('list.personal_information.view')
                  "
                  :key="`basic_${index}`"
                >
                  <td>
                    {{ $t(`system-questions.${detail.question.system_name}`) }}
                  </td>
                  <td v-if="detail.question.system_name == 'nationality'">
                    <div class="constrainer">
                      {{ nationalitynames[detail.answer][$i18n.locale] }}
                    </div>
                  </td>
                  <td v-else>{{ detail.answer }}</td>
                </tr>
                <!-- Display none personal info -->
                <tr v-else :key="`basic_${index}`">
                  <td>
                    {{ $t(`system-questions.${detail.question.system_name}`) }}
                  </td>
                  <!-- <td v-if="detail.question.system_name == 'nationality'">
                    <div class="constrainer">
                      {{ nationalitynames[detail.answer][$i18n.locale] }}
                    </div>
                  </td> -->
                  <td v-if="detail.question.system_name == 'face_recognition'">
                    <div class="constrainer">
                      {{
                        detail.answer.length > 0
                          ? $t(`common.word-yes`)
                          : $t(`common.word-no`)
                      }}
                    </div>
                  </td>
                  <td v-else>
                    <div class="constrainer">{{ translateAnswer(detail) }}</div>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <!-- Passport -->
        <div class="col-12 col-lg-6 mb-3">
          <div class="d-flex justify-content-between align-content-center">
            <h5 class="text-primary">
              <i class="fas fa-passport" />
              {{ $t('dashboard-common.word-passport') }}
            </h5>
            <div
              class="passport-buttons"
              v-show="data.passport_image && showCompanionPassportButton"
            >
              <button
                class="btn btn-outline-primary btn-sm"
                @click="showPhoto(data.passport_image)"
                :disabled="downloadable.loading"
                v-b-tooltip.hover
                :title="$t('dashboard-common.word-view')"
              >
                <i class="fas fa-eye icon" />
              </button>
              <button
                class="btn btn-outline-primary btn-sm"
                @click="downloadPassportPhoto"
                :disabled="downloadable.loading"
                v-b-tooltip.hover
                :title="$t('dashboard-common.word-download')"
              >
                <i class="fas fa-download icon" />
              </button>
              <button
                class="btn btn-outline-primary btn-sm"
                @click="confirmDeletePassportPhoto"
                :disabled="downloadable.loading"
                v-b-tooltip.hover
                :title="$t('dashboard-common.word-delete')"
              >
                <i class="fas fa-trash icon" />
              </button>
            </div>
          </div>
          <div
            v-if="data.passport_image"
            class="ghost-fill border rounded-bottom px-2 py-1"
            @click="showPhoto(data.passport_image)"
          >
            <div class="text-center py-1">
              <load-image
                :url="data.passport_image"
                eventNamePrefix="companion-passport"
                @loaded="
                  (v) => {
                    downloadable.source = v;
                  }
                "
                @companion-passport-image-loaded="
                  handleCompanionPassportImageLoaded
                "
                @companion-passport-no-image="handleCompanionPassportNoImage"
                class="image-preview"
              />
            </div>
          </div>
          <div v-else class="ghost-fill border rounded-bottom px-2 py-1">
            <div class="unavailable">
              <i class="icon fas fa-ban text-muted" />
              <div class="description text-muted">
                {{ $t('common.word-unavailable') }}
              </div>
            </div>
          </div>
        </div>
        <!-- Other Information -->
        <div class="col-12 col-lg-6 mb-3">
          <h5 class="text-primary">
            <i class="fas fa-notes-medical" />
            {{ $t('dashboard-list.other-information') }}
          </h5>
          <div class="ghost-fill border rounded-bottom px-2 py-1">
            <table class="w-100 table table-sm hoverable mb-0">
              <template
                v-if="
                  Array.isArray(data.other) &&
                    data.other.some(
                      (detail) =>
                        detail.question.section !== 'other_info' &&
                        translateAnswer(detail).length > 0
                    )
                "
              >
                <tr
                  v-for="(detail, index) in data.other"
                  :key="`other_${index}`"
                  v-if="detail.question.section != 'other_info'"
                >
                  <td>
                    {{ detail.question.label }}
                  </td>
                  <td>{{ translateAnswer(detail) }}</td>
                </tr>
              </template>
              <div v-else class="unavailable">
                <i class="icon fas fa-ban text-muted" />
                <div class="description text-muted">
                  {{ $t('common.word-unavailable') }}
                </div>
              </div>
            </table>
          </div>
        </div>
        <!-- Signature -->
        <div class="col-12 col-lg-6 mb-3">
          <h5 class="text-primary">
            <i class="fas fa-file-signature" />
            {{ $t('dashboard-common.word-signature') }}
          </h5>
          <div
            v-if="data.signature_image"
            class="ghost-fill border rounded-bottom px-2 py-1"
          >
            <div class="text-center py-1">
              <load-image :url="data.signature_image" class="image-preview" />
            </div>
          </div>
          <div v-else class="ghost-fill border rounded-bottom px-2 py-1">
            <div class="unavailable">
              <i class="icon fas fa-ban text-muted" />
              <div class="description text-muted">
                {{ $t('common.word-unavailable') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<style>
.table.hoverable tr:first-child td {
  border-top-style: none;
}
</style>

<style lang="scss" scoped>
.ghost-fill {
  height: calc(100% - 32px);
  min-height: 200px;
}
.passport-buttons {
  button {
    width: 50px;
    margin-left: 5px;
    margin-top: -3px;
  }
  float: right;
}
.unavailable {
  pointer-events: none;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .icon {
    font-size: 36px;
  }
}
</style>

<script>
import logsApi from '@/api/logs';
import dialogs from '@/mixins/dialogs';
import uac from '@/mixins/uac';
import loadImage from './loadImage';

export default {
  components: { loadImage },
  props: {
    facility_id: { type: Number },
    data: { type: Object, default: () => {} },
    title: { type: String, default: '' },
    last: { type: Boolean, default: false },
    personal_info: { type: Array, default: [] },
    nationalitynames: { type: Object, default: () => {} },
    prefecturenames: { type: Object, default: () => {} },
    genders: { type: Object, default: () => {} },
    occupations: { type: Object, default: () => {} },
    yesno: { type: Object, default: () => {} },
  },
  mixins: [dialogs, uac],
  data() {
    return {
      collapsed: true,
      downloadable: {
        filename: 'downloadable',
        source: '',
        loading: false,
      },
      showCompanionPassportButton: true,
    };
  },
  methods: {
    translateAnswer(detail) {
      const question = detail.question.system_name;
      const answer = detail.answer.trim();
      const notDisplayQuestions = [
        'doctor_certificate',
        'pass_affection',
        'internation_travel',
        'domestic_travel',
        'coughing',
        'body_temperature',
        'transportation',
        'foreign_travel',
        'close_contact_corona_patient',
      ];

      if (notDisplayQuestions.includes(question)) {
        return '';
      }

      // Gender
      if (question == 'gender') {
        if (answer.length < 1) return '';
        return this.genders[$i18n.locale][answer];
      }
      // Nationality
      if (question == 'nationality') {
        try {
          if (answer.length < 1) return '';
          const nationalityObj = this.nationalitynames[answer];
          if (typeof nationalityObj == 'undefined') return '';
          if (typeof nationalityObj[this.$i18n.locale] == 'undefined')
            return '';
          return nationalityObj[this.$i18n.locale];
        } catch (e) {
          console.error(e);
          return 'Atlantis';
        }
      }
      // Occupation
      if (question == 'occupation') {
        if (answer.length < 1) return '';
        if (typeof this.occupations[$i18n.locale][answer] == 'undefined')
          return '';
        return this.occupations[$i18n.locale][answer];
      }
      // Prefecture
      if (question == 'prefecture') {
        if (answer.length < 1) return '';
        if (
          typeof this.prefecturenames[answer.toLocaleLowerCase()] == 'undefined'
        )
          return '';
        return this.prefecturenames[answer.toLocaleLowerCase()][$i18n.locale];
      }
      return answer;
    },
    showPhoto(data) {
      this.$emit('showPhoto', data);
    },
    handleCompanionPassportNoImage() {
      this.showCompanionPassportButton = false;
    },
    handleCompanionPassportImageLoaded() {
      this.showCompanionPassportButton = true;
    },
    downloadPassportPhoto() {
      try {
        const rsv_no = this.data.booking.pms_reservation_no;
        const source = this.data.passport_image;
        const baseurl = this.$http.defaults.baseURL;
        const link = `${baseurl}/log/download-image?path=${source}&rsv_no=${rsv_no}`;
        const anchor = document.createElement('a');
        anchor.setAttribute('href', link);
        anchor.setAttribute('download', link);
        anchor.setAttribute('style', 'display: none');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t(`common.error-generic`), {
          position: 'top',
        });
      }
    },
    confirmDeletePassportPhoto() {
      this.confirm(this.$t(`dashboard-list.confirm-passport-delete`), {
        yes: this.deletePassportPhoto,
      });
    },
    async deletePassportPhoto() {
      try {
        const params = { type: 'companion', facility_id: this.facility_id };
        await logsApi.deletePassportPhoto(this.data.log_id, params);
        await this.$emit('showDetails', this.guest_index);
        this.$toast.success(
          this.$t(`dashboard-list.message-passport-deleted`),
          {
            position: 'top',
          }
        );
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },
  },
};
</script>
