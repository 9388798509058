<template>
  <div>
    <input type="hidden" :value="changeTitle()" />
    <router-view />
    <loading
      :active.sync="cs.isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="bars"
      :width="70"
      :height="70"
      :color="$theme.primary"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    changeTitle() {
      const title = {
        en: "PreCheck-in_プリ・チェックイン",
        ja: process.env.VUE_APP_TITLE,
        ko: "PreCheck-in_プリ・チェックイン",
        "zh-CN": "PreCheck-in_プリ・チェックイン",
        "zh-TW": "PreCheck-in_プリ・チェックイン",
      };

      document.title = title[this.$i18n.locale];
    }
  },
  computed: {
    ...mapState("checkin-site", {
      cs: state => state
    })
  }
};
</script>
