import http, { httpmock } from '@/utils/http';


const replaceEquals = (value) => {
  return value
  .replace(/=/g, '&&EQUAL&&')
  .replace(/\?/g, '&&QUESTION&&')
  .replace(/'/g, '&&SINGLEQUOTE&&')
  .replace(/,/g, '&&COMMA&&')
  .replace(/\./g, '&&DOT&&');
};

const sendRequest = async (url, data, no_loader) => {
  const config = no_loader ? { headers: { 'no-loader': true } } : {};
  const encryptedData = { ...data, template: data.template ? replaceEquals(data.template) : undefined };
  const response = await http.post(url, encryptedData, config);
  return response;
};
export default {
  async sendPciGuide(data, no_loader) {
      return sendRequest('preview/email/pre-check-in', data, no_loader);
  },

  async sendPciConfirm (data, no_loader) {
    return sendRequest('preview/email/confirm-booking', data, no_loader);
  },

  async resend (data, no_loader) {
    return sendRequest('log/resend', data, no_loader);
  }
};
