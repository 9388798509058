import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  getters: {},
  mutations: {
    loading (state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  actions: {
    initialize ({ dispatch }) {
      const fallback = 'en'
      const langEn = /en-.*/
      const langJP = /ja-.*/
      const languages = ['ja', 'en']

      let language = langEn.test(navigator.language) ? 'en' : navigator.language

      language = langJP.test(navigator.language) ? 'ja' : language

      if (!languages.includes(language)) language = fallback

      i18n.locale = language
    }
  }
}