<template>
  <div>
    <form @submit.prevent="submit">
      <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
        <p>{{ $t("dashboard-settings.instruction-languages") }}</p>
        <table class="table table-bordered hoverable table-sm">
          <thead>
            <tr>
              <th>
                {{ $t("dashboard-settings.languages-column-languages") }}
              </th>
              <th>{{ $t("dashboard-settings.languages-column-option") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lang, index) in languageOptions" :key="`lang_${index}`">
              <td>
                {{ $t(`language-options.${lang.code}`) }}
              </td>
              <td>
                <toggle-button
                  @change="toggleOption"
                  v-model="languageOptions[index].enable"
                  :disabled="
                    lang.required || !hasAccess('setting.language_setting.edit')
                  "
                  :labels="{
                    checked: 'ON',
                    unchecked: 'OFF',
                  }"
                  :width="70"
                  :value="lang.enable"
                  :sync="true"
                  :color="{checked: $theme.purple, unchecked: $theme.red}"
                />
                <span v-if="lang.required">
                  ({{ $t("common.word-required") }})
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12" align="right">
        <button
          v-if="hasAccess('setting.language_setting.edit')"
          @click="submit"
          :disabled="!dirty"
          type="button"
          class="btn btn-warning btn-wide"
        >
          <i class="fa fa-save mr-1" />
          {{ $t("dashboard-settings.button-save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.table {
  td,
  th {
    vertical-align: middle !important;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 38px;
  }
  td.controls {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import settingsApi from "@/api/settings";
import uac from "@/mixins/uac";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  mixins: [uac],
  data() {
    return {
      ...mapState(["languages"]),
      languageOptions: [
        {
          name: "ja",
          code: "ja",
          enable: true,
          required: true
        },
        {
          name: "en",
          code: "en",
          enable: false,
          required: false
        },
        {
          name: "ko",
          code: "ko",
          enable: false,
          required: false
        },
        {
          name: "zh-cn",
          code: "zh-CN",
          enable: false,
          required: false
        },
        {
          name: "zh-tw",
          code: "zh-TW",
          enable: false,
          required: false
        }
      ],
      data: {
        name: "language",
        value: []
      },
      dirty: false
    };
  },
  async mounted() {
    this.data.facility_id = this.facility.id;
    try {
      const response = await settingsApi.getLanguages(this.facility.id);
      if (response.total != 0) {
        response.data[0].value.forEach((lang, key) => {
          var index = this.languageOptions.findIndex(x => x.name == lang.name);
          this.languageOptions[index].enable = true;
        });
      }
    } catch (e) {
      console.error(e.message);
      this.$toast.error(this.$t("dashboard-common.error-generic"), {
        position: "top"
      });
    }
  },
  methods: {
    ...mapActions(["updateLanguages"]),
    async toggleOption() {
      this.dirty = true;
      this.data.value = [];
      this.languageOptions.forEach((value, key) => {
        if (value.enable === true) {
          this.data.value.push({
            name: this.languageOptions[key].name,
            code: this.languageOptions[key].code
          });
        }
      });
    },
    async submit() {
      if (this.data.value.length != 0) {
        try {
          await settingsApi.save({ ...this.data });
          this.updateLanguages(this.data.value);
          this.$toast.success(this.$t("dashboard-common.saved-generic"), {
            position: "top"
          });
        } catch (e) {
          this.$toast.error(this.$t("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      } else {
        this.toggleOption();
        this.submit();
      }
    }
  }
};
</script>