<template>
  <error-page-layout>
    <span class="display-1 d-block text-primary pt-4">
      <i class="fa fa-wrench"></i>
    </span>
    <div class="mb-4 mt-4 lead">{{ $t("error-pages.body-503") }}</div>
  </error-page-layout>
</template>

<script>
import ErrorPageLayout from "@/layouts/ErrorPageLayout";

export default {
  components: { ErrorPageLayout }
};
</script>