import http, { httpmock } from '@/utils/http';

const endpoint = 'custom-messages';
const defaults = {
  author: '',
  updated_at: ''
}

function normalizeData(data) {
  const { id, name, value, updated_at, created_at, facility_id, activity_log } = data;
  const normalized = { id, name, value, updated_at, created_at, facility_id }
  if (activity_log) {
    normalized.author = activity_log.user ? activity_log.user.username : '';
  }
  return normalized;
}

export default {
  async getSMS (facility_id, lang, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      facility_id,
      name: `message-sms`,
      value: '',
      author: defaults.author,
      updated_at: defaults.updated_at
    }
    const params = { name: meta.name, hide_default: false }
    const response = await http.get(`${endpoint}/${facility_id}`, { params }, config);

    let mergedData = [];
    response.data.data.forEach((data, index) => {
      let merged = {};
      const normalized = normalizeData(data);
      for (const key of Object.keys(meta)) {
        try {
          merged[key] = normalized[key];
        } catch (e) { merged = meta; }
      }
      mergedData.push(merged);
    });
    return mergedData;
  },
  async getEmailGuide (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      facility_id,
      name: `pre-email`, // message-email-guide
      value: '',
      author: defaults.author,
      updated_at: defaults.updated_at
    }
    const params = { name: meta.name, hide_default: false }
    const response = await http.get(`${endpoint}/${facility_id}`, { params }, config);

    let mergedData = [];
    response.data.data.forEach((data, index) => {
      let merged = {};
      const normalized = normalizeData(data);

      // 取得時に "==EQUAL==" を "=" に戻す
      normalized.value = this.restoreEquals(normalized.value);

      for (const key of Object.keys(meta)) {
        try {
          merged[key] = normalized[key];
        } catch (e) { merged = meta; }
      }
      mergedData.push(merged);
    });
    return mergedData;
  },
  async getEmailConfirm (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      facility_id,
      name: `message-template-1`, // message-email-confirm
      value: '',
      author: defaults.author,
      updated_at: defaults.updated_at
    }
    const params = { name: meta.name, hide_default: false }
    const response = await http.get(`${endpoint}/${facility_id}`, { params }, config);
    const normalized = normalizeData(response.data.data);

    let merged = {}
    for (const key of Object.keys(meta)) {
      try {
        if (normalized[key]) { merged[key] = normalized[key]; }
        else { merged[key] = meta[key]; }
      } catch (e) { merged = meta; }
    }

    return merged;
  },
  async getLanding (facility_id, lang, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      facility_id,
      name: `message-landing2-${lang}`,
      value: '',
      author: defaults.author,
      updated_at: defaults.updated_at
    }

    const params = { name: meta.name, hide_default: false }
    const response = await http.get(`${endpoint}/${facility_id}`, { params }, config);
    const normalized = normalizeData(response.data.data);

    let merged = {}
    for (const key of Object.keys(meta)) {
      try {
        if (normalized[key]) { merged[key] = normalized[key]; }
        else { merged[key] = meta[key]; }
      } catch (e) { merged = meta; }
    }
    return merged;
  },
  async getLanding2State (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      facility_id,
      name: `state-landing2-enabled`,
      value: false
    }
    const params = { name: meta.name, hide_default: false }
    const response = await http.get(`${endpoint}/${facility_id}`, { params }, config);
    const normalized = normalizeData(response.data.data);

    let merged = {}
    for (const key of Object.keys(meta)) {
      try {
        if (normalized[key]) { merged[key] = normalized[key]; }
        else { merged[key] = meta[key]; }
      } catch (e) { merged = meta; }
    }
    return merged;
  },
  async save (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const meta = {
      name: '',
      value: '',
      author: defaults.author,
      updated_at: defaults.updated_at
    }

    const beforeEscape = { ...data };
    beforeEscape.value = this.replaceEquals(data.value);
    const response = await http.post(`${endpoint}`, { ...beforeEscape }, config);

    // レスポンスの `value` フィールドは元に戻します
    const normalized = normalizeData(response.data.data);
    normalized.value = this.restoreEquals(normalized.value);

    let merged = {}
    for (const key of Object.keys(meta)) {
      try {
        if (normalized[key]) { merged[key] = normalized[key]; }
        else { merged[key] = meta[key]; }
      } catch (e) { merged = meta; }
    }
    return merged;
  },

  // テキスト置換のユーティリティ関数
  replaceEquals(value) {
    if (typeof value === 'string') {
    return value
    .replace(/=/g, '&&EQUAL&&')
    .replace(/\?/g, '&&QUESTION&&')
    .replace(/'/g, '&&SINGLEQUOTE&&')
    .replace(/,/g, '&&COMMA&&')
    .replace(/\./g, '&&DOT&&');
    }
    return value;
  },
  restoreEquals(value) {
    if (typeof value === 'string') {
    return value
    .replace(/&&EQUAL&&/g, '=')
    .replace(/&&QUESTION&&/g, '?')
    .replace(/&&SINGLEQUOTE&&/g, "'")
    .replace(/&&COMMA&&/g, ',')
    .replace(/&&DOT&&/g, '.');
    }
    return value;
  },
}
