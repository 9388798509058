<template>
  <div v-if="hasAccess('notice_setting.frequency.view')">
    <h1 class="h3 mb-2 text-gray-800">
      {{ $t("dashboard-notifications.title") }}
    </h1>
    <p>{{ $t("dashboard-notifications.description") }}</p>
    <p>{{ displayPriorityMessage() }}</p>
    <form @submit.prevent="save">
      <div class="p-3 mb-3 border rounded">
        <table class="table-list">
          <tr>
            <td class="border-bottom">
              {{ $t("dashboard-notifications.column-moment") }}
            </td>
            <td class="border-bottom">
              {{ $t("dashboard-notifications.column-send") }}
            </td>
            <td class="border-bottom">
              {{ $t("dashboard-notifications.column-not-send") }}
            </td>
            <td class="border-bottom">
              {{ $t("dashboard-notifications.column-time") }}
            </td>
          </tr>
          <tr>
            <td class="pt-3">
              {{ $t("dashboard-notifications.label-after-reserve") }}
            </td>
            <td class="pt-3">
              <b-form-radio
                v-model="data.value.data[0].status"
                name="after_rsv"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="pt-3">
              <b-form-radio
                v-model="data.value.data[0].status"
                name="after_rsv"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="pt-3">
        
            </td>
          </tr>
          <tr>
            <td>{{ $t("dashboard-notifications.label-3days-prior") }}</td>
            <td>
              <b-form-radio
                v-model="data.value.data[1].status"
                name="days_no_3"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td>
              <b-form-radio
                v-model="data.value.data[1].status"
                name="days_no_3"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="text-monospace">
              <b-form-timepicker
                hide-header
                v-model="data.value.data[1].time"
                :minutes-step="30"
                offset="-1"
                no-close-button
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("dashboard-notifications.label-2days-prior") }}</td>
            <td>
              <b-form-radio
                v-model="data.value.data[2].status"
                name="days_no_2"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td>
              <b-form-radio
                v-model="data.value.data[2].status"
                name="days_no_2"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="text-monospace">
              <b-form-timepicker
                hide-header
                v-model="data.value.data[2].time"
                :minutes-step="30"
                offset="-1"
                no-close-button
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
              <!-- <input
                v-model="data.value.data[2].time"
                class="form-control"
                type="time"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              /> -->
            </td>
          </tr>
          <tr>
            <td>{{ $t("dashboard-notifications.label-1day-prior") }}</td>
            <td>
              <b-form-radio
                v-model="data.value.data[3].status"
                name="days_no_1"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td>
              <b-form-radio
                v-model="data.value.data[3].status"
                name="days_no_1"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="text-monospace">
              <b-form-timepicker
                hide-header
                v-model="data.value.data[3].time"
                :minutes-step="30"
                offset="-1"
                no-close-button
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
              <!-- <input
                v-model="data.value.data[3].time"
                class="form-control"
                type="time"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              /> -->
            </td>
          </tr>
          <tr>
            <td>{{ $t("dashboard-notifications.label-arrival-day1") }}</td>
            <td>
              <b-form-radio
                v-model="data.value.data[4].status"
                name="days_no_arrival"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td>
              <b-form-radio
                v-model="data.value.data[4].status"
                name="days_no_arrival"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="text-monospace">
              <b-form-timepicker
                hide-header
                v-model="timePicker4"
                :minutes-step="30"
                offset="-1"
                no-close-button
                :disabled="!hasAccess('notice_setting.frequency.edit')"
                :state="this.timePickerState"
              />
              <small v-if="this.timePickerState != null" class="form-message text-danger">{{ $t("dashboard-notifications.error-duplicate-time") }}</small>
              <!-- <input
                v-model="data.value.data[4].time"
                class="form-control"
                type="time"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              /> -->
            </td>
          </tr>
          <tr>
            <td>{{ $t("dashboard-notifications.label-arrival-day2") }}</td>
            <td>
              <b-form-radio
                v-model="data.value.data[5].status"
                name="days_no_arrival2"
                :value="1"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td>
              <b-form-radio
                v-model="data.value.data[5].status"
                name="days_no_arrival2"
                :value="0"
                :disabled="!hasAccess('notice_setting.frequency.edit')"
              />
            </td>
            <td class="text-monospace">
              <b-form-timepicker
                hide-header
                v-model="timePicker5"
                :minutes-step="30"
                offset="-1"
                no-close-button
                :disabled="!hasAccess('notice_setting.frequency.edit')"
                :state="this.timePickerState"
              />
              <small v-if="this.timePickerState != null" class="form-message text-danger">{{ $t("dashboard-notifications.error-duplicate-time") }}</small>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <ul>
                <li><small>{{ $t("dashboard-notifications.footnote-1") }}</small></li>
                <li v-if="!data.value.sms_priority"><small>{{ $t("dashboard-notifications.footnote-2") }}</small></li>
                <li><small>{{ $t("dashboard-notifications.footnote-3") }}</small></li>
                <li><small>{{ $t("dashboard-notifications.footnote-4") }}</small></li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <div class="text-right">
        <button
          type="submit"
          href="#"
          class="btn btn-warning btn-wide"
          :disabled="!hasAccess('notice_setting.frequency.edit')"
        >
          <i class="fas fa-save mr-1" />
          {{ $t("common.word-save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.table-list {
  width: 100%;
  td {
    vertical-align: middle;
    padding: 8px;
  }
}
</style>

<script>
import settingsApi from "@/api/settings";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";

export default {
  beforeMount() {
    this.data.facility_id = this.facility.id;
  },
  async mounted() {
    this.updateActiveMenu("notifications");
    try {
      const response = await settingsApi.getNotifications(this.facility.id);
      if (response.total > 0) {
        const notification_frequency_settings_data = {...this.data.value.data, ...response.data[0].value.data}; //当日2つ目のデータがDBにないため直後の処理で上書きされてしまうため一旦退避させる
        const data = { ...this.data, ...response.data[0] };
        data.value.data = notification_frequency_settings_data;
        this.data = data;
        this.timePicker4 = this.data.value.data[4].time;
        this.timePicker5 = this.data.value.data[5].time;
      }
      this.loaded = true;
    } catch (e) {
      console.error(e.message);
      this.$toast.error(this.$t("dashboard-common.error-generic"), {
        position: "top"
      });
    }
  },
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  data() {
    return {
      dirty: false,
      loaded: false,
      timeOptions: {
        start: '00:00',
        step: '00:30',
        end: '12:30',
      },
      data: {
        name: "notification_frequency_settings",
        value: {
          data: [
            {
              time: "00:00",
              moment: "After Reservation",
              status: 0,
              default: true
            },
            {
              time: "08:00",
              moment: "3 days prior to Arrival",
              status: 0,
              days_no: 3,
              default: false
            },
            {
              time: "00:00",
              moment: "2 days prior to Arrival",
              status: 0,
              days_no: 2,
              default: true
            },
            {
              time: "00:00",
              moment: "1 day prior to Arrival",
              status: 0,
              days_no: 1,
              default: true
            },
            {
              time: "00:00",
              moment: "Arrival Day",
              status: 0,
              default: true
            },
            {
              time: "00:00",
              moment: "Arrival Day2",
              status: 0,
              default: true
            }
          ],
          sms_priority: false
        }
      },
      timePicker4: '00:00',
      timePicker5: '00:00',
      timePickerState: null
    };
  },
  mixins: [uac],
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    async save() {
      // b-form-timepickerで変更するとフォーマットが変わり比較できないため統一する
      const formatTime4 = this.timePicker4.slice(0, 5);
      const formatTime5 = this.timePicker5.slice(0, 5);
      if(formatTime4 == formatTime5){
        this.timePickerState = false
        this.$toast.error(this.$t('common.error-generic'), { position: 'top' });
        return;
      }
      this.data.value.data[4].time = formatTime4;
      this.data.value.data[5].time = formatTime5;
      try {
        const response = await settingsApi.updateFrequency(this.data);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    displayPriorityMessage() {
      if (this.data.value.sms_priority != null && this.data.value.sms_priority) {
        return this.$t("dashboard-notifications.send-sms-description");
      } else {
        return this.$t("dashboard-notifications.send-email-description");
      }
    }
  },
  watch: {
    data() {
      if (this.loaded) this.dirty = true;
    },
    timePicker4(oldValue, newValue) {
      if(oldValue != newValue){
        this.timePickerState = null
      }
    },
    timePicker5(oldValue, newValue) {
      if(oldValue != newValue){
        this.timePickerState = null
      }
    }
  }
};
</script>
