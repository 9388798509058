<template>
  <auth-layout>
    <template v-slot:header>
      <div class="text-center">
        <i v-if="step == 'complete'" class="fas fa-check-circle header-icon" />
        <i v-else-if="step == 'sent'" class="fas fa-paper-plane header-icon" />
        <i v-else class="fas fa-user-plus header-icon" />
      </div>
    </template>
    <h4 class="text-center">
      {{ title }}
    </h4>
    <p
      v-if="step == 'verify'"
      class="text-center border rounded border-secondary p-2 mt-3 mb-3 bg-light"
    >
      {{ $t("auth.instruction-verify") }}
    </p>
    <p v-if="step == 'sent'" class="text-center mb-1">
      {{ $t("auth.message-email-sent", { email: verifyData.email }) }}
    </p>
    <div
      v-if="step != 'verify' && step != 'sent' && !errorMessage"
      class="d-flex justify-content-center py-3"
    >
      <register-stepper :step="step" />
    </div>
    <!-- Verify -->
    <form v-if="step == 'verify'" @submit.prevent="submitVerify">
      <div>
        <text-field
          type="email"
          :label="$t(`auth.label-enter-email`)"
          placeholder="e.g. username@mail.com"
          v-model="verifyData.email"
          required
          @input="validateEmail"
          :error="errors.email"
        />
      </div>
      <!-- <div class="text-center">
        <a href="#" @click.prevent="openTerms">
          {{ $t("auth.text-terms-conditions") }}
        </a>
      </div>
      <div class="text-center mb-3">
        <b-form-checkbox v-model="verifyData.acceptTerms">
          <span class="ml-1">{{ $t("auth.text-terms-accept") }}</span>
        </b-form-checkbox>
      </div> -->
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-min">
          {{ $t(`auth.button-submit`) }}
        </button>
      </div>
    </form>
    <!-- Register: User -->
    <form v-if="step == 'user'" @submit.prevent="next">
      <b-alert v-if="errorMessage" variant="danger" show class="text-center">
        {{ errorMessage }}
      </b-alert>
      <template v-else>
        <div>
          <text-field
            type="email"
            :label="$t(`auth.label-email`)"
            placeholder="e.g. username@mail.com"
            v-model="userData.email"
            required
            @input="validateEmail"
            :error="errors.email"
            :disabled="true"
          />
        </div>
        <div>
          <text-field
            :label="$t(`auth.label-username`)"
            placeholder="e.g. User01"
            v-model="userData.username"
            required
            @input="validateUsername"
            :error="errors.username"
          />
        </div>
        <div>
          <text-field
            type="password"
            :label="$t(`auth.label-password`)"
            placeholder="e.g. Password100%"
            v-model="userData.password"
            required
            @input="validatePassword"
            :error="errors.password"
          />
        </div>
        <div>
          <text-field
            type="text"
            :label="$t(`auth.label-last-name`)"
            :placeholder="$t(`auth.label-last-name`)"
            v-model="userData.last_name"
            required
            @input="errors.last_name = ''"
            :error="errors.last_name"
          />
        </div>
        <div>
          <text-field
            type="text"
            :label="$t(`auth.label-first-name`)"
            :placeholder="$t(`auth.label-first-name`)"
            v-model="userData.first_name"
            required
            @input="errors.first_name = ''"
            :error="errors.first_name"
          />
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary btn-min">
            {{ $t(`auth.button-next`) }}
          </button>
        </div>
      </template>
    </form>
    <!-- Register: Facility -->
    <form v-if="step == 'facility'" @submit.prevent="submit">
      <div>
        <text-field
          type="text"
          :label="$t(`auth.label-hotel-name`)"
          placeholder="e.g. Hotel California"
          v-model="facilityData.name"
          required
          @input="errors.name = ''"
          :error="errors.name"
        />
      </div>
      <div>
        <text-field
          :label="$t(`auth.label-unique-hotel-id`)"
          placeholder="e.g. hotel_california"
          v-model="facilityData.slug_name"
          required
          @keypress="allowSlugChars"
          @paste="allowSlugChars"
          @input="errors.slug_name = ''"
          :error="errors.slug_name"
        />
      </div>
      <div>
        <select-field
          :error="errors.country"
          :label="$t(`auth.label-country`)"
          :options="nationalityOptions()"
          v-model="facilityData.country"
          required
        />
      </div>
      <div>
        <text-field
          type="text"
          :label="$t(`auth.label-state`)"
          :placeholder="'California'"
          v-model="facilityData.state"
          required
          @input="errors.state = ''"
          :error="errors.state"
        />
      </div>
      <div>
        <text-field
          type="text"
          :label="$t(`auth.label-city`)"
          :placeholder="$t(`auth.label-city`)"
          v-model="facilityData.city"
          required
          @input="errors.city = ''"
          :error="errors.city"
        />
      </div>
      <div>
        <text-field
          type="text"
          :label="$t(`auth.label-address`)"
          :placeholder="$t(`auth.label-address`)"
          v-model="facilityData.address"
          required
          @input="errors.address = ''"
          :error="errors.address"
        />
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary btn-min mr-1"
          @click.prevent="step = 'user'"
        >
          {{ $t(`auth.button-back`) }}
        </button>
        <button type="submit" class="btn btn-primary btn-min">
          {{ $t(`auth.button-next`) }}
        </button>
      </div>
    </form>
    <!-- Register: Complete -->
    <p v-if="step == 'complete'">{{ $t(`auth.message-registration-saved`) }}</p>
    <template v-slot:footer>
      <div class="d-flex justify-content-around px-3 mt-n1">
        <!-- <router-link class="small" to="login">{{
          $t(`auth.label-login`)
        }}</router-link>
        <router-link class="small" to="password-reset">{{
          $t(`auth.link-reset`)
        }}</router-link> -->
      </div>
    </template>
  </auth-layout>
</template>

<script>
import usersApi from "@/api/users";
import facilitiesApi from "@/api/facilities";
import AuthLayout from "@/layouts/AuthLayout";
import TextField from "@/components/form-fields/TextField";
import SelectField from "@/components/form-fields/SelectField";
import RegisterStepper from "@/components/RegisterStepper";

import nationalities from "@/utils/mockups/nationalities";

const specialCharRegex = /[\.\,\+\*\?\^\$\(\)\[\]\{\}\|\\!@#%&~`]+/;
// const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
import * as EmailValidator from "email-validator";

export default {
  async created() {
    const query = this.$query.parse(location.search);
    if (query.email) {
      const emailIsValid = this.validateEmailFormat(query.email);
      if (!emailIsValid) return this.$router.replace({ path: "error-404" });
      this.step = "user";
      const emailExist = await this.checkEmailExist(query.email);
      if (emailExist)
        this.errorMessage = this.$t("auth.message-email-unavailable");
      this.userData.email = query.email;
    }
  },
  components: { AuthLayout, TextField, RegisterStepper, SelectField },
  computed: {
    title() {
      if (this.step == "sent" || this.step == "complete") {
        return this.$t(`auth.title-success`);
      }
      return this.$t(`auth.title-register`);
    }
  },
  data() {
    return {
      step: "verify", // verify, sent, user, facility, complete
      errorMessage: "",
      verifyData: {
        acceptTerms: false,
        email: null
      },
      userData: {
        email: null,
        username: null,
        password: null,
        first_name: null,
        last_name: null
      },
      facilityData: {
        country: null,
        building: null,
        slug_name: null,
        name: null,
        state: null,
        city: null,
        address: null,
        user_id: null
      },
      errors: {
        email: "",
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        country: "",
        building: "",
        slug_name: "",
        name: "",
        state: "",
        city: "",
        address: ""
      }
    };
  },
  methods: {
    openTerms() {
      window.open(process.env.VUE_APP_FACILITY_TERMS_URL, "_blank");
    },
    async submitVerify() {
      const emailExist = await this.checkEmailExist(this.verifyData.email);
      if (emailExist) {
        this.errors.email = this.$t("auth.message-email-unavailable");
        return;
      }
      try {
        const verify = await usersApi.sendVerification({
          email: this.verifyData.email
        });
        this.step = "sent";
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    },
    async next() {
      const userIdExist = await this.checkUserIdExist(this.userData.username);
      if (userIdExist) {
        this.errors.username = this.$t("auth.message-userid-unavailable");
        return;
      }
      this.step = "facility";
    },
    async storeUser() {
      try {
        this.clearErrors();
        const response = await usersApi.register(this.userData);
        const user = response.data[0];
        this.facilityData.user_id = user.id;
        return true;
      } catch ({ response }) {
        const status = response.status;
        console.log(status);
        if (status == 422) {
          const error_messages = {
            email: this.$t("validations.already-taken"),
            username: this.$t("validations.already-taken")
          };
          for (let field of Object.keys(response.data.errors)) {
            this.errors[field] = error_messages[field];
          }
          this.$toast.warning(this.$t("auth.error-failed-validation"), {
            position: "top"
          });
          return false;
        }
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
        return false;
      }
    },
    async storeFacility() {
      this.clearErrors();
      try {
        const response = await facilitiesApi.register(this.facilityData);
        this.step = "complete";
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    },
    async submit() {
      // Recheck if userid exist.
      const userIdExist = await this.checkUserIdExist(this.userData.username);
      if (userIdExist) {
        this.errors.username = this.$t("auth.message-userid-unavailable");
        return;
      }
      // Check if slug exist.
      const slugExists = await this.checkSlugExist(this.facilityData.slug_name);
      if (slugExists) {
        this.errors.slug_name = this.$t("validations.already-taken");
        this.$toast.warning(this.$t("auth.error-failed-validation"), {
          position: "top"
        });
        return;
      }
      // Submit to create user.
      if (this.facilityData.user_id == null) {
        const storeUser = await this.storeUser();
        if (!storeUser) {
          this.step = "user";
          return;
        }
      }
      // Submit to create facility.
      await this.storeFacility();
    },
    allowSlugChars(e) {
      const value = e.type == "paste" ? e.clipboardData.getData("text") : e.key;
      const allowed = /^[A-Za-z0-9_]+$/;
      if (!allowed.test(value)) e.preventDefault();
    },
    async checkEmailExist(email) {
      try {
        const verify = await usersApi.verifyEmailExist({ email });
        return verify.data.exist;
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    },
    async checkUserIdExist(userid) {
      try {
        const verify = await usersApi.verifyUserIdExist({ username: userid });
        return verify.data.exist;
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    },
    async checkSlugExist(slug_name) {
      try {
        const verify = await facilitiesApi.slugExists({ slug_name });
        return verify.data.exist;
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    },
    clearErrors() {
      const cleaned = {};
      for (let error of Object.keys(this.errors)) cleaned[error] = "";
      this.errors = cleaned;
    },
    nationalityOptions() {
      const list = nationalities[$i18n.locale];
      const filtered = [];
      if (list) {
        for (let entry of list) {
          filtered.push({ value: entry.CountryCode, text: entry.Nationality });
        }
      }
      return filtered;
    },
    validateUsername(v, e) {
      this.errors.username = "";
      const value = e.target.value;
      let error = false;
      if (value.length < 1) {
        e.target.setCustomValidity(this.$t("validations.required"));
        error = true;
      } else if (!/[a-z]+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-lowercase"));
      } else if (!/[A-Z]+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-uppercase"));
      } else if (!/\d+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-number"));
      } else if (value.length < 6) {
        e.target.setCustomValidity(this.$t("validations.require-min-6"));
      } else {
        e.target.setCustomValidity("");
      }
      if (error) e.target.checkValidity();
    },
    validatePassword(v, e) {
      this.errors.password = "";
      const value = e.target.value;
      let error = false;
      if (value.length < 1) {
        e.target.setCustomValidity(this.$t("validations.required"));
        error = true;
      } else if (!/[a-z]+/.test(value)) {
        // e.target.setCustomValidity(this.$t("validations.require-lowercase"));
        e.target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!/[A-Z]+/.test(value)) {
        // e.target.setCustomValidity(this.$t("validations.require-uppercase"));
        e.target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!/\d+/.test(value)) {
        // e.target.setCustomValidity(this.$t("validations.require-number"));
        e.target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!specialCharRegex.test(value)) {
        // e.target.setCustomValidity(this.$t("validations.require-special"));
        e.target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (value.length < 8) {
        // e.target.setCustomValidity(this.$t("validations.require-min-8"));
        e.target.setCustomValidity(this.$t("validations.invalid-field"));
      } else {
        e.target.setCustomValidity("");
      }
      if (error) e.target.checkValidity();
    },
    validateEmail(v, e) {
      this.errors.email = "";
      const email = e.target.value;
      let isValid = false;
      if (!this.validateEmailFormat(email)) {
        e.target.setCustomValidity(this.$t("validations.invalid-email"));
      } else {
        e.target.setCustomValidity("");
        isValid = true;
      }
      e.target.checkValidity();
      return isValid;
    },
    validateEmailFormat(v) {
      if (/\+/.test(v)) return false;
      return EmailValidator.validate(String(v).toLowerCase());
    }
  }
};
</script>
