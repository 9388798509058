<template>
  <b-form-group :label="label" :class="`checkbox-group ${error.length > 0 ? 'errored' : ''}`">
    <template #label>
     <div class="flex-center-label">
        {{ label }}
        <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
      </div>
    </template>
    <b-form-checkbox-group
      v-model="selected"
      :options="options"
      :required="required"
      name="checkbox_name"
      @input="(v) => $emit('input', v)"
    />
    <small
      v-if="error.length > 0"
      class="form-message text-danger"
      >{{ error }}</small
    >
  </b-form-group>
</template>

<style lang="scss">
@import "~@/assets/scss/variables";
</style>

<script>
export default {
  props: {
    options: { type: Object | Array, default: () => {} },
    required: { type: Boolean, default: false },
    label: { type: String, default: "" },
    value: { type: Object | String, default: () => {} },
    error: { type: String, default: "" }
  },
  async mounted(){
    if(this.value){
      this.selected = this.value
    }
  },
  data() {
    return {
      selected: []
    };
  },
  watch: {
    value() {
      if(this.value){
        this.selected = this.value
      }
    }
  }
};
</script>