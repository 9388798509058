export default {
  namespaced: true,
  state: {
    imageLoaderCounter: 0
  },
  getters: {},
  mutations: {
    imageLoaderIncrement (state) {
      state.imageLoaderCounter++
    },
    imageLoaderDecrement (state) {
      state.imageLoaderCounter--
    }
  },
  actions: {
    initialize ({ dispatch }) { }
  }
}