<template>
  <div class="form-group" :id="id" :class="{ errored: error.length > 0 }">
    <label for="passport" class="flex-center-label">
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
    </label>
    <div class="">
      <input
        type="file"
        accept="image/*"
        ref="capture_camera"
        style="display: none"
        capture
        @change="inputChanged"
      />
      <input
        type="file"
        accept="image/*"
        ref="file_upload"
        style="display: none"
        @change="inputChanged"
      />
      <div class="mb-2">
        <b-button
          variant="outline-primary"
          class="mr-2 btn-min"
          @click="showCamera"
        >
          <i class="fa fa-camera"></i>
          {{ $t("common.word-camera") }}
        </b-button>
        <b-button
          variant="outline-primary"
          class="btn-min"
          @click="$refs.file_upload.click()"
        >
          <i class="fa fa-upload"></i>
          {{ $t("common.word-upload") }}
        </b-button>
      </div>
      <div
        class="form-control image-slot"
        :class="{
          'border-primary': error.length < 1,
          'border-danger': error.length > 0,
        }"
      >
        <img
          v-show="value.length > 5 && error.length < 1"
          class="img-contain"
          :src="value"
          @error="invalidImage"
        />
      </div>
      <small class="form-message text-danger">{{ error }}</small>
    </div>
    <!-- Webcam Modal -->
    <b-modal
      size="lg"
      :id="`capture_modal_${id}`"
      hide-header
      @show="webcamStart"
      @hide="webcamStop"
    >
      <div>
        <web-cam
          :ref="`webcam_screen_${id}`"
          selectFirstDevice
          @notsupported="enableWebcamError"
        />
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-primary btn-round mx-2"
            style="width: 48px; height: 48px"
            @click="webcamCapture"
          >
            <i class="fas fa-camera fa-lg" />
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-round mx-2"
            style="width: 48px; height: 48px"
            @click="() => $bvModal.hide(`capture_modal_${id}`)"
          >
            <i class="fas fa-times fa-lg" />
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.image-slot {
  padding: 5px;
  height: 100%;
  min-height: 38px;
  max-height: 300px;
}
.img-contain {
  max-height: calc(300px - 10px);
}
</style>

<script>
import { WebCam } from "vue-web-cam";
export default {
  mounted() {
    this.isMobile =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
  },
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    required: { type: Boolean, default: false },
    error: { type: String, default: "" },
    id: String,
  },
  components: { WebCam },
  data() {
    return {
      imageData: "",
      isMobile: false,
    };
  },
  methods: {
    inputChanged(e) {
      if (e.target.files.length < 1) return;

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width * 0.7;
          canvas.height = img.height * 0.7;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const resizedImage = canvas.toDataURL();

          this.imageData = resizedImage;
          this.$emit("input", resizedImage);
        };

        img.onerror = () => {
          this.invalidImage();
        };

        img.src = e.target.result;
      };

      reader.onerror = (e) => {
        console.log("Unsupported image format.");
        this.$toast.error("Unsupported image format.");
      };

      reader.readAsDataURL(file);
    },

    invalidImage() {
      if (this.imageData.length < 5) return;
      this.$emit("input", "");
      this.$toast.error("Unsupported image format.", { position: "top" });
    },
    showCamera() {
      if (!this.isMobile) {
        this.$bvModal.show(`capture_modal_${this.id}`);
      } else {
        this.$refs.capture_camera.click();
      }
    },
    enableWebcamError() {
      this.$toast.error(this.$t(`check-in-form.error-camera-notfound`));
    },
    webcamCapture(e) {
      const data = this.$refs[`webcam_screen_${this.id}`].capture();
      this.imageData = data;
      this.$emit("input", data);
      this.webcamStop();
      this.$bvModal.hide(`capture_modal_${this.id}`);
    },
    webcamStart() {
      this.showWebcam = true;
    },
    webcamStop() {
      this.$refs[`webcam_screen_${this.id}`].stop();
      this.showWebcam = false;
    },
  },
};
</script>
