import http, { httpmock } from "@/utils/http";

const endpoint = "log/get/facility-visitor-other-form";

function normalizeMain(obj) {
  const {
    input_type,
    is_core,
    label,
    language,
    rank,
    section,
    system_name,
  } = obj.facility_questionnaire[0];
  return {
    question: {
      input_type,
      is_core,
      label,
      language,
      rank,
      section,
      system_name,
    },
    answer: obj.value,
    id: obj.id,
  };
}

function normalizeCompanion(obj) {
  const {
    input_type,
    is_core,
    label,
    language,
    rank,
    section,
    system_name,
  } = obj.facility_questionnaire;
  return {
    question: {
      input_type,
      is_core,
      label,
      language,
      rank,
      section,
      system_name,
    },
    answer: obj.value,
    id: obj.id,
  };
}

export default {
  async getOne(query, no_loader = false) {
    const config = no_loader ? { headers: { "no-loader": true } } : {};
    const params = {
      paginate: false,
      facility_visitor_log_id: query.log_id,
      facility_id: query.facility_id,
    };
    const meta = {
      basic: [],
      other: [],
      signature_image: null,
      passport_image: null,
      log_id: null,
      companions: [],
    };
    const details = { ...meta };
    const companions = [];
    const response = await http.post(endpoint, params, config);
    const { data } = response.data;
    const { qrCodeData } = data;
    const {
      booking_status_id,
      checkin_date,
      checkin_time,
      checkout_date,
      checkout_time,
      guest_contact_no,
      pms_reservation_no,
      pms_code,
      room_number,
      qr_file_path,
    } = data.booking;

    // Process main guest data
    data.visitor_logs.forEach((log) => {
      const item = normalizeMain(log);
      if (item.question.system_name == "gov_id") {
        details.passport_image = item.answer;
        details.log_id = item.id;
      } else if (item.question.system_name == "signature") {
        details.signature_image = item.answer;
      } else if (["basic_info"].includes(item.question.section)) {
        details.basic.push(item);
      } else {
        details.other.push(item);
      }
    });
    // Process visitor data
    data.companion.forEach((companion) => {
      const companionsDetails = { basic: [], other: [] };
      companionsDetails.booking = {
        booking_status_id,
        checkin_date,
        checkin_time,
        checkout_date,
        checkout_time,
        guest_contact_no,
        pms_reservation_no,
        pms_code,
        room_number,
      };
      companion.facility_companion_other_form_log.forEach((log) => {
        const item = normalizeCompanion(log);
        if (item.question.system_name == "gov_id") {
          companionsDetails.passport_image = item.answer;
          companionsDetails.log_id = item.id;
        } else if (item.question.system_name == "signature") {
          companionsDetails.signature_image = item.answer;
        } else if (["basic_info"].includes(item.question.section)) {
          companionsDetails.basic.push(item);
        } else {
          companionsDetails.other.push(item);
        }
      });
      companions.push(companionsDetails);
    });

    details.booking = {
      booking_status_id,
      checkin_date,
      checkin_time,
      checkout_date,
      checkout_time,
      guest_contact_no,
      pms_reservation_no,
      pms_code,
      room_number,
      qr_file_path,
    };
    details.companions = companions;
    details.qrCodeData = qrCodeData;
    details.booking.qr_file_path = qr_file_path;
    return details;
  },
};
