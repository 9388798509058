var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{ errored: _vm.error.length > 0 },attrs:{"id":_vm.id}},[_c('label',{staticClass:"flex-center-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"badge text-white custom-required-mark"},[_vm._v(_vm._s(_vm.$t('common.word-required')))]):_vm._e()]),_c('div',{staticClass:"d-flex w-100"},[_c('b-form-select',{staticClass:"mr-1",class:{
        'border-primary': _vm.error.length < 1 && _vm.dateValue.year == null,
        'border-danger' : _vm.error.length > 0 && _vm.dateValue.year == null,
      },attrs:{"required":_vm.required},on:{"change":_vm.update},model:{value:(_vm.dateValue.year),callback:function ($$v) {_vm.$set(_vm.dateValue, "year", $$v)},expression:"dateValue.year"}},_vm._l((_vm.dateOptions.year),function(year,yindex){return _c('b-form-select-option',{key:`y_${yindex}`,attrs:{"value":isNaN(year) ? null : year}},[_vm._v(" "+_vm._s(year)+" ")])}),1),_c('b-form-select',{staticClass:"mr-1",class:{
        'border-primary': _vm.error.length < 1 && _vm.dateValue.month == null,
        'border-danger' : _vm.error.length > 0 && _vm.dateValue.month == null,
      },attrs:{"required":_vm.required},on:{"change":_vm.update},model:{value:(_vm.dateValue.month),callback:function ($$v) {_vm.$set(_vm.dateValue, "month", $$v)},expression:"dateValue.month"}},_vm._l((_vm.dateOptions.month),function(month,mindex){return _c('b-form-select-option',{key:`m_${mindex}`,attrs:{"value":isNaN(month) ? null : month}},[_vm._v(" "+_vm._s(month)+" ")])}),1),_c('b-form-select',{staticClass:"mr-1",class:{
        'border-primary': _vm.error.length < 1 && _vm.dateValue.day == null,
        'border-danger' : _vm.error.length > 0 && _vm.dateValue.day == null,
      },attrs:{"required":_vm.required},on:{"change":_vm.update},model:{value:(_vm.dateValue.day),callback:function ($$v) {_vm.$set(_vm.dateValue, "day", $$v)},expression:"dateValue.day"}},_vm._l((_vm.dateOptions.day),function(day,dindex){return _c('b-form-select-option',{key:`d_${dindex}`,attrs:{"value":isNaN(day) ? null : day}},[_vm._v(" "+_vm._s(day)+" ")])}),1),(_vm.copyButton)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary btn-round text-white ml-1",attrs:{"type":"button","title":_vm.$t('check-in-form.tip-copy-from-main')},on:{"click":() => _vm.$emit('copy')}},[_c('i',{staticClass:"far fa-copy"})]):_vm._e()],1),(!_vm.noErrorMessage && _vm.error.length > 0)?_c('small',{staticClass:"form-message text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }