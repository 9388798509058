<template>
  <div id="dashboard_wrapper" class="dashboard-theme" v-on:click="resetIdleTime($event)">
    <!-- Page Wrapper -->
    <div id="wrapper" v-if="show">
      <!-- Sidebar -->
      <side-nav style="position: fixed; top: 0; left: 0; z-index: 5" />
      <side-nav />

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
          <!-- Topbar -->
          <top-nav />
          <!-- End of Topbar -->

          <!-- Begin Page Content -->
          <div class="container-fluid">
            <router-view />
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright &copy; {{ url }}</span>
            </div>
          </div>
        </footer>
        <!-- End of Footer -->
      </div>
      <!-- End of Content Wrapper -->
    </div>
    <div class="container" v-if="!show">
      <router-view />
    </div>
    <confirm-box />
  </div>
</template>

<style lang="scss">
.dashboard-theme {
  @import "./assets/scss/sb-admin-2.scss";
  .nav-tabs .nav-link.active {
    cursor: default !important;
    pointer-events: none;
  }
  .nav-tabs {
    .nav-link {
      &.active {
        color: darken($primary, 15%);
        border-top-color: lighten($primary, 35%);
        border-bottom: 1px solid white !important;
        background: linear-gradient(to bottom, lighten($primary, 40%), white, transparent, transparent);
        text-shadow: 0px 0px 5px white;
      }
      &:hover:not(.active) {
        color: white;
        border-bottom-color: transparent !important;
        background: $primary;
      }
      background: lighten($primary, 20%);
      color: white;
      border-top-color:white;
      border-left-color:white;
      border-right-color:white;
    }
  }
  .drag-handle {
    color: $secondary;
    margin-right: 5px;
    cursor: move;
    &:hover {
      color: $primary;
    }
  }
  .drag-ghost {
    background-color: $gray-100;
    &> * {
      opacity: 0;
    }
  }
  .editor-container,
  .tab-container {
    border-top-width: 0 !important;
  }
  .btn-block {
    max-width: 200px;
  }
  label.vue-js-switch {
    margin-bottom: 0;
  }
  /* Styles for adjusting the layout of label text inside toggle buttons */
.vue-js-switch .v-switch-label.v-left{
  left: 4px !important;
  width: calc(100% - 24px);
  text-align: center;
}
.vue-js-switch .v-switch-label.v-right{
  right: 4px !important;
  width: calc(100% - 24px);
  text-align: center;
}
  .sidebar-dark {
    .sidebar-heading {
      color: rgba(255, 255, 255, 1);
    }
    .nav-item .nav-link i {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
  // Quill editor customization.
  .ql-snow {
    .ql-tooltip {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      &:before {
        content: "URL";
      }
      .ql-action, .ql-remove {
        font-size: 18px;
        vertical-align: middle;
        color: $primary;
      }
      .ql-action:after {
        content: "\f044";
      }
      .ql-remove:before {
        content: "\f2ed";
      }
      &[data-mode=link]:before {
        content: "URL:";
      }
    }
    .ql-tooltip.ql-editing {
      .ql-action::after {
        content: "\f0c7";
      }
    }
  } 
}
</style>

<script>
import { init } from "./assets/js/sb-admin";
import sideNav from "./components/sideNav";
import topNav from "./components/topNav";
import facilitiesApi from "@/api/facilities";
import authApi from "@/api/auth";
import settingsApi from "@/api/settings";
import ConfirmBox from "@/components/ConfirmBox";

import { mapGetters } from "vuex";

import questionsApi from "@/api/questions";

export default {
  beforeRouteEnter: async (to, from, next) => {
    if (!$storage.has("auth")) return next({ path: "/login" });
    const auth = $storage.get("auth");
    const noUser = typeof auth.user === "undefined";
    if (noUser) return next({ name: "authlogin" });
    $store.dispatch("dashboard/updateUserData", auth.user);
    try {
      const response = await facilitiesApi.get({ with: "user" });
      $store.dispatch("dashboard/updateFacilities", response.data);
      next();
    } catch (e) {
      console.error(e.message);
      $storage.clear();
      return next({ path: "/login" });
    }
  },
  beforeMount() {
    this.$i18n.locale = navigator.language.substr(0, 2);
    this.resetIdleTime(null);
  },
  components: { sideNav, topNav, ConfirmBox },
  computed: { ...mapGetters("dashboard", ["facility"]) },
  data() {
    return {
      url: window.location.origin,
      show: true,
      idle_time: 0,
    };
  },
  watch: {
    idle_time: function(value) {
    if (value >= this.auto_logout_time) {
      this.idle_time = 0;
      authApi.logout({ user_id: this.facility.user.id}, null, true);
      }
    }
  },
  methods: {
    async resetIdleTime(e){
      if(e && e.target.dataset.target == '#logoutModal') return false;
      this.idle_time = 0;
      const response = await authApi.resetIdleTime({ user_id: this.facility.user.id}, null, true);
      this.auto_logout_time = Number(response.data.logout_time);
    }
  },
  async mounted() {
    this.auto_logout_time = 9999;
    this.show = this.$route.meta.show == undefined ? true : this.$route.meta.show;
    init();
    const languages = await settingsApi.getLanguages(this.facility.id);
    $store.dispatch("updateLanguages", languages.data[0].value);
    setInterval(() => { this.idle_time++ }, 1000)
    await questionsApi.init();
  }
};
</script>