import Vue from 'vue'
import Router from '@/router'
import Store from '@/store'
import i18n from './i18n'

// import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { BootstrapVue } from 'bootstrap-vue'
import { Vue2Storage } from 'vue2-storage'
import VueToast from 'vue-toast-notification'
import vueDebounce from 'vue-debounce'

import ImageUploader from 'vue-image-upload-resize'
import ToggleButton from 'vue-js-toggle-button'
import VueTelInput from 'vue-tel-input'
import Loading from 'vue-loading-overlay'

import Http from '@/utils/http'
import Query from 'query-string'
import VueSignaturePad from 'vue-signature-pad'
import { languageMaps } from "@/utils/mockups/language-options"

Vue.config.productionTip = false

if(process.env.NODE_ENV == 'development'){
  Vue.config.debug = true
  Vue.config.devtools = true
  Vue.config.errorHandler = function(error, vm, info) {
    const message = error.toString();
    console.error('【vue.config.errorHandler_message】', message);
    console.warn('【vue.config.errorHandler_vm】', vm);
    console.warn('【vue.config.errorHandler_info】', info);
  }
}

// Register add-ons and plugins
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_GA_ID }
// }, Router)
Vue.use(BootstrapVue)
Vue.use(vueDebounce)
Vue.use(Vue2Storage)
Vue.use(VueToast)
Vue.use(ImageUploader)
Vue.use(ToggleButton)
Vue.use(VueTelInput)
Vue.use(VueSignaturePad)
// Vue.mixin(Catcher)
Vue.component('loading', Loading)
Vue.prototype.$http = Http
Vue.prototype.$query = Query
window.$http = Http
window.$storage = Vue.$storage
window.$i18n = i18n
window.$languages = languageMaps.universal;
Vue.prototype.$languages = languageMaps.universal;
Vue.prototype.$theme = {
  primary: '#5468a1',
  success: '#3099a7',
  purple: '#452481',
  red:'#EA4025'
}

// Import Styles
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'vue-prism-editor/dist/prismeditor.min.css'
import '@/assets/scss/custom.scss'
import '@/assets/scss/_styled-form.scss'

// Init vue instance
import App from './App.vue'
new Vue({
  // eslint-disable-line no-new
  el: '#app',
  template: '<App/>',
  router: Router,
  store: Store,
  i18n,
  render: h => h(App)
})
