var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"id":_vm.id}},[(_vm.label)?_c('label',{staticClass:"flex-center-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"badge text-white custom-required-mark"},[_vm._v(" "+_vm._s(_vm.$t('common.word-required')))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"phone-field-wrapper"},[_c('vue-tel-input',{staticClass:"form-control",class:{
        'border-primary': _vm.error.length < 1,
        'border-danger': _vm.error.length > 0,
        errored: _vm.error.length > 0,
      },attrs:{"validCharactersOnly":"","placeholder":_vm.placeholder,"autoFormat":false,"value":_vm.value},on:{"input":(num, obj) => {
          _vm.$emit('input', num, obj);
        },"validate":(v) => {
          _vm.$emit('validate', v);
        },"country-changed":(v) => {
          _vm.$emit('validate', v);
        }},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('i',{staticClass:"fas fa-caret-down"})]},proxy:true}])}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.value && _vm.value.length > 0 && _vm.error.length < 1),expression:"value && value.length > 0 && error.length < 1"}],staticClass:"form-icon form-icon-clear",attrs:{"type":"button","title":"clear"},on:{"click":(v) => _vm.$emit('input', '', _vm.defaultObject)}},[_c('i',{staticClass:"fas fa-times"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.error.length > 0),expression:"error.length > 0"}],staticClass:"form-icon form-icon-exclamation",attrs:{"type":"button","title":"clear"},on:{"click":(v) => _vm.$emit('input', '', _vm.defaultObject)}},[_c('i',{staticClass:"fas fa-exclamation-circle"})])],1),(!_vm.noErrorMessage & (_vm.error.length > 0))?_c('small',{staticClass:"form-message text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }