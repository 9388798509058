<template>
  <auth-layout>
    <template v-slot:header>
      <div class="text-center">
        <i class="fas fa-lock header-icon" />
      </div>
    </template>
    <h4 class="text-center">{{ $t(`auth.label-login`) }}</h4>
    <form @submit.prevent="submit">
      <div>
        <text-field
          :label="$t(`auth.label-username`)"
          v-model="data.username"
          placeholder="e.g. User01"
          required
          @input="error_msg = ''"
          :error="error_msg"
        />
      </div>
      <div>
        <text-field
          type="password"
          :label="$t(`auth.label-password`)"
          v-model="data.password"
          placeholder="e.g. Password100%"
          required
          @input="error_msg = ''"
          :error="error_msg"
        />
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-min">
          {{ $t(`auth.label-login`) }}
        </button>
      </div>
    </form>
    <template v-slot:footer>
      <div class="d-flex justify-content-around px-3 mt-n1">
        <router-link class="small" to="password-reset">{{
          $t(`auth.link-reset`)
        }}</router-link>
      </div>
    </template>
  </auth-layout>
</template>

<script>
import authApi from "@/api/auth";
import AuthLayout from "@/layouts/AuthLayout";
import TextField from "@/components/form-fields/TextField";

export default {
  components: { AuthLayout, TextField },
  data() {
    return {
      error_msg: "",
      data: {
        username: null,
        password: null
      }
    };
  },
  methods: {
    async submit() {
      const failedMessage = this.$t("auth.error-failed");
      try {
        const login = await authApi.login(this.data);
        if (!login.status.success) {
          this.error_msg = "  ";
          this.$toast.error(failedMessage, { position: "top" });
          return;
        }
        this.$storage.set("auth", login);
        this.$router.push({ name: "dashboard-home" });
      } catch ({ response }) {
        const status = response.status;
        this.$toast.error(this.$t("common.error-generic"), { position: "top" });
      }
    }
  }
};
</script>
