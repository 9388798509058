import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters('dashboard', ['permissions'])
  },
  methods: {
    hasAccess (accessKey) {
      const paths = accessKey.split('.');
      if (paths.length < 3) return false;
      const [category, name, control] = paths;
      return this.permissions.some((permission) => (
        permission.category == category &&
        permission.name == name &&
        permission.control == control
      ));
    }
  }
}