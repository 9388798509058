<template>
  <div>
    <!-- ローカル環境での宿泊約款表示のため、上記<div>タグ内の　v-if="hasAccess('tac.terms_information.edit')"　を削除しております -->
    <h1 class="h3 mb-2 text-gray-800">{{ $t("dashboard-terms.title") }}</h1>
    <p>{{ $t("dashboard-terms.description") }}</p>
    <form class="p-4m" @submit.prevent="saveText">
      <div>
        <b-tabs>
          <b-tab
            v-for="(lang, index) in languages"
            :key="`lang_${index}`"
            :title="$languages[lang.code]"
            :active="lang.code == langCode"
            @click="() => showText(lang.code)"
          ></b-tab>
        </b-tabs>
      </div>
      <div class="px-3 pt-3 pb-3 mb-3 border rounded-bottom editor-container">
        <!-- <vue-editor

          v-model="data.value.content"
          :editor-toolbar="customToolbar"
        /> -->
        <quill-editor
          v-model="data.value.content"
        />
      </div>

      <div class="text-right" >
        <button type="submit" class="btn btn-warning btn-wide">
          <i class="fas fa-save mr-1"></i>
          {{ $t("dashboard-common.word-save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.ql-editor ol li {
  color: initial !important;
  font: initial !important;
  font-family: Helvetica, Arial, sans-serif !important;
  margin-bottom: initial !important;
}
</style>

<script>
// import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters, mapState } from "vuex";
import uac from "@/mixins/uac";
// import termsApi from "@/api/terms-conditions";
import settingsApi from "@/api/settings";
import QuillEditor from '@/components/QuillEditor'

export default {
  components: {
    // VueEditor
    QuillEditor
  },
  computed: {
    ...mapState(["languages"]),
    ...mapGetters("dashboard", ["facility"])
  },
  mixins: [uac],
  beforeMount() {
    this.data.facility_id = this.facility.id;
    this.data.name = `terms-conditions-${this.$i18n.locale}`;
  },
  async mounted() {
    this.updateActiveMenu("terms");
    this.showText(this.$i18n.locale);
  },
  data() {
    return {
      data: {
        facility_id: null,
        name: null,
        value: {
          content: ""
        }
      },
      langCode: "",
      customToolbar: [
        [
          { font: [] },
          { size: ["small", false, "large", "huge"] },
          { color: [] },
          { background: [] },
          { align: [] }
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "clean"] // remove formatting button
      ]
    };
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    async showText(lang) {
      try {
        this.langCode = lang;
        const response = await settingsApi.getTerms(this.facility.id, lang);
        if (response.total > 0) {
          const data = { ...this.data, ...response.data[0] };
          this.data = data;
        } else {
          this.$set(this.data, "value", { content: "" });
        }
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async saveText() {
      try {
        this.data.name = `terms-conditions-${this.langCode}`;
        const response = await settingsApi.save(this.data);
        this.$toast.success(this.$t("dashboard-common.saved-generic"), {
          position: "top"
        });
        window.scrollTo(0, 0);
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    }
  }
};
</script>