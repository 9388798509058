export default {
  en: {
    yes: "Yes",
    no: "No"
  },
  ja: {
    yes: "はい",
    no: "いいえ"
  },
  ko: {
    yes: "예",
    no: "아니"
  },
  'zh-CN': {
    yes: "是的",
    no: "不"
  },
  'zh-TW': {
    yes: "是的",
    no: "不"
  }
}