<template>
  <!-- Step-5: Provide signature -->
  <div>
    <div class="nav-buttons">
      <circle-nav-button direction="prev" @click="goBack" />
    </div>
    <div class="d-flex justify-content-center py-3">
      <checkin-stepper step="input" />
    </div>
    <header class="checkin-form-header font-weight-bold bg-white sticky-top">
      <div class="d-flex justify-content-between">
        <span>{{ $t('check-in-form.title-signature') }}</span>
        <span></span>
      </div>
    </header>
    <div>
      <p>{{ $t('check-in-form.instruction-signature') }}</p>
    </div>
    <div class="d-flex justify-content-center mb-3 mt-n1">
      <a href="" @click.prevent="$emit('show-terms')">
        <small>{{ $t('check-in-form.terms-conditions') }}</small>
      </a>
    </div>
    <div class="text-center">
      <div class="aspect-ratio ratio-5x3 mb-3">
        <div
          class="signature-wrapper border border-primary mb-3"
          :class="{ editing }"
        >
          <vue-signature-pad :options="signatureOptions" ref="signaturePad" />
          <div class="control-buttons">
            <template v-if="editing">
              <button class="btn" @click="undoSignature" title="undo">
                <i class="fas fa-undo"></i>
              </button>
              <button class="btn" @click="clearSignature" title="clear">
                <i class="fas fa-trash-alt"></i>
              </button>
              <button class="btn" @click="saveSignature" title="save">
                <i class="fas fa-save"></i>
              </button>
            </template>
            <button
              v-show="!editing"
              class="btn"
              @click="editing = true"
              title="edit"
            >
              <i class="fas fa-edit"></i>
            </button>
          </div>
        </div>
      </div>
      <b-alert
        variant="danger"
        :show="signatureError.length > 0"
        dismissible
        class="text-center"
        @dismissed="signatureError = ''"
      >
        {{ signatureError }}
      </b-alert>
    </div>

    <div class="text-center">
      <button type="button" class="btn btn-primary btn-min" @click="goNext">
        {{ $t('check-in-form.word-next') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
.signature-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-style: dashed !important;
  .control-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }
  &.editing {
    border-style: solid !important;
    background-color: $gray-100 !important;
  }
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import CheckinStepper from '@/components/CheckinStepper';
import CircleNavButton from '@/components/CircleNavButton';

export default {
  beforeMount() {
    if (!this.facility.id) this.$router.replace({ path: '/error-404' });
    if (!this.enabledSignature)
      return this.$router.replace({ name: 'checkin-step-6' });
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        if (this.signatureImageData.length > 0) {
          this.$refs.signaturePad.fromDataURL(this.signatureImageData);
          this.editing = false;
        }
      });
    });
  },
  components: { CheckinStepper, CircleNavButton },
  computed: {
    ...mapState('checkin', [
      'facility',
      'enabledFaceCapture',
      'requiredFaceCapture',
      'enabledSignature',
      'requiredSignature',
      'signatureImageData',
    ]),
    hasData() {
      return this.signatureImageData.length > 0;
    },
  },
  data() {
    return {
      signatureError: '',
      editing: true,
      signatureOptions: {
        backgroundColor: '#ffffff',
      },
    };
  },
  methods: {
    ...mapActions('checkin', ['updateSignatureImageData']),
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.signatureError = '';
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature(
        'image/jpeg'
      );
      if (isEmpty && this.requiredSignature) {
        this.signatureError = this.$t('check-in-form.error-field-required');
        this.$toast.error(this.$t('check-in-form.error-form-check'), {
          position: 'top',
        });
        return false;
      }
      const imageData = isEmpty ? '' : data;
      this.updateSignatureImageData(imageData);
      this.editing = false;
      return true;
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    goBack() {
      if (this.enabledFaceCapture)
        this.$router.replace({ name: 'checkin-step-4' });
      else this.$router.replace({ name: 'checkin-step-3' });
    },
    goNext() {
      if (this.saveSignature())
        this.$router.replace({ name: 'checkin-step-6' });
    },
  },
  watch: {
    editing(yes) {
      if (yes) this.$refs.signaturePad.openSignaturePad();
      else this.$refs.signaturePad.lockSignaturePad();
    },
  },
};
</script>
