import { render, staticRenderFns } from "./PhotoField.vue?vue&type=template&id=928c3fbc&scoped=true"
import script from "./PhotoField.vue?vue&type=script&lang=js"
export * from "./PhotoField.vue?vue&type=script&lang=js"
import style0 from "./PhotoField.vue?vue&type=style&index=0&id=928c3fbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "928c3fbc",
  null
  
)

export default component.exports