<template>
  <div>
    <h1 class="h3 mb-2 text-gray-800">{{ $t("dashboard-settings.title") }}</h1>
    <p></p>
    <ul class="nav nav-tabs">
      <li class="nav-item" v-if="hasAccess('setting.basic_information.view')">
        <a
          :class="`nav-link ${activNav === 'profile' ? 'active' : ''}`"
          @click.prevent="activNav = 'profile'"
          >{{ $t("dashboard-settings.tab-profile") }}</a
        >
      </li>
      <li
        class="nav-item"
        v-if="hasAccess('setting.facility_information.view')"
      >
        <a
          :class="`nav-link ${activNav === 'names' ? 'active' : ''}`"
          @click.prevent="activNav = 'names'"
          >{{ $t("dashboard-settings.tab-names") }}</a
        >
      </li>
      <li class="nav-item" v-if="hasAccess('setting.language_setting.view')">
        <a
          :class="`nav-link ${activNav === 'languages' ? 'active' : ''}`"
          @click.prevent="activNav = 'languages'"
          >{{ $t("dashboard-settings.tab-languages") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="`nav-link ${activNav === 'face' ? 'active' : ''}`"
          @click.prevent="activNav = 'face'"
          >{{ $t("dashboard-settings.tab-face-recognition") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="`nav-link ${activNav === 'links' ? 'active' : ''}`"
          @click.prevent="activNav = 'links'"
          >{{ $t("dashboard-settings.tab-links") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="`nav-link ${activNav === 'maintenance' ? 'active' : ''}`"
          @click.prevent="activNav = 'maintenance'"
          >{{ $t("dashboard-settings.tab-maintenance") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="`nav-link ${activNav === 'login' ? 'active' : ''}`"
          @click.prevent="activNav = 'login'"
          >{{ $t("dashboard-settings.tab-login") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="`nav-link ${activNav === 'sms' ? 'active' : ''}`"
          @click.prevent="activNav = 'sms'"
          >{{ $t("dashboard-settings.tab-sms") }}</a
        >
      </li>
    </ul>

    <div>
      <div
        v-if="
          activNav === 'profile' && hasAccess('setting.basic_information.view')
        "
      >
        <profile-setting />
      </div>
      <div
        v-if="
          activNav === 'names' && hasAccess('setting.facility_information.view')
        "
      >
        <name-setting />
      </div>
      <div
        v-if="
          activNav === 'languages' && hasAccess('setting.language_setting.view')
        "
      >
        <language-setting />
      </div>
      <div v-if="activNav === 'face'">
        <face-setting />
      </div>
      <div v-if="activNav === 'links'">
        <link-setting />
      </div>
      <div v-if="activNav === 'maintenance'">
        <maintenance-setting />
      </div>
      <div v-if="activNav === 'login'">
        <login-setting />
      </div>
      <div v-if="activNav === 'sms'">
        <sms-setting />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-link {
  cursor: pointer;
}
.nav-link.active {
  cursor: default !important;
}
</style>
.
<script>
import ProfileSetting from "./ProfileSetting";
import NameSetting from "./NameSetting";
import LanguageSetting from "./LanguageSetting";
import FaceSetting from "./FaceSetting";
import LinkSetting from "./LinkSetting";
import MaintenanceSetting from "./MaintenanceSetting";
import LoginSetting from "./LoginSetting";
import SmsSetting from "./SmsSetting";

import uac from "@/mixins/uac";

import { mapActions } from "vuex";

export default {
  components: {
    ProfileSetting,
    NameSetting,
    LanguageSetting,
    FaceSetting,
    LinkSetting,
    MaintenanceSetting,
    LoginSetting,
    SmsSetting,
  },
  mounted() {
    this.updateActiveMenu("settings");
  },
  computed: {},
  mixins: [uac],
  data() {
    return {
      activNav: "profile"
    };
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"])
  }
};
</script>
