import Index from '@/pages/auth/index'
import AuthLogin from '@/pages/auth/login/login'
import AuthReset from '@/pages/auth/password-reset/password-reset'
import AuthNewPassword from '@/pages/auth/password-reset/password-new'
import AuthRegister from '@/pages/auth/register/register'

export default [
  {
    path: `/auth`,
    alias: '/',
    component: Index,
    children: [
      { path: 'login', component: AuthLogin, name: 'auth-login' },
      { path: 'password-reset', component: AuthReset, name: 'auth-reset' },
      { path: 'new-password', component: AuthNewPassword, name: 'auth-new-password' },
      { path: 'register', component: AuthRegister, name: 'register' },
    ]
  },
]