<template>
  <!-- Step 1: Input phone number -->
  <div>
    <b-alert
      v-if="tooManyRequest"
      variant="default"
      show
      class="border border-primary bg-light justify-content-center"
    >
      {{ $t("check-in-form.error-system-locked-new-first") }}
      <template v-if="lockHours != 0">
        {{ $t("check-in-form.error-system-locked-new-hours").replace('X', lockHours) }}
        {{ $t("check-in-form.error-system-locked-new-middle") }}
      </template>
      {{ $t("check-in-form.error-system-locked-new-minutes").replace('Y', lockMinutes) }}
      {{ $t("check-in-form.error-system-locked-new-last") }}
    </b-alert>
    <template v-if="checkedConfirmLock != false && !tooManyRequest">
      <b-alert
        variant="default"
        show
        class="border border-primary bg-light justify-content-center"
      >
        {{ $t("check-in-form.instruction-phone") }}
      </b-alert>
      <form @submit.prevent="goNext" class="was-validated">
        <phone-field
          :label="$t('check-in-form.word-phone')"
          :value="phoneNumber"
          @input="updatePhoneNumber"
          :error="phoneError"
          required
        />
        <div class="d-flex justify-content-center mb-2">
          <button type="submit" class="btn btn-primary">
            {{ $t("check-in-form.terms-accept") }}
          </button>
        </div>
        <div>
          <p
          v-if="errorCode != ''"
          class="form-message m-4 text-center text-danger pre-wrap"
          >{{ 
            $t("check-in-form.error-confirm-booking") + `(Error: ${errorCode})`
            +
            "\n" + this.$t("check-in-form.error-confirm-booking-number-of-attempts").replace('XXX', lockCountDown)
          }}
          </p>
        </div>
      </form>
    </template>
    <div class="d-flex justify-content-center mb-2">
      <a :href="`/terms-and-condition?lang=${$i18n.locale}`" target="_blank">
        <small>{{ $t("check-in-form.terms-link-text") }}</small>
      </a>
    </div>
  </div>
</template>

<script>
import bookingsAPI from "@/api/bookings";
import PhoneField from "@/components/form-fields/PhoneField";
import { mapActions, mapState } from "vuex";

export default {
  async mounted() {
    // Ensure previous step got processed.
    if (!this.facility.id) this.$router.replace({ path: "/error-404" });
    await this.init();
  },
  components: { PhoneField },
  computed: {
    ...mapState("checkin", [
      "countryCode", "phoneNumber", "facility", "token"
    ])
  },
  data() {
    return {
      phoneError: "",
      termsAndConditions: "",
      tooManyRequest: false,
      lockHours: 0,
      lockMinutes: 0,
      lockCountDown: null,
      checkedConfirmLock: false,
      errorCode: "",
    };
  },
  methods: {
    async init() {
      $store.commit("checkin/set_loading", true);

      try {
        await bookingsAPI.checkConfirmLock();
        this.checkedConfirmLock = true;
      } catch (e) {
        console.log(e);
        const status = e.response.status;
        if (status === 429) {
          this.tooManyRequest = true;
          this.lockHours = e.response.data.data.hours;
          this.lockMinutes = e.response.data.data.minutes;
          $store.commit("checkin/set_loading", false);
          console.log('loading done');
          return;
        }
      }      

      $store.commit("checkin/set_loading", false);
      console.log('loading done');
    },
    updatePhoneNumber(num, obj) {
      const { country, number } = obj;
      if (!country || !number) return;
      this.updatePhone({ code: country.dialCode, number: number.significant });
      this.phoneError = "";
    },
    async goNext() {
      // Ensure valid phone number length
      if (this.phoneNumber.length < 5) {
        this.phoneError = this.$t("check-in-form.error-field-required");
        return;
      }
      // Fetch booking record
      this.errorCode = '';
      this.lockCountDown = '';
      try {
        var response = await bookingsAPI.checkPhone(
          {
            code: this.countryCode,
            number: this.phoneNumber
          },
          {
            token: this.token,
            facility_id: this.facility.id
          }
        );
      } catch (e) {
        const status = e.response.status;
        this.errorCode = e.response.data.data.errorCode;
        this.lockCountDown = e.response.data.data.lockCountDown;
        if (status === 422) {
          this.phoneError = this.$t("check-in-form.error-phone-notfound");
          return;
        }
        if (status === 429) {
          this.tooManyRequest = true;
          this.lockHours = e.response.data.data.hours;
          this.lockMinutes = e.response.data.data.minutes;
          return;
        }
        this.$toast.error(this.$t("check-in-form.error-generic"), {
          position: "top"
        });
        return;
      }
      // Validate that record exist
      const data = response.data;
      if (data.length < 1) {
        this.phoneError = this.$t("check-in-form.error-phone-notfound");
        return;
      }
      // Save booking record & proceed to next step.
      this.updateBookingData(data[0]);
      this.$router.replace({ name: "checkin-step-2" });
    },
    ...mapActions("checkin", ["updateBookingData", "updatePhone"])
  }
};
</script>