<template>
  <!-- Step 1: Input phone number -->
  <div>
    <b-alert
      v-if="tooManyRequest"
      variant="default"
      show
      class="border border-primary bg-light justify-content-center"
    >
      {{ $t("check-in-form.error-system-locked-new-first") }}
      <template v-if="lockHours != 0">
        {{ $t("check-in-form.error-system-locked-new-hours").replace('X', lockHours) }}
        {{ $t("check-in-form.error-system-locked-new-middle") }}
      </template>
      {{ $t("check-in-form.error-system-locked-new-minutes").replace('Y', lockMinutes) }}
      {{ $t("check-in-form.error-system-locked-new-last") }}
    </b-alert>
    <template v-if="checkedConfirmLock != false && !tooManyRequest">
      <b-alert
        variant="default"
        show
        class="border border-primary bg-light justify-content-center"
      >
      <p class="pre-wrap">
        {{ $t("check-in-form.instruction-ex-url-label") }}
        <br />
        {{ $t("check-in-form.instruction-ex-url-first") }}
      </p>
      <!-- 認証方式に応じて案内文を変更 ここから -->
      <template v-if="authentication_method == 1">
        <span>
          {{ $t("check-in-form.instruction-ex-url-phone-last-4-digits") }}
        </span>
      </template>
      <template v-else-if="authentication_method == 2">
        <span>
          {{ $t("check-in-form.instruction-ex-url-reservation-number") }}
        </span>
      </template>
      <template v-else-if="authentication_method == 3">
        <span>
          {{ $t("check-in-form.instruction-ex-url-phone") }}
        </span>
      </template>
      <!-- 認証方式に応じて案内文を変更 ここまで -->
      </b-alert>
      <form @submit.prevent="goNext" class="was-validated">
        <template v-if="authentication_method == 1 || authentication_method == 2">
          <div class="form-group">
            <!-- 認証方式に応じてlabelの文言を変更 ここから -->
            <template v-if="authentication_method == 1">
              <label>
                {{ $t("check-in-form.instruction-phone-last-4-digits") }}
              </label>
            </template>
            <template v-else-if="authentication_method == 2">
              <label>
                {{ $t("check-in-form.instruction-reservation-number") }}
              </label>
            </template>
            <!-- 認証方式に応じてlabelの文言を変更 ここまで -->
            <span class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
            <input
              class="form-control border-primary background-image-none"
              :type="'text'"
              :maxLength="authentication_method == 1 ? 4 : -1"
              v-model="otherNumber"
              :error="otherNumberError"
              required
            />
          </div>
        </template>
        <template v-else-if="authentication_method == 3">
          <phone-field
            :label="$t('check-in-form.word-phone')"
            :value="phoneNumber"
            @input="updatePhoneNumber"
            :error="phoneError"
            required
          />
        </template>
        <date-field
          v-if="authentication_method == 1 || authentication_method == 2 || authentication_method == 3"
          :label="$t('check-in-form.check-in-date')"
          v-model="checkInDate"
          :error="checkInError"
          :min="minDate"
          :requiredIcon="false"
          required
        />
        <div class="d-flex justify-content-center mb-2">
          <button type="submit" class="btn btn-primary">
            {{ $t("check-in-form.terms-accept") }}
          </button>
        </div>
        <div>
          <p
          v-if="errorCode != ''"
          class="form-message m-4 text-center text-danger pre-wrap"
          >{{
            $t("check-in-form.error-confirm-booking") + `(Error: ${errorCode})`
            +
            "\n" + this.$t("check-in-form.error-confirm-booking-number-of-attempts").replace('XXX', lockCountDown)
          }}
          </p>
        </div>
      </form>
    </template>
    <div class="d-flex justify-content-center mb-2">
      <a :href="`/terms-and-condition?lang=${$i18n.locale}`" target="_blank">
        <small>{{ $t("check-in-form.terms-link-text") }}</small>
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import facilitiesApi from "@/api/facilities";
import bookingsAPI from "@/api/bookings";
import TextField from "@/components/form-fields/TextField";
import PhoneField from "@/components/form-fields/PhoneField";
import DateField from "@/components/form-fields/DateField";
import { mapActions, mapState } from "vuex";

export default {
  async mounted() {
    await this.init();
  },
  components: { TextField, PhoneField, DateField },
  computed: {
    ...mapState("checkin", [
      "countryCode", "phoneNumber", "facility", "token", "maxRetryReached"
    ])
  },
  data() {
    return {
      facilityData: null,
      instructionSecondChunk: "",
      otherNumber: "",
      otherNumberLabel: "",
      checkInDate: null,
      minDate: moment.tz("Asia/Tokyo").format("YYYY-MM-DD"),
      phoneError: "",
      checkInError: "",
      otherNumberError: "",
      termsAndConditions: "",
      tooManyRequest: false,
      lockHours: 0,
      lockMinutes: 0,
      lockCountDown: null,
      checkedConfirmLock: false,
      errorCode: "",
      authentication_method: null
    };
  },
  watch: {
    otherNumber(input) {
      if(this.authentication_method != 1) return false;
      // type='number'の際にeを入力できてしまうため
      input = input.replace(/e/g, '');
      this.otherNumber = input.length > 4 ? input.slice(0, -1) : input;
    }
  },
  methods: {
    async init() {
      $store.commit("checkin/set_loading", true);
      const query = this.$query.parse(location.search);
      if (!query.slug) return this.$router.replace({ path: "/error-404" });

      try {
        const response = await facilitiesApi.getBySlug(query.slug, true);
        if (response.data.length < 1) return this.$router.replace({ path: "/error-404" });
        this.facilityData = response.data[0];
        this.facilityData.confirmBooking = true;
        const facility_setting = this.facilityData.facility_setting.find(d => d.name == "form");
        // 設定が無い場合tokenなしURLが無効なので404へ
        if(!facility_setting) return this.$router.replace({ path: "/error-404" });
        this.authentication_method = facility_setting.value.authentication_method;
        this.facilityData.formMask = facility_setting.value.form_mask_method == 1 ? true : false;
        // 認証方法が0かnullならtokenなしURLが無効なので404へ
        if(!this.authentication_method) return this.$router.replace({ path: "/error-404" });

        switch(this.authentication_method){
          case 1:
              this.otherNumberLabel = this.$t("check-in-form.instruction-phone-last-4-digits");
              this.instructionSecondChunk = this.$t("check-in-form.instruction-ex-url-phone-last-4-digits");
              break;
          case 2:
              this.otherNumberLabel = this.$t("check-in-form.instruction-reservation-number");
              this.instructionSecondChunk = this.$t("check-in-form.instruction-ex-url-reservation-number");
              break;
          case 3:
              this.instructionSecondChunk = this.$t("check-in-form.instruction-ex-url-phone");
              break;
        }
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("check-in-form.error-generic"), {
          position: "top"
        });
        return;
      }

      $store.commit("checkin/set_loading", false);
      console.log('loading done');
      $store.commit("checkin/update_facility", this.facilityData);

      try {
        await bookingsAPI.checkConfirmLock();
        this.checkedConfirmLock = true;
      } catch (e) {
        console.log(e);
        const status = e.response.status;
        if (status === 429) {
          this.tooManyRequest = true;
          this.lockHours = e.response.data.data.hours;
          this.lockMinutes = e.response.data.data.minutes;
          $store.commit("checkin/set_loading", false);
          console.log('loading done');
          return;
        }
      }

      // 電話番号フル桁にrequired=trueをセット
      this.$nextTick(function() {
              document.querySelector('[name="telephone"]').setAttribute('required', true);
          });

    },
    updatePhoneNumber(num, obj) {
      const { country, number } = obj;
      if (!country || !number) return;
      this.updatePhone({ code: country.dialCode, number: number.significant });
      this.phoneError = "";
    },
    updateCheckInDate(num, obj) {
      const { date } = obj;
      if (!date) return;
      this.updateCheckIn({ date: date });
      this.checkInError = "";
    },
    async goNext() {
      const params = {
        otherNumber:  this.otherNumber,
        checkInDate:  this.checkInDate,
        slugName:     this.facilityData.slug_name
      }
      this.errorCode = '';
      this.lockCountDown = '';
      try {
        var response = await bookingsAPI.confirmBooking(
          {
            countryCode: this.countryCode,
            phoneNumber: this.phoneNumber
          },
          params
          );
      } catch (e) {
        // 事前登録済みの予約がヒットした
        if (e.response.data?.code === 422 && e.response.data.message === "Guest already checked in.") {
          return this.$router.replace({ path: "/error-422", query: { status: "checkin-used" } });
        }
        const status = e.response.status;
        this.errorCode = e.response.data.data.errorCode;
        this.lockCountDown = e.response.data.data.lockCountDown;
        if (status === 422) {
          this.phoneError = this.$t("check-in-form.error-phone-notfound");
          return;
        }
        if (status === 429) {
          this.tooManyRequest = true;
          this.lockHours = e.response.data.data.hours;
          this.lockMinutes = e.response.data.data.minutes;
          return;
        }
        this.$toast.error(this.$t("check-in-form.error-generic"), {
          position: "top"
        });
        return;
      }
      // Validate that record exist
      const data = response.data;
      if (data.length < 1) {
        this.phoneError = this.$t("check-in-form.error-phone-notfound");
        return;
      }
      // Save booking record & proceed to next step.
      this.updateBookingData(data[0]);
      this.$router.replace({ name: "checkin-step-2" });
    },
    ...mapActions("checkin", ["updateBookingData", "updateOtherNumber", "updatePhone", "updateCheckIn"])
  }
};
</script>
