<template>
  <b-modal :id="id" @input="(v) => $emit('input', v)" size="lg">
    <!-- Header -->
    <template v-slot:modal-title>
      <i class="far fa-user-circle"></i>
      {{ $t('check-in-form.word-companion') }} # {{ number }}
    </template>

    <!-- Main Content -->
    <table class="table-inner-stripe w-100">
      <tr v-for="(answer, index) in data.other_form" :key="`a_${index}`">
        <td>{{ translateLabel(index) }}</td>
        <td v-if="qmap[index].input_type == 'choices'">
          <div class="answer">
            <template v-if="Array.isArray(answer)">
              <b-badge
                v-for="(item, iindex) in answer"
                :key="`itm_${iindex}`"
                pill
                class="mr-1"
                variant="primary"
              >
                {{ item }}
              </b-badge>
            </template>
            <span v-else>{{ translateAnswer(index, answer) }}</span>
          </div>
        </td>
        <td v-else-if="qmap[index].input_type == 'image_file_upload'">
          <img
            v-if="answer.length > 0"
            :src="answer"
            class="img-contain"
            style="max-height: 400px"
          />
          <span v-else></span>
        </td>
        <td v-else>{{ translateAnswer(index, answer) }}</td>
      </tr>
      <tr v-if="data.email">
        <td>Email</td>
        <td>{{ data.email }}</td>
      </tr>
      <tr
        v-if="
          data.email == '' && data.other_form && !hasAnswers(data.other_form)
        "
      >
        <td>
          {{ $t('check-in-form.instruction-no-companion-question') }}
        </td>
      </tr>
    </table>

    <!-- Footer -->
    <template v-slot:modal-footer>
      <button
        type="button"
        class="btn btn-primary btn-min"
        @click="$bvModal.hide(id)"
      >
        Close
      </button>
    </template>
  </b-modal>
</template>

<script>
import genders from '@/utils/mockups/genders';
import occupations from '@/utils/mockups/occupations';
import yesno from '@/utils/mockups/yes-no';

export default {
  props: {
    id: { type: String, default: '' },
    qmap: { type: Object, default: () => {} },
    data: { type: Object, default: () => {} },
    number: { type: Number, default: 0 },
    nationalityNames: { type: Object, default: () => {} },
    prefectureNames: { type: Object, default: () => {} },
  },
  methods: {
    hasAnswers(form) {
      return Object.keys(form).length;
    },
    translateLabel(questionId) {
      const question = this.qmap[questionId];
      if (question.is_core != 1) return question.label;
      return this.$t(`system-questions.${question.system_name}`);
    },
    translateAnswer(questionId, answerText) {
      const question = this.qmap[questionId];
      const answer = answerText === null ? '' : answerText.trim();
      if (question.system_name == 'gender') {
        if (answer.length < 1) return '';
        return genders[this.$i18n.locale][answer.toLocaleLowerCase()];
      }
      if (question.system_name == 'occupation') {
        if (answer.length < 1) return '';
        if (
          typeof occupations[this.$i18n.locale][answer.toLocaleLowerCase()] ==
          'undefined'
        )
          return '';
        return occupations[this.$i18n.locale][answer.toLocaleLowerCase()];
      }
      if (question.system_name == 'nationality') {
        if (answer.length < 1) return '';
        const nationalityObj = this.nationalityNames[answer];
        if (typeof nationalityObj == 'undefined') return '';
        if (typeof nationalityObj[this.$i18n.locale] == 'undefined') return '';
        return nationalityObj[this.$i18n.locale];
      }
      if (question.system_name == 'prefecture') {
        if (answer.length < 1) return '';
        if (
          typeof this.prefectureNames[answer.toLocaleLowerCase()] == 'undefined'
        )
          return '';
        return this.prefectureNames[answer.toLocaleLowerCase()][
          this.$i18n.locale
        ];
      }
      return answer;
    },
  },
};
</script>
