export default {
  namespaced: true,
  state: () => ({
    slugName: '',
    facilities: [],
    facilityIndex: 0,
    activeMenu: 'list',
    userData: {}
  }),
  getters: {
    facility ({ facilities, facilityIndex }) {
      return facilities[facilityIndex];
    },
    permissions ({ userData }) {
      if (typeof userData.user_permissions === "undefined") {
        return [];
      }
      return userData.user_permissions;
    },
    userData (state) {
      return state.userData;
    }
  },
  mutations: {
    update_facilities (state, payload) {
      state.facilities = payload;
    },
    update_active_menu (state, payload) {
      state.activeMenu = payload;
    },
    update_userdata (state, payload) {
      state.userData = payload;
    },
  },
  actions: {
    updateFacilities ({ commit }, payload) {
      commit('update_facilities', payload)
    },
    updateActiveMenu ({ commit }, payload) {
      commit('update_active_menu', payload)
    },
    updateUserData ({ commit }, payload) {
      commit('update_userdata', payload)
    },
  }
}
