<template>
  <div class="form-group" :id="id" :class="{ errored: error.length > 0 }">
    <label v-if="label" class="flex-center-label">
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
    </label>
    <div class="d-flex w-100">
      <div style="position: relative" class="flex-fill">
        <input
          :type="type"
          class="form-control"
          :class="{
            'border-primary': error.length < 1,
            'border-danger': error.length > 0,
          }"
          :id="id"
          :value="value"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          @input="(e) => $emit('input', e.target.value, e)"
          @keypress="(e) => $emit('keypress', e)"
          @paste="(e) => $emit('paste', e)"
        />
        <button
          v-show="value && value.length > 0 && error.length < 1 && !disabled"
          @click="(v) => $emit('input', '')"
          type="button"
          class="form-icon form-icon-clear"
          title="clear"
        >
          <i class="fas fa-times"></i>
        </button>
        <button
          v-show="error.length > 0"
          @click="(v) => $emit('input', '')"
          type="button"
          class="form-icon form-icon-exclamation"
          title="clear"
        >
          <i class="fas fa-exclamation-circle"></i>
        </button>
      </div>
      <!-- <a
        v-if="copyButton"
        type="button"
        class="btn btn-primary btn-round text-white ml-1"
        v-b-tooltip.hover
        :title="$t('check-in-form.tip-copy-from-main')"
        @click="() => $emit('copy')"
      >
        <i class="far fa-copy" />
      </a> -->
      <a
        v-if="copyButton"
        class="btn btn-primary btn-round text-white ml-1"
        v-b-tooltip.hover
        :title="$t('check-in-form.tip-copy-from-main')"
        @click="() => $emit('copy')"
      >
        <i class="far fa-copy" />
      </a>
    </div>

    <small
      v-if="!noErrorMessage & (error.length > 0)"
      class="form-message text-danger"
      >{{ error }}</small
    >
  </div>
</template>


<script>
export default {
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    copyButton: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    error: { type: String, default: "" },
    noErrorMessage: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    id: String
  }
};
</script>
