<template>
  <b-modal v-model="show" :title="$t('common.title-confirm')" centered>
    <div v-html="message"></div>
    <template v-slot:modal-footer>
      <div class="text-right">
        <button @click="yes" class="btn btn-warning btn-min text-white">
          <i class="fas fa-check mr-1" />
          {{ $t("common.word-yes") }}
        </button>
        <button @click="no" class="btn btn-outline-danger btn-min ml-3">
          <i class="fas fa-times mr-1" />
          {{ $t("common.word-no") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { eventBus } from "@/event-bus";

export default {
  created() {
    eventBus.$on("confirm-action", this.confirm);
  },
  data() {
    return {
      show: false,
      message: "",
      yesFunction: null,
      noFunction: null
    };
  },
  methods: {
    confirm(message, action) {
      this.message = message;
      if (typeof action.yes === "function") this.yesFunction = action.yes;
      else this.yesFunction = null;
      if (typeof action.no === "function") this.noFunction = action.no;
      else this.noFunction = null;
      this.show = true;
    },
    yes() {
      if (typeof this.yesFunction === "function") this.yesFunction();
      this.show = false;
    },
    no() {
      if (typeof this.noFunction === "function") this.noFunction();
      this.show = false;
    }
  }
};
</script>