import t from "typy";
import draggable from "vuedraggable";
import questionsApi from "@/api/questions";
import filtersApi from "@/api/localized-filters";
import { mapActions, mapGetters, mapState } from "vuex";
import uac from "@/mixins/uac";
import { questionsForMainGuest } from "../../../utils/constants/questions_for_main_guest";

export default {
  components: {
    draggable
  },
  computed: {
    ...mapState(['languages']),
    ...mapGetters('dashboard', ['facility']),
  },
  data () {
    return {
      lang: '',
      submit_btn: '',
      error_msg: '',
      add_msg: '',
      updated_msg: '',
      remove_msg: '',
      mode: 'create',
      facility_questions: [],
      optional_question: {},
      optional_questions: [],
      config: {
        question_delete_id: 0,
      },
      temp_questions: [],
      filter_enabled: true,
      filters: []
    }
  },
  async mounted () {
    this.updateActiveMenu("form");
    await this.getFilters();
    await questionsApi.init({ facility_id: this.facility.id });
    await this.loadQuestions()
    this.config = { save_button: 0 };
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    setInitialQuestionObj () {
      this.optional_question = {
        language: this.lang,
        facility_id: this.facility.id
      }
    },
    questionsForMainGuest(){
      return questionsForMainGuest;
    },
    async getFilters () {
      try {
        const data = await filtersApi.show(this.facility.id);
        const filters = data.facility_localized_category;
        const list = [];
        if (t(filters).isArray) {
          filters.forEach((filter) => {
            list.push({ text: filter.name, value: filter.id });
          });
          this.filter_enabled = data.value.visibility;
          this.filters = list;
        }
      } catch (e) {
        console.error('form.js -> getFilters', e.message);
      }
    },
    async deleteQuestion (id) {
      await this.$http.delete('/facility-optional-questions/' + this.optional_question.id).then(r => {
        const key = this.temp_questions.map((el) => el.id).indexOf(this.optional_question.id);
        if (key >= 0) {
          this.$delete(this.temp_questions, key);
        }
        this.loadQuestions(this.optional_question.language);
        jQuery('#modalQDelete').modal('toggle');
      });
    },
    openQuestionModal (id, obj) {
      this.optional_question = {};
      if (id) {
        this.optional_question = Object.assign({}, obj);
      }
      else {
        this.setInitialQuestionObj();
      }
      if (!this.optional_question.metadata) {
        this.optional_question.metadata = {
          options: []
        }
      }
      if (!this.optional_question.metadata.options) {
        this.optional_question.metadata.options = [];
      }
      this.$bvModal.show('modal-update-question');
    },
    async btnSaveQuestion () {
      var default_value = {
        input_type: "string",
        section: "optional",
        visibility: 1,
        is_core: 0,
        system_name: "optional_question",
        id: new Date().getTime() + '_new'
      }
      const language = this.optional_question.language;
      this.temp_questions.push({ ...this.optional_question, ...default_value });

      jQuery('#modalQuestion').modal('toggle');
      await this.populate_questions();
      this.config.save_button = 1;
      this.setInitialQuestionObj();
    },
    async batchSavingQuestion () {
      try {
        const questions = Object.assign(this.temp_questions, this.facility_questions);
        await questionsApi.save(this.facility.id, questions, this.filters);
        this.temp_questions = [];
        this.config.save_button = 0;
        this.loadQuestions(this.lang);
        this.$toast.success(this.$t('dashboard-common.updated-generic'), { position: "top" });
      } catch (e) {
        this.$toast.error(this.$t('dashboard-common.error-generic'), { position: "top" });
      }
    },
    async populate_questions () {
      this.optional_questions = [];
      this.facility_questions.forEach((value, key) => {
        if (this.temp_questions) {
          var q = this.temp_questions.find(el => el.id == value.id);
          if (q) {
            this.facility_questions[key] = q;
          }
        }
      });
      this.temp_questions.forEach((value, key) => {
        const k = this.optional_questions.map((el) => el.id).indexOf(value.id);
        if (k >= 0) {
          this.optional_questions[k] = value;
        } else if (value.id.toString().indexOf('_new') !== -1) {
          this.optional_questions.push(value);
        }
      });
    },
    async loadQuestions (language) {
      if (!language) language = (this.$i18n.locale == 'ja') ? 'ja' : 'en'

      this.lang = language;

      // Init
      //await questionsApi.init({ facility_id: this.facility.id });

      // Fetch questions
      const facility_questions = await questionsApi.fget({
        facility_id: this.facility.id,
        language,
        paginate: false,
      });
      this.facility_questions = facility_questions.data;
      await this.populate_questions();
    },
    async updateQuestion (fq, obj, method = null) {
      if (method == "switch") {
        fq.visibility = (obj.value) ? 1 : 0
      }
      if (method == "required") {
        fq.required = (obj.value) ? 1 : 0
      }
      if (method == "switch_companion") {
        fq.visibility_companion = (obj.value) ? 1 : 0
      }
      if (method == "required_companion") {
        fq.required_companion = (obj.value) ? 1 : 0
      }      
      const key = this.temp_questions.map((el) => el.id).indexOf(fq.id);
      if (key >= 0) {
        this.temp_questions[key] = fq;
      } else {
        this.temp_questions.push({ ...fq });
      }
      this.$set(this.facility_questions, this.facility_questions.findIndex(el => el.id == fq.id), fq);
      this.$bvModal.hide('modal-update-question');

      this.config.save_button = 1;
      this.populate_questions();
    },
    errorDisplay (d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    },
    async addOption () {
      this.optional_question.metadata.options.push({
        name: ''
      });
    }
  },
  mixins: [uac]
}