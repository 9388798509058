<template>
  <div class="form-group" :id="id" :class="{ errored: error.length > 0 }">
    <label class="flex-center-label">
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark">{{ $t('common.word-required') }}</span>
    </label>
    <div class="d-flex w-100">
      <b-form-select 
        v-model="dateValue.year"
        @change="update"
        :required="required"
        class="mr-1"
        :class="{
          'border-primary': error.length < 1 && dateValue.year == null,
          'border-danger' : error.length > 0 && dateValue.year == null,
        }"
      >
        <b-form-select-option
          v-for="(year, yindex) in dateOptions.year"
          :key="`y_${yindex}`"
          :value="isNaN(year) ? null : year"
        >
          {{ year }}
        </b-form-select-option>
      </b-form-select>
      <b-form-select 
        v-model="dateValue.month"
        @change="update"
        :required="required"
        class="mr-1"
        :class="{
          'border-primary': error.length < 1 && dateValue.month == null,
          'border-danger' : error.length > 0 && dateValue.month == null,
        }"
      >        <b-form-select-option
          v-for="(month, mindex) in dateOptions.month"
          :value="isNaN(month) ? null : month"
          :key="`m_${mindex}`"
        >
          {{ month }}
        </b-form-select-option>
      </b-form-select>
      <b-form-select 
        v-model="dateValue.day"
        @change="update"
        :required="required"
        class="mr-1"
        :class="{
          'border-primary': error.length < 1 && dateValue.day == null,
          'border-danger' : error.length > 0 && dateValue.day == null,
        }"
      >        <b-form-select-option
          v-for="(day, dindex) in dateOptions.day"
          :value="isNaN(day) ? null : day"
          :key="`d_${dindex}`"
        >
          {{ day }}
        </b-form-select-option>
      </b-form-select>
      <a
        v-if="copyButton"
        type="button"
        class="btn btn-primary btn-round text-white ml-1"
        v-b-tooltip.hover
        :title="$t('check-in-form.tip-copy-from-main')"
        @click="() => $emit('copy')"
      >
        <i class="far fa-copy" />
      </a>
    </div>
    <small v-if="!noErrorMessage && error.length > 0" class="form-message text-danger">{{
      error
    }}</small>
  </div>
</template>


<script>
export default {
  props: {
    value: { type: String },
    label: { type: String, defalt: "label" },
    required: { type: Boolean, default: false },
    noErrorMessage: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    copyButton: { type: Boolean, default: false },
    error: { type: String, default: "" },
    id: String
  },
  created() {
    this.buildDateYear();
    this.buildDateMonth();
    this.buildDateDay();
  },
  async mounted() {
    if(this.value == null) return false;
    const [year, month, day] = this.value.split('-');
    this.dateValue = {
        year: String(year),
        month: String(month),
        day: String(day),
      }
  },
  data() {
    return {
      dateOptions: {
        year: [],
        month: [],
        day: [],
      },
      dateValue: {
        year: null,
        month: null,
        day: null,
      }
    }
  },
  methods: {
    buildDateYear() {
      const nowYear = (new Date()).getFullYear();
      const yearList = [];
      for (let x = 1900; x <= 2021; x++) yearList.push(this.padNumber(x));
      Array.prototype.splice.apply(yearList,[81, 0].concat(['--']));
      this.dateOptions.year = yearList;
    },
    buildDateMonth() {
      const monthList = ['--'];
      for (let x = 1; x <= 12; x++) monthList.push(this.padNumber(x));
      this.dateOptions.month = monthList;
    },
    buildDateDay() {
      const dayList = ['--'];
      for (let x = 1; x <= 31; x++) dayList.push(this.padNumber(x));
      this.dateOptions.day = dayList;
    },
    padNumber(value) {
      if (String(value).length > 1) return String(value);
      return `0${value}`;
    },
    update() {
      const { year, month, day } = this.dateValue;
      const value = [year, month, day].indexOf(null) === -1 ? `${year}-${month}-${day}` : '';
      this.$emit("input", value);
    },
  },
  watch: {
    value(v) {
      const [year, month, day] = v.split('-');
      this.dateValue = {
        year: String(year),
        month: String(month),
        day: String(day),
      }
    }
  }
};
</script>