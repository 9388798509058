<template></template>

<script>
import { mapActions } from "vuex";
import facilitiesApi from "@/api/facilities";
import bookingsApi from "@/api/bookings";
export default {
  async mounted() {
    await this.init();
  },
  data() {
    return {
      booking_status_id: 20, // Accessed
      reservation_no: null,
      pms_code: null
    }
  },
  methods: {
    ...mapActions("checkin", ["setMaxRetryReached"]),
    async init() {
      $store.commit("checkin/set_loading", true);
      const query = this.$query.parse(location.search);

      // Ensure slug & token value is present.
      if (!query.token && !query.slug) return this.$router.replace({ path: "/error-404" });

      // Validate slug & set facility data.
      let facilityData = null;
      try {
        const response = await facilitiesApi.getBySlug(query.slug, true);
        if (response.data.length < 1) return this.$router.replace({ path: "/error-404" });
        facilityData = response.data[0];
        const pms_setting = facilityData.facility_setting.find(d => d.name == "pms_settings");
        this.pms_code = pms_setting.value.pms_code;
        $store.commit("checkin/update_facility", facilityData);
        if (query.token) $store.commit("checkin/set_token", query.token);
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t("check-in-form.error-generic"), {
          position: "top"
        });
        return;
      }

      // Apply maintenance mode state.
      const maintenance = this.isMaintenanceMode(facilityData);
      if (maintenance) return this.$router.replace({ path: "/error-503" });

      // slugとtokenの両方がある場合は予約情報取得
      const tokenStatus = query.token && query.slug ? await this.validateToken(query) : null;
      if (tokenStatus?.code == 422) {
        return this.$router.replace({ path: "/error-422", query: { status: tokenStatus.message } });
      } else if (tokenStatus?.code == 404) {
        return this.$router.replace({ path: "/error-404" });
      }

      const statusData = {
        reservation_no: this.reservation_no,
        pms_code: this.pms_code,
        booking_status_id: this.booking_status_id
      }

      // Update booking status code to 'Accessed'.
      if(statusData.reservation_no) await this.updateBookingStatus(statusData);

      $store.commit("checkin/set_loading", false)
      
      // Go to next.
      if(query.token && tokenStatus.code == 200){
        this.$router.replace({ name: "checkin-step-1" });
      } else {
        // slugはあるもののtokenが無い場合
        // 認証方法が0かnullならtokenなしURLが無効なので404へ
        const facility_setting = facilityData.facility_setting.find(d => d.name == "form");
        if(!facility_setting.value.authentication_method) return this.$router.replace({ path: "/error-404" });
        // 認証方法が0以外なら認証ページへ
        return this.$router.replace({ name: "checkin-confirm", query: { slug: query.slug } });
      }
    },
    async updateBookingStatus(data) {
      try {
        await bookingsApi.createStatusLog(data);
      } catch(e) {
        console.error('init->bookingsApi.createStatusLog', e.message);
      }
    },
    async validateToken(query) {
      const status = { code: 200, message: '' };
      try {
        const response = await bookingsApi.getCheckinStatus(query, true);
        if (response.data.attempts == 3) this.setMaxRetryReached(true);
        else {
          // Find booking status code & reservation_no.
          let booking_status_id = null;
          this.reservation_no = response.data.reservation_no;
          try {
            const statusQuery = await bookingsApi.getStatuses();
            const statusObj = statusQuery.find(v => v.name == "Accessed");
            booking_status_id = statusObj.id;
          } catch (e) {
            console.error('Init.vue -> bookingsApi.getStatuses', e.message);
          }
          if (booking_status_id) this.booking_status_id = booking_status_id
        }
      } catch (e) {
        console.error("Init.vue", e);
        if (e.response.data.code == 422) {
          const data = e.response.data;
          status.code = 422;
          if(data.message == 'Resevation expired.') status.message = "checkin-expired";
          else status.message = "checkin-used";
        } else {
          status.code = 404;
        }
      }
      return status;
    },
    isMaintenanceMode(facilityData) {
      const settings = facilityData.facility_setting ?? null;
      if (!settings) return 0;
      const mode = settings.find(setting => (setting.name == 'maintenance_mode'));
      const value = mode ? (mode.value ?? 0) : 0;
      return value > 0 ? true : false;
    }
  }
};
</script>