import http, { httpmock } from '@/utils/http';
const endpoint = 'facility-setting';

export default {
  async getLanguages (facility_id, no_loader) {
    const isCheckinRoute = true;
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: 'language', paginate: false, isCheckinRoute: isCheckinRoute }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getFaceRecognitionSettings (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config.params = { facility_id }
    const response = await http.get(`face-recognition-settings/${facility_id}`, config);
    return response.data;
  },
  async updateFaceRecognitionSettings (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const { facility_id } = data;
    config.params = { facility_id }
    const response = await http.put(`face-recognition-settings/${facility_id}`, data, config);
    return response.data;
  },
  async getMaintenanceSettings (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const data = {
      facility_id,
      name: 'maintenance_mode',
      paginate: false
    }
    const response = await http.post(`facility-setting/list`, data, config);
    return response.data;
  },
  async getNotifications (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: 'notification_frequency_settings', paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getLinks (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: 'mobile-app-links', paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getTerms (facility_id, lang, no_loader) {
    const isCheckinRoute = true;
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: `terms-conditions-${lang}`, paginate: false, isCheckinRoute: isCheckinRoute }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getLanding2 (facility_id, lang, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: `message-landing2-${lang}`, paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getLanding2State (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: `state-landing2-enabled`, paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getForm (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: 'form', paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async getConfirmLock (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const query = { facility_id, name: 'confirm_lock', paginate: false }
    const response = await http.post(`${endpoint}/list`, query, config);
    return response.data;
  },
  async save (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/store`, { ...data }, config);
    return response.data;
  },
  async updateFrequency (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/update-frequency`, { ...data }, config);
    return response.data;
  },
  async updateSms (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/update-sms-status`, { ...data }, config);
    return response.data;
  }
}
