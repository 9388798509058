<template>
  <button
    type="button"
    class="btn btn-outline-primary rounded-circle border-2"
    @click="$emit('click')"
  >
    <i :class="iconClass" />
  </button>
</template>

<script>
export default {
  computed: {
    iconClass() {
      const directions = {
        prev: "fas fa-arrow-left",
        next: "fas fa-arrow-right"
      };
      const foundClass = directions[this.direction];
      return foundClass ? foundClass : directions["next"];
    }
  },
  props: {
    direction: {
      type: String,
      default: "next" // 'prev' or 'next'
    }
  }
};
</script>