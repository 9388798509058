<template>
  <form @submit.prevent="submitForm">
    <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
      <!-- Name -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-name") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.name"
            type="text"
            required
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-name')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- Phone -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-phone") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.phone"
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-phone')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- Email -->
      <!-- <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-email") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="user.email"
            type="email"
            v-debounce:500ms="checkEmail"
            required
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-email')"
          />
        </div>
      </div> -->
      <!-- Website URL -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-website-url") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.url"
            type="text"
            class="form-control"
            placeholder="https://www.site.com"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- Country -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-country") }}
        </label>
        <div class="col-sm-8">
          <select
            v-model="facilityData.country"
            required
            class="form-control"
            :readonly="!hasAccess('setting.basic_information.edit')"
          >
            <option value="">--</option>
            <option
              v-for="(cname, index) in changeNationality()"
              :key="`c${index}`"
              :value="cname.CountryCode"
            >
              {{ cname.Nationality }}
            </option>
          </select>
        </div>
      </div>
      <!-- State -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-state") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.state"
            required
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-state')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- City -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-city") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.city"
            required
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-city')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- Address -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-address") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.address"
            required
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-address')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <!-- PreCheck-In Url -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-precheckin-url") }}
        </label>
        <div class="col-sm-8">
          <!-- <div class="form-control">
            {{ precheckInUrl }}
          </div> -->
          <input
            v-model="precheckInUrl"
            :disabled=true
            required
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-address')"
            :readonly="!hasAccess('setting.basic_information.edit')"
          />
        </div>
      </div>
      <div class="form-group" align="right">
        <button
          type="button"
          class="btn btn-outline-secondary btn-wide mx-1 rounded-pill"
          v-if="hasAccess('setting.basic_information.edit')"
          @click="downloadQR"
        >
          {{ $t("dashboard-settings.button-download-precheckin-url") }}
        </button>
      </div>  
      <!-- Building -->
      <!-- <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.profile-label-building") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="facilityData.building"
            type="text"
            class="form-control"
            :placeholder="$t('dashboard-settings.profile-label-building')"
          />
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-12" align="right">
        <button
          type="submit"
          class="btn btn-warning btn-wide mx-1"
          v-if="hasAccess('setting.basic_information.edit')"
        >
          <i class="fas fa-save mr-1" />
          {{ $t("dashboard-settings.button-save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// import t from "typy";
import facilitiesApi from "@/api/facilities";
import moment from "moment-timezone";
import { nationalities } from "@/utils/mockups/";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  mixins: [uac],
  data() {
    return {
      updated: 0,
      email_exists: false,
      errorEmail: "",
      facilityData: {},
      user: { email: "" },
      precheckInUrl: "",
    };
  },
  async mounted() {
    this.facilityData = {
      id: this.facility.id,
      name: this.facility.name,
      phone: this.facility.phone,
      url: this.facility.url,
      address: this.facility.address,
      building: this.facility.building,
      city: this.facility.city,
      state: this.facility.state,
      country: this.facility.country,
      receive_checkin_email: this.facility.receive_checkin_email,
      slug_name: this.facility.slug_name,
    };
    this.user = this.facility.user;
    this.precheckInUrl = window.location.origin + '/check-in?slug=' + this.facility.slug_name;

  },
  methods: {
    ...mapActions("dashboard", ["updateFacilities"]),
    async downloadQR() {
      try {
        const response = await facilitiesApi.downloadQR({ user_id: this.facility.user.id});
        // responseにbase64形式でQRコード画像が返ってくるので、要素化してダウンロードする
        let element = document.createElement('a');
        element.href = response.data.qr;
        element.download = this.facility.slug_name + '.png';
        element.target = '_blank';
        element.click();
        element.remove();
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async checkEmail(e) {
      if (this.user.email == "") return;
      const data = {
        email: this.user.email,
        id: this.facility.id,
        type: "facility"
      };
      const email = await this.$http
        .post("/user/exists", data)
        .then(r => this.catcher(r.data, "data.exist", r => r.data))
        .catch(e => this.catcher(e));
      if (email.error.has_error) return this.errorDisplay(email);
      this.email_exists = this.$("dashboard-settings.error-email-exist");
    },
    async submitForm(e) {
      const fe = ["error", "updated_at", "user_id", "created_at"];
      let data = {
        facility_id: this.facility.id,
        facility: this.facilityData,
        user: this.user
      };

      fe.map(obj => delete this.facility[obj]);
      try {
        await facilitiesApi.update(data);
        const response = await facilitiesApi.get({ with: "user" });
        this.updateFacilities(response.data);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    changeNationality() {
      return nationalities[this.$i18n.locale];
    },
    formatDate(date) {
      if (date == null) return "";
      const timezone = moment.tz.guess();
      return moment.tz(date, timezone).format("MM/DD/YYYY");
    }
  }
};
</script>