<template>
  <div>
    <nav
      class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
    >
      <!-- Sidebar Toggle (Topbar) -->
      <button
        id="sidebarToggleTop"
        class="btn btn-link d-md-none rounded-circle mr-3"
      >
        <i class="fa fa-bars"></i>
      </button>

      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="" @click.prevent="$i18n.locale = 'en'">
            <span
              class="language-btn d-lg-inline text-gray-600 small font-weight-bold"
              :class="{ active: $i18n.locale == 'en' }"
            >
              English
            </span>
          </a>
        </li>
        <li style="line-height: 70px">
          <a class="text-muted">|</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="" @click.prevent="$i18n.locale = 'ja'">
            <span
              class="language-btn d-lg-inline text-gray-600 small font-weight-bold"
              :class="{ active: $i18n.locale == 'ja' }"
            >
              日本語
            </span>
          </a>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span
              class="mr-2 d-none d-lg-inline text-gray-600 small font-weight-bold"
            >
              ID: {{ userData.username }}
            </span>
          </a>
          <!-- Dropdown - User Information -->
          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <a
              @click.prevent="logout"
              class="dropdown-item"
              data-toggle="modal"
              data-target="#logoutModal"
            >
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              {{ $t("account-page.logoff") }}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";
.language-btn {
  text-align: center;
  min-width: 65px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3.2px;
  border: 1px solid transparent;
  &.active {
    color: white !important;
    background-color: $primary;
  }
  &:not(&.active):hover {
    background-color: #fcfcfc;
    border-color: rgba(0, 0, 0, 0.1);
  }
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("dashboard", ["userData"]),
    ...mapGetters("dashboard", ["facility"])
  },
  methods: {
    logout() {
      this.$storage.remove("auth");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>