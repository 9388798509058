import http, { httpmock } from '@/utils/http';

const endpoint = 'facility';

export default {
  async get (options = {}) {
    const auth = $storage.get("auth");
    const facility_id = auth.user.user_facility.facility_id;
    const meta = { phone: '', site_url: '' }
    const transformed = { data: [], total: 0 }
    const data = { user_id: auth.id, settings: 1, ...options }
    const params = { id: facility_id }
    const response = await http.post(`${endpoint}/list`, data, { params });
    for (const entry of response.data.data) transformed.data.push({ ...meta, ...entry });
    return { ...transformed, total: response.data.total };
  },
  async update (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.put(`${endpoint}/update`, { ...data }, config);
    return response;
  },
  async getBySlug (slug_name, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/list`, { slug_name, settings: 1 }, config);
    return response.data;
  },
  async addName (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`facility-name/store`, { ...data }, config);
    return response;
  },
  async updateName (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.put(`facility-name/update`, { ...data }, config);
    return response;
  },
  async deleteName (data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.delete(`facility-name`, { ...data }, config);
    return response;
  },
  async register (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${endpoint}/store`, { ...data }, config);
    return response.data;
  },
  async slugExists (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${endpoint}/exists`, { ...data }, config);
    return response.data;
  },
  async downloadQR (data, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true }, params } : { params };
    const response = await http.post(`${endpoint}/download-qr`, { ...data }, config);
    return response.data;
  },
}