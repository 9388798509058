import http, { httpmock } from '@/utils/http';

const endpoint = "roles";

export default {
  async list (params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.get(`${endpoint}`, { params }, config);
    return response.data;
  }
}