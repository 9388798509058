import http, { httpmock } from '@/utils/http';

const endpoint = 'localized-filters';

export default {
  async get (data = {}, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const facility = $store.state.checkin.facility;
    const params = { params: { facility_id: facility.id, ...data } }
    const response = await http.get(endpoint, params, config);
    return response.data.data;
  },
  async show (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.get(`${endpoint}/${facility_id}`, {}, config);
    return response.data.data;
  },
  async showPublic (facility_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config.params = { facility_id }
    const response = await http.get(`${endpoint}/`, config);
    return response.data.data;
  },
  async update (facility_id, data, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.put(`${endpoint}/${facility_id}`, data, config);
    return response.data.data;
  }
}