<template>
  <form @submit.prevent="submitForm">
    <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
      <p>{{ $t("dashboard-settings.instruction-links") }}</p>
      <!-- Google Play -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.links-label-google-play") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="data.value.play_store"
            type="url"
            class="form-control"
            placeholder="https://play.google.com/store/apps/details?id=<package_name>"
          />
        </div>
      </div>
      <!-- App Store -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.links-label-app-store") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="data.value.app_store"
            type="url"
            class="form-control"
            placeholder="https://apps.apple.com/<country>/app/<app–name>/id<store-ID>"
          />
        </div>
      </div>
      <!-- Laplink -->
      <!-- <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-settings.label-laplink") }}
        </label>
        <div class="col-sm-8">
          <input
            v-model="data.value.laplink"
            type="url"
            class="form-control"
            placeholder="https://my.other-url.com"
          />
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-12" align="right">
        <button type="submit" class="btn btn-warning btn-wide mx-1">
          <i class="fas fa-save mr-1" />
          {{ $t("dashboard-settings.button-save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// import t from "typy";
import settingsApi from "@/api/settings";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  mixins: [uac],
  data() {
    return {
      data: {
        facility_id: null,
        name: "mobile-app-links",
        value: {
          play_store: "",
          app_store: "",
          laplink: ""
        }
      }
    };
  },
  beforeMount() {
    this.data.facility_id = this.facility.id;
  },
  async mounted() {
    try {
      const response = await settingsApi.getLinks(this.facility.id);
      if (response.total > 0) {
        const data = { ...this.data, ...response.data[0] };
        this.data = data;
      }
    } catch (e) {
      console.error(e.message);
      this.$toast.error(this.$t("dashboard-common.error-generic"), {
        position: "top"
      });
    }
  },
  methods: {
    ...mapActions("dashboard", ["updateFacilities"]),
    async submitForm(e) {
      try {
        const response = await settingsApi.save(this.data);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    }
  }
};
</script>