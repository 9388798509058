<template>
  <div>
    <h1 class="h3 mb-2 text-gray-800">
      {{ $t('dashboard-list.title') }}
    </h1>

    <!-- Search block -->
    <form @submit.prevent="search">
      <!-- Date & time search -->
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <table class="table table-borderless" id="filter" style="width: auto">
            <tr>
              <td class="font-weight-bold">
                {{ $t('dashboard-list.check-in-date-from') }}
              </td>
              <td>
                <b-form-datepicker
                  hide-header
                  v-model="filters.from_checkin_date"
                  offset="-1"
                  :date-format-options="date_format"
                  :label-no-date-selected="$t('common.text-nodate')"
                  :required="filters.from_checkin_date ? true : false"
                />
                <!-- <input
                  type="date"
                  class="form-control"
                  v-model="filters.from_checkin_date"
                /> -->
              </td>
              <td class="font-weight-bold">
                {{ $t('dashboard-list.check-in-date-to') }}
              </td>
              <td>
                <b-form-datepicker
                  hide-header
                  v-model="filters.to_checkin_date"
                  offset="-1"
                  :date-format-options="date_format"
                  :label-no-date-selected="$t('common.text-nodate')"
                  :required="filters.to_checkin_date ? true : false"
                />
                <!-- <input
                  type="date"
                  class="form-control"
                  v-model="filters.to_checkin_date"
                  :required="filters.from_checkin_date"
                /> -->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Search Conditions -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="border rounded">
            <table class="table table-borderless mb-0">
              <thead>
                <tr>
                  <th>
                    {{ $t('dashboard-list.search-condition') }}
                    <hr class="my-1" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-center justify-content-lg-between
                      "
                    >
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="" @change="updateFilterChange">
                          {{ $t('dashboard-list.all') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="not_subject" @change="updateFilterChange">
                          {{ $t('dashboard-list.not_subject') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="before_send" @change="updateFilterChange">
                          {{ $t('dashboard-list.before_send') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="sent" @change="updateFilterChange">
                          {{ $t('dashboard-list.sent') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="opened" @change="updateFilterChange">
                          {{ $t('dashboard-list.opened') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="accessed" @change="updateFilterChange">
                          {{ $t('dashboard-list.accessed') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="input" @change="updateFilterChange">
                          {{ $t('dashboard-list.input') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="seen" @change="updateFilterChange">
                          {{ $t('dashboard-list.seen') }}
                        </b-form-radio>
                      </div>
                      <div class="mx-2">
                        <b-form-radio v-model="filters.status" value="confirmed" @change="updateFilterChange">
                          {{ $t('dashboard-list.confirmed') }}
                        </b-form-radio>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex search-condition-row-2">
                      <div class="no-wrap">
                        <b-form-checkbox class="d-inline mx-3" v-model="filters.has_email_exists" value="true" @change="updateFilterChange">
                          {{ $t('dashboard-list.email-exists') }}
                        </b-form-checkbox>
                        <b-form-checkbox class="d-inline mx-3" v-model="filters.has_no_email_exists" value="true" @change="updateFilterChange">
                          {{ $t('dashboard-list.no-email-exists') }}
                        </b-form-checkbox>
                        <b-form-checkbox class="d-inline mx-3" v-model="filters.has_passport_picture" value="true" @change="updateFilterChange">
                          {{ $t('dashboard-list.passport-picture') }}
                        </b-form-checkbox>
                        <b-form-checkbox class="d-inline mx-3" v-model="filters.has_face_picture" value="true" @change="updateFilterChange">
                          {{ $t('dashboard-list.face-picture') }}
                        </b-form-checkbox>
                      </div>
                      <div class="no-wrap flex-fill text-right">
                        <button type="button" @click="clearFilter" class="btn btn-outline-danger btn-block">
                          <i class="fas fa-times"></i>
                          {{ $t('dashboard-list.clear_this_filter') }}
                        </button>
                        <button type="button" @click="saveFilter" :disabled="!filtersChanged" class="btn btn-warning m-0 btn-block ml-2">
                          <i class="fas fa-save"></i>
                          {{ $t('dashboard-list.save_this_filter') }}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="search-condition-row-3">
                  <td>
                    <div class="w-100 text-center no-wrap">
                      <button type="button" @click="clearFilter" class="btn btn-outline-danger btn-block" :disabled="!hasFilterSet">
                        <i class="fas fa-times"></i>
                        {{ $t('dashboard-list.clear_this_filter') }}
                      </button>
                      <button type="button" @click="saveFilter" :disabled="!filtersChanged" class="btn btn-warning m-0 btn-block ml-2">
                        <i class="fas fa-save"></i>
                        {{ $t('dashboard-list.save_this_filter') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="padding-left: 12px; padding-right: 12px">
              <hr class="my-1" />
            </div>
            <table v-if="hasAccess('list.search_terms.edit')" id="nomination-search" class="table table-borderless mb-0">
              <tr>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="field-label">
                      {{ $t('dashboard-list.search-reservation-id') }}
                    </span>
                    <input type="text" :placeholder="$t('dashboard-list.search-reservation-id')" class="form-control" v-model="filters.reservation_number" />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="field-label">
                      {{ $t('dashboard-list.search-name') }}
                    </span>
                    <input type="text" :placeholder="$t('dashboard-list.search-name')" class="form-control" v-model="filters.name" />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="field-label">
                      {{ $t('dashboard-list.search-phone') }}
                    </span>
                    <input type="text" :placeholder="$t('dashboard-list.search-phone')" class="form-control" v-model="filters.phone_number" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="12 pt-0">
                  <div class="d-flex justify-content-center justify-content-md-end">
                    <button type="submit" class="btn btn-warning btn-block">
                      <i class="fas fa-search"></i>
                      {{ $t('dashboard-list.search') }}
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </form>

    <!-- Control Guide Mail Form -->
    <template v-if="displayControlGuideMailForm()">
      <div class="w-100 small border p-3">
        <p>
          <strong class="guide-mail-main-title">{{ $t('dashboard-guide-mail.main-title') }}</strong>
        </p>
        <div class="d-flex">
          <div id="selectMail" class=" pr-2">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('dashboard-guide-mail.cms') }}</th>
                  <th>
                    {{ $t('dashboard-guide-mail.instruction-guide-mail-select') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pr-2">
                    <label>{{ $t('dashboard-guide-mail.label-email') }}</label>
                  </td>
                  <td>
                    <b-form-select class="form-control w-100" v-model="selectedEmailGuideData" required>
                      <option :value="option.value" v-for="(option, index) in emailGuideData" :key="index"> {{ option.text === '---' ? option.text : $t(option.text) }}{{ option.alphabet }} </option>
                    </b-form-select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label class="pr-2">{{ $t('dashboard-guide-mail.label-sms') }}</label>
                  </td>
                  <td>
                    <b-form-select :disabled="!sms_status" class="w-100" v-model="selectedSmsGuideData" :options="smsGuideData" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="sendGuideMail" class=" pl-3 pr-4 border-right">
            <span>{{ $t('dashboard-guide-mail.instruction-send-guide-mail-for-reservation') }} <br />{{ $t('dashboard-guide-mail.instruction-send-guide-mail-for-reservation-immediately') }} </span>
            <br />
            <button v-on:click="sendGuideMail" class="p-2 mt-2 mb-2 font-weight-bold border-0 btn btn-warning rounded" onclick="">
              <i class="far fa-envelope"></i>
              {{ $t('dashboard-guide-mail.send-guide-mail') }}
            </button>
            <br />
            <span
              >{{ $t('dashboard-guide-mail.complete-send-guide-mail') }}
              {{ displayOperationLogs('send_guide_mail') }}
            </span>
          </div>
          <div id="registrationStopGuideMail" class="w-25 pl-3 pr-4 border-right">
            <span>{{ $t('dashboard-guide-mail.instruction-stop-send-guide-mail-for-reservation') }}</span>
            <br />
            <button v-on:click="registrationStopGuideMail" class="p-2 mt-2 mb-2 font-weight-bold text-white border-0 btn add-denied-reservation-btn rounded">
              {{ $t('dashboard-guide-mail.register-stop-guide-mail') }}
            </button>
            <br />
            <span>{{ $t('dashboard-guide-mail.complete-registration') }} {{ displayOperationLogs('registration_stop_guide_mail') }}</span>
          </div>
          <div id="removeDeniedReservation" class=" pl-3 pre-wrap">
            <span>{{ $t('dashboard-guide-mail.instruction-cancel-stop-send-guide-mail-for-reservation') }}</span>
            <br />
            <button v-on:click="restartSendGuideMail" class="p-2 mt-2 mb-2 font-weight-bold border-0 btn delete-denied-reservation-btn rounded">
              {{ $t('dashboard-guide-mail.deregister') }}
            </button>
            <br />
            <span>{{ $t('dashboard-guide-mail.complete-deregistration') }} {{ displayOperationLogs('restart_send_guide_mail') }}</span>
          </div>
        </div>
        <div class="guide-mail-description pl-2 mt-4">
          {{ $t('dashboard-guide-mail.message') }}
        </div>
      </div>
    </template>

    <!-- Main list -->
    <div class="mt-1 overflow-auto">
      <div class="d-flex py-2 justify-content-between">
        <h5 class="mb-0" style="line-height: 38px">
          {{ $t('dashboard-list.table-title') }}
          <span class="ml-5">TOTAL | {{ guest_logs.total }} {{ $t('dashboard-common.records') }}</span>
        </h5>
        <button v-if="hasAccess('list.pre_check_in.download')" type="button" class="btn btn-primary btn-block" @click="exportExcel">
          <i class="fas fa-download"></i>
          {{ $t('dashboard-common.word-download') }}
        </button>
      </div>
      <table id="list-table" class="table table-bordered table-sm hoverable">
        <thead class="sticky-top">
          <tr>
            <th style="text-align:center;">
              <b-form-checkbox @change="toggleAllGuideMailCheck()" v-model="toggleGuideMailCheck" :disabled="guest_logs.data.length === 0" plain>
                {{ '▼' }}
              </b-form-checkbox>
            </th>
            <th>{{ $t('dashboard-list.reservation-id') }}</th>
            <th>{{ $t('dashboard-list.email') }}</th>
            <th>{{ $t('dashboard-list.check-in-date') }}</th>
            <th>{{ $t('dashboard-list.check-out-date') }}</th>
            <th>{{ $t('dashboard-list.name') }}</th>
            <th>{{ $t('dashboard-list.name_kana') }}</th>
            <th>{{ $t('common.word-phone') }}</th>
            <th>{{ $t('dashboard-common.word-status') }}</th>
            <th>{{ $t('dashboard-list.pre-check-in-registered-date') }}</th>
            <th>{{ $t('dashboard-list.receipt_address') }}</th>
            <th>{{ $t('dashboard-list.passport-picture') }}</th>
            <th>{{ $t('dashboard-list.face-picture') }}</th>
            <!-- <th>{{ $t("dashboard-common.word-confirmation") }}</th> -->
            <th>{{ $t('dashboard-common.word-details') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(guest, index) in guest_logs.data" :key="`vl${index}`">
            <td class="text-center">
              <b-form-checkbox v-if="displayGuideMailCheck(guest)" v-model="guest.guide_mail_check" @change="changedGuideMailCheck" class="mr-0 text-center opacity-50" inline plain />
            </td>
            <td>{{ guest.reservation_id || '--' }}</td>
            <td>
              <span>{{ checkEmailExists(guest) }}</span>
            </td>
            <td>{{ formatDate(guest.check_in_date) || '--' }}</td>
            <td>{{ formatDate(guest.check_out_date) }}</td>
            <td>{{ guest.name || '--' }}</td>
            <td>{{ guest.name_kana || '--' }}</td>
            <td>{{ guest.phone_number || '--' }}</td>
            <td>
              {{ $t(`dashboard-common.status-${guest.status.toLocaleLowerCase()}`) }}
            </td>
            <td>
              <span v-if="guest.log_id">
                {{ formatDate(guest.facility_visitor_log.created_at) }}
              </span>
              <i v-else class="fas fa-minus" />
            </td>
            <td>
              <span v-if="guest.receipt_address == '1'">
                {{ $t(`dashboard-common.receipt-address-guest`) }}
              </span>
              <span v-else-if="guest.receipt_address == '2'">
                {{ $t(`dashboard-common.receipt-address-company`) }}
              </span>
              <i v-else class="fas fa-minus" />
            </td>
            <td>
              <i v-if="guest.has_passport_picture == 'yes'" class="fas fa-circle" />
              <i v-else class="fas fa-minus" />
            </td>
            <td>
              <i v-if="guest.has_face_picture == 'yes'" class="fas fa-circle" />
              <i v-else class="fas fa-minus" />
            </td>
            <td>
              <button v-if="hasAccess('list.details_pop_up.view')" :disabled="!guest.has_log" class="btn btn-sm btn-primary btn-action" @click="() => showDetails(index)">
                {{ $t('dashboard-common.word-details') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination -->
    <b-pagination :value="list_pagination.page" :total-rows="guest_logs.total" :per-page="list_pagination.per_page" first-number @page-click="pageClick" />

    <!-- View details modal -->
    <b-modal id="modal-guest-details" size="xl" hide-footer @hide="updateListWhenItemChanged">
      <template v-slot:modal-header>
        <div class="left-slot">{{ $t('dashboard-common.word-details') }}</div>
        <div class="center-slot"></div>
        <div class="right-slot">
          <button type="button" class="close" :disabled="loading || loading_item" @click="() => $bvModal.hide('modal-guest-details')">
            ×
          </button>
          <button type="button" title="Next record" class="control arrows text-primary" :disabled="!hasNextDetails || loading || loading_item" @click="nextDetails">
            <i class="fas fa-caret-down"></i>
          </button>
          <button type="button" title="Previous record" class="control arrows text-primary" :disabled="!hasPrevDetails || loading || loading_item" @click="prevDetails">
            <i class="fas fa-caret-up"></i>
          </button>
          <b-form-checkbox class="mr-3" v-model="current_detail_confirmed" :disabled="loading || loading_item" @change="toggleConfirmStatusLog">
            <span class="pl-1">
              {{ $t('dashboard-common.word-confirm') }}
            </span>
          </b-form-checkbox>
        </div>
      </template>
      <div class="row">
        <div class="row-left col-lg-6">
          <!-- Basic Information -->
          <div class="basic col-12  mb-3">
            <h5 class="text-primary">
              <i class="fas fa-address-card" />
              {{ $t('dashboard-list.basic-information') }}
            </h5>
            <div class="ghost-fill border rounded-bottom px-2 py-1">
              <table class="w-100 table table-sm hoverable mb-0">
                <tr>
                  <td>{{ $t('dashboard-common.word-status') }}</td>
                  <td>
                    {{ getStatusText(current_details.booking_status) }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('dashboard-list.reservation-id') }}</td>
                  <td>{{ current_details.booking.pms_reservation_no }}</td>
                </tr>
                <tr>
                  <td>{{ $t('dashboard-list.check-in-date') }}</td>
                  <td>{{ current_details.booking.checkin_date }}</td>
                </tr>
                <tr>
                  <td>{{ $t('dashboard-list.check-out-date') }}</td>
                  <td>{{ current_details.booking.checkout_date }}</td>
                </tr>
                <template v-for="(detail, index) in current_details.basic">
                  <tr v-if="personal_info.includes(detail.question.system_name) && hasAccess('list.personal_information.view')" :key="`basic_${index}`">
                    <td>
                      {{ $t(`system-questions.${detail.question.system_name}`) }}
                    </td>
                    <td v-if="detail.question.system_name == 'nationality'">
                      <div class="constrainer">
                        {{ translateAnswer(detail) }}
                      </div>
                    </td>
                    <td v-else>{{ detail.answer }}</td>
                  </tr>
                  <!-- email無しで予約を作成後に事前登録した際、Email欄が2つ表示されてしまう現象を防ぐ -->
                  <tr v-else-if="!(detail.question.system_name == 'email' && !detail.answer)" :key="`i_${index}`">
                    <td>
                      {{ $t(`system-questions.${detail.question.system_name}`) }}
                    </td>
                    <td v-if="detail.question.system_name == 'nationality'">
                      <div class="constrainer">
                        {{ translateAnswer(detail) }}
                      </div>
                    </td>
                    <td v-else-if="detail.question.system_name == 'face_recognition'">
                      <div class="constrainer"></div>
                    </td>
                    <td v-else>
                      <div class="constrainer">
                        {{ translateAnswer(detail) }}
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
          <!-- Other Information -->
          <div class="other col-12 mb-3">
            <h5 class="text-primary">
              <i class="fas fa-notes-medical" />
              {{ $t('dashboard-list.other-information') }}
            </h5>
            <div class="ghost-fill border rounded-bottom px-2 py-1">
              <table class="w-100 table table-sm hoverable mb-0">
                <template v-if="Array.isArray(current_details.other) && current_details.other.some((detail) => detail.question.section !== 'other_info' && translateAnswer(detail).length > 0)">
                  <tr v-for="(detail, index) in current_details.other" :key="`other_${index}`" v-if="detail.question.section != 'other_info'">
                    <td>{{ detail.question.label }}</td>
                    <td>{{ translateAnswer(detail) }}</td>
                  </tr>
                </template>
                <div v-else class="unavailable">
                  <i class="icon fas fa-ban text-muted" />
                  <div class="description text-muted">
                    {{ $t('common.word-unavailable') }}
                  </div>
                </div>
              </table>
            </div>
          </div>
        </div>
        <div class="row-right col-lg-6">
          <!-- QRcode -->
          <div
            class="col-12  mb-4
          "
          >
            <div class="d-flex justify-content-between align-content-center">
              <h5 class="text-primary">
                <i class="fas fa-qrcode" />
                {{ $t('dashboard-common.word-qr') }}
              </h5>
              <div class="passport-buttons" v-show="current_details.booking.qr_file_path && showQrButton">
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showPhoto(current_details.booking.qr_file_path)"
                  :disabled="downloadable.loading"
                  v-b-tooltip.hover
                  :title="$t('dashboard-common.word-expansion')"
                >
                  <i class="fas fa-eye icon" />
                </button>
                <button class="btn btn-outline-primary btn-sm" @click="downloadFile('qr')" :disabled="downloadable.loading" v-b-tooltip.hover :title="$t('dashboard-common.word-download')">
                  <i class="fas fa-download icon" />
                </button>
                <button class="btn btn-outline-primary btn-sm" @click="print" :disabled="downloadable.loading" v-b-tooltip.hover :title="$t('dashboard-common.word-print')">
                  <i class="fas fa-print icon" />
                </button>

                <button class="btn btn-outline-primary btn-sm" @click="openConfirmModal" v-b-tooltip.hover :title="$t('dashboard-common.word-resend')">
                  <i class="fas fa-paper-plane icon" />
                </button>
              </div>
            </div>
            <div v-if="current_details.booking.qr_file_path" class="ghost-fill border rounded-bottom px-2 py-1 mt-2" @click="showPhoto(current_details.booking.qr_file_path)">
              <div class="text-center py-1">
                <div class="qr-image-preview" id="qr-image-preview-id">
                  <load-image
                    :url="current_details.booking.qr_file_path"
                    eventNamePrefix="qr"
                    @loaded="
                      (v) => {
                        downloadable.source = v;
                      }
                    "
                    @qr-image-loaded="handleQrImageLoaded"
                    @qr-no-image="handleQrNoImage"
                    class="image-preview"
                  />
                </div>
              </div>
            </div>
            <div v-else class="ghost-fill border rounded-bottom px-2 py-1">
              <div class="unavailable">
                <i class="icon fas fa-ban text-muted" />
                <div class="description text-muted">
                  {{ $t('common.word-unavailable') }}
                </div>
              </div>
            </div>

            <!-- Confirmation Modal -->
            <div v-if="isModalVisible" class="modal-resend"></div>
          </div>
          <!-- Passport -->
          <div class="col-12 mt-5 mb-2">
            <div class="d-flex justify-content-between align-content-center">
              <h5 class="text-primary">
                <i class="fas fa-passport" />
                {{ $t('dashboard-common.word-passport') }}
              </h5>
              <div class="passport-buttons" v-show="current_details.passport_image && showPassportButton">
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showPhoto(current_details.passport_image)"
                  :disabled="downloadable.loading"
                  v-b-tooltip.hover
                  :title="$t('dashboard-common.word-view')"
                >
                  <i class="fas fa-eye icon" />
                  <!-- <span class="label ml-1">{{
                  $t("dashboard-common.word-view")
                }}</span> -->
                </button>
                <button class="btn btn-outline-primary btn-sm" @click="downloadFile('passport')" :disabled="downloadable.loading" v-b-tooltip.hover :title="$t('dashboard-common.word-download')">
                  <i class="fas fa-download icon" />
                  <!-- <span class="label ml-1">{{
                  $t("dashboard-common.word-download")
                }}</span> -->
                </button>
                <button class="btn btn-outline-primary btn-sm" @click="confirmDeletePassportPhoto" :disabled="downloadable.loading" v-b-tooltip.hover :title="$t('dashboard-common.word-delete')">
                  <i class="fas fa-trash icon" />
                  <!-- <span class="label ml-1">{{
                  $t("dashboard-common.word-delete")
                }}</span> -->
                </button>
              </div>
            </div>
            <div v-if="current_details.passport_image" class="ghost-fill border rounded-bottom px-2 py-1 mt-1" @click="showPhoto(current_details.passport_image)">
              <div class="text-center py-1">
                <load-image
                  :url="current_details.passport_image"
                  eventNamePrefix="passport"
                  @loaded="
                    (v) => {
                      downloadable.source = v;
                    }
                  "
                  @passport-image-loaded="handlePassportImageLoaded"
                  @passport-no-image="handlePassportNoImage"
                  class="image-preview"
                />
              </div>
            </div>
            <div v-else class="ghost-fill border rounded-bottom px-2 py-1">
              <div class="unavailable">
                <i class="icon fas fa-ban text-muted" />
                <div class="description text-muted">
                  {{ $t('common.word-unavailable') }}
                </div>
              </div>
            </div>
          </div>

          <!-- Signature -->
          <div class="col-12 mt-5 mb-3">
            <h5 class="text-primary">
              <i class="fas fa-file-signature" />
              {{ $t('dashboard-common.word-signature') }}
            </h5>
            <div v-if="current_details.signature_image" class="ghost-fill border rounded-bottom px-2 py-1 mt-1">
              <div class="text-center py-1">
                <load-image :url="current_details.signature_image" class="image-preview" />
              </div>
            </div>
            <div v-else class="ghost-fill border rounded-bottom px-2 py-1">
              <div class="unavailable">
                <i class="icon fas fa-ban text-muted" />
                <div class="description text-muted">
                  {{ $t('common.word-unavailable') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="loading_item" class="inner-overlay">
          <loading active :is-full-page="false" loader="bars" color="#5468a1" />
        </div>
      </div>
      <companion-details
        v-for="(data, index) in current_details.companions"
        :key="`c_${index}`"
        :facility_id="facility.id"
        :data="data"
        :title="`${$t('check-in-form.word-companion')} #${index + 1}`"
        :last="index + 1 == current_details.companions.length"
        :personal_info="personal_info"
        :nationalitynames="nationalityNames"
        :prefecturenames="prefectureNames"
        :genders="genders"
        :occupations="occupations"
        :yesno="yesno"
        @showPhoto="showPhoto"
        @showDetails="showDetails"
      />
    </b-modal>

    <!-- View Photo Modal -->
    <b-modal
      id="modal-view-photo"
      :size="current_photo_src.includes('qr') ? 'l' : 'xl'"
      hide-footer
      :title="current_photo_src.includes('qr') ? $t(`dashboard-common.word-qr`) : $t(`dashboard-common.word-passport`)"
    >
      <div class="image-full">
        <load-image :url="current_photo_src" class="image-full" />
      </div>
    </b-modal>
    <!-- View Resend Modal -->
    <b-modal
      v-model="confirmModal.show"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :title="$t('dashboard-common.word-qr')"
      :header-class="{ 'modal-loading': confirmModal.loading }"
    >
      <p>{{ $t('dashboard-common.resend-confirm') }}</p>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="resend" :disabled="confirmModal.loading">
          {{ $t('dashboard-common.resend-good') }}
        </b-button>
        <b-button variant="secondary" @click="closeConfirmModal">{{ $t('dashboard-common.resend-cancelled') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss">
#modal-guest-details .image-preview {
  img {
    width: 100%;
    height: 400px !important;
    object-fit: contain;
    background-color: white;
  }
  cursor: pointer;
}

#modal-guest-details .qr-image-preview {
  img {
    width: 100%;
    height: 400px !important;
    object-fit: contain;
    background-color: white;
  }
  cursor: pointer;
}
#modal-view-photo .image-full {
  img {
    width: 100% !important;
  }
}

#modal-view-qr .qr-image-full {
  img {
    width: 50% !important;
  }
}

.qr-image-full {
  display: flex;
  justify-content: center;
}

.row-left {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.row-right {
  display: flex;
  flex-wrap: wrap;
}
</style>

<style lang="scss" scoped>
.btn-block {
  display: inline-block !important;
}
.btn-action {
  width: 70px;
}
#modal-guest-details___BV_modal_header_ {
  border-bottom-style: none !important;
}
.table.hoverable {
  tr:first-child td {
    border-top: none;
  }
}
.right-slot {
  * {
    float: right;
  }
  button {
    outline: none;
    background-color: transparent;
  }
  .control {
    border: none;
    &.arrows {
      font-size: 20px;
      transform: scale(1.5);
    }
    opacity: 0.8;
    &:hover:not([disabled]) {
      opacity: 1;
    }
  }
}
.ghost-fill {
  height: calc(100% - 40px);
  min-height: 200px;
}
.passport-buttons {
  button {
    width: 50px;
    margin-left: 5px;
    margin-top: -3px;
  }
  float: right;
}
.unavailable {
  pointer-events: none;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .icon {
    font-size: 36px;
  }
}
.constrainer {
  width: 100%;
  overflow-x: auto;
}
.field-label {
  margin-right: 8px;
  white-space: nowrap;
}

@media only screen and (max-width: 1300px) {
  #nomination-search {
    .field-label {
      display: none !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #list-table {
    th:nth-child(4),
    th:nth-child(5),
    td:nth-child(4),
    td:nth-child(5) {
      display: none !important;
    }
  }
}
@media only screen and (max-width: 400px) {
  .passport-buttons {
    button {
      min-width: auto !important;
      .icon {
        width: 32px;
      }
      .label {
        display: none;
      }
    }
  }
}
.search-condition-row-3 {
  display: none;
}
@media only screen and (max-width: 1048px) {
  .search-condition-row-2 {
    justify-content: center;
    & > div:nth-child(2) {
      display: none;
    }
  }
  .search-condition-row-3,
  .search-condition-row-3 td {
    display: block !important;
  }
}
.guide-mail-main-title {
  font-size: 15px;
  font-weight: bold;
}
.guide-mail-description {
  text-align: center;
}
</style>

<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';

import guestsApi from '@/api/guests';
import bookingsApi from '@/api/bookings';
import logsApi from '@/api/logs';
import cmsApi from '@/api/cms';
import guideMailApi from '@/api/guide-mail';
import settingsApi from '@/api/settings';
import emailsApi from '@/api/emails';

import nationalities from '@/utils/mockups/nationalities';
import prefectures from '@/utils/mockups/prefectures';
import genders from '@/utils/mockups/genders';
import occupations from '@/utils/mockups/occupations';
import yesno from '@/utils/mockups/yes-no';
import receipt_addresses from '@/utils/mockups/receipt_addresses';

import loadImage from '../components/loadImage';

import uac from '@/mixins/uac';
import dialogs from '@/mixins/dialogs';
import translation from '@/mixins/translation';

import CompanionDetails from '../components/CompanionDetails';

export default {
  components: { CompanionDetails, loadImage },
  mixins: [dialogs, uac, translation],
  async beforeMount() {
    const response = await settingsApi.getNotifications(this.facility.id);
    if (response.total > 0) {
      const notification_frequency_settings = response.data[0];
      this.sms_status = notification_frequency_settings.value.sms_status;
    }
    const settingsName = `list_filter_${this.facility.id}`;
    if (this.$storage.has(settingsName)) {
      const settings = this.$storage.get(settingsName);
      this.filters = { ...this.filters, ...settings };
    }
    const params = {
      facility_id: this.facility.id,
    };
    const result = await guideMailApi.fetchOperationLogs(params);
    this.operationLogs['send_guide_mail'] = result.data.data.sendGuideMailOperationLog;
    this.operationLogs['registration_stop_guide_mail'] = result.data.data.stopGuideMailOperationLog;
    this.operationLogs['restart_send_guide_mail'] = result.data.data.stopGuideMailOperationLog;
  },
  async mounted() {
    this.updateActiveMenu('list');
    this.syncStoredFilters();
    this.setTodayDate();
    this.buildNationalityNames();
    this.buildPrefectureNames();
    this.occupations = occupations;
    this.yesno = yesno;
    this.genders = genders;

    // emailのガイドメールの選択肢を取得する
    this.emailGuideData = []; // 初期化
    const eMailGuideResponse = await cmsApi.getEmailGuide(this.facility.id);
    for (let i = 0; i < eMailGuideResponse.length; i++) {
      // データベースにはnameがpre-email, pre-email-B, pre-email-C...という名前で保存されている
      // これを'', 'B', 'C'...という名前に変換する
      const emailGuideDatum = eMailGuideResponse[i];
      const alphabet = emailGuideDatum.name.replace('pre-email', '').replace('-', '');
        // valueが空白でない場合のみ配列に追加
        if (emailGuideDatum.value && emailGuideDatum.value.replace(/<[^>]*>?/gm, '').trim() !== '') {
            // ''は0, 'B'は1, 'C'は2...というように変換する
            const arrayIndex = alphabet ? alphabet.codePointAt() - 65 : 0;
            const textSelect = `dashboard-guide-mail.guide-mail`;
            const data = {
                text: textSelect,
                alphabet: String.fromCharCode(65 + arrayIndex),
                value: arrayIndex,
            };
        this.emailGuideData[arrayIndex] = data;
        }
      
    }
    // 空の要素を削除する
    this.emailGuideData = this.emailGuideData.filter((data) => data);
    this.emailGuideData.unshift({ text: '---', value: '' });

    // SMSのガイドメールの選択肢を取得する
    // SMS通知がOFFの場合は取得しない→削除（APIが動作しない時があるため）
      this.smsGuideData = []; // 初期化
      const smsGuideResponse = await cmsApi.getSMS(this.facility.id);
      for (let i = 0; i < smsGuideResponse.length; i++) {
        // データベースにはnameがmessage-sms, message-sms-B, message-sms-C...という名前で保存されている
        // これを'', 'B', 'C'...という名前に変換する
        const smsGuideDatum = smsGuideResponse[i];
        const alphabet = smsGuideDatum.name.replace('message-sms', '').replace('-', '');
        //valueがある場合のみ配列に追加
        if (smsGuideDatum.value) {
            // ''は0, 'B'は1, 'C'は2...というように変換する
            const arrayIndex = alphabet ? alphabet.codePointAt() - 65 : 0;
            const data = {
                text: 'SMS ' + String.fromCharCode(65 + arrayIndex),
                value: arrayIndex,
            };
        this.smsGuideData[arrayIndex] = data;
        }
      }
      // 空の要素を削除する
      this.smsGuideData = this.smsGuideData.filter((data) => data);
      this.smsGuideData.unshift({ text: '---', value: '' });
    

    this.goPage();
  },
  data() {
    return {
      current_details: {
        booking: {
          booking_status_id: '',
          checkin_date: '',
          checkin_time: '',
          checkout_date: '',
          checkout_time: '',
          guest_contact_no: '',
          pms_reservation_no: '',
          room_number: '',
        },
        questions: [],
        booking_status: {},
      },
      current_photo_src: '',
      current_detail_confirmed: false,
      item_modified: false,
      date_format: { year: 'numeric', month: '2-digit', day: '2-digit' },
      downloadable: {
        filename: 'downloadable',
        source: '',
        loading: false,
      },
      loading: false,
      loading_page: false,
      loading_item: false,
      list_pagination: {
        paginate: true,
        per_page: 100,
        page: 1,
      },
      guest_index: 0,
      guest_logs: {
        data: [],
        total: 0,
      },
      filters: {
        status: '',
      },
      nationalityNames: {},
      prefectureNames: {},
      genders: {},
      occupations: {},
      yesno: {},
      statusNameToObjects: {},
      statusIdToNames: {},
      personal_info: ['full_name', 'full_name_for_katakana', 'address', 'age', 'dob', 'gov_id_number', 'gov_id'],
      non_list: ['gov_id', 'signature'],
      toggleGuideMailCheck: false,
      filtersChanged: false,
      emailGuideData: [{ text: '---', value: '' }],
      selectedEmailGuideData: '',
      smsGuideData: [{ text: '---', value: '' }],
      selectedSmsGuideData: '',
      checkedReservationNumbers: [],
      checkedSmsReservationNumbers: [],
      checkedEmailReservationNumbers: [],
      guideMailAvailableReservationsLength: 0,
      sms_status: false,
      sms_priority: false,
      operationLogs: {
        send_guide_mail: null,
        registration_stop_guide_mail: null,
        restart_send_guide_mail: null,
      },
      filtersChanged: false,
      isModalVisible: false,
      confirmModal: {
        show: false,
        loading: false,
      },
      showPassportButton: true,
      showQrButton: true,
      now: new Date(),
    };
  },
  computed: {
    ...mapGetters('dashboard', ['facility']),
    hasPrevDetails() {
      const logLength = this.guest_logs.data.length;
      return logLength > 0 && this.guest_index > 0;
    },
    hasNextDetails() {
      const logLength = this.guest_logs.data.length;
      return this.guest_index < logLength - 1;
    },
    isDeletion: function() {
      return this.selecting.mode == 'deleting' && this.selecting.showSelecting;
    },
    currentLogId() {
      const currentLog = this.guest_logs.data[this.guest_index];
      return currentLog ? currentLog.log_id : 0;
    },
    hasFilterSet() {
      return this.filters.status || this.filters.has_face_picture || this.filters.has_passport_picture || this.filters.has_email_exists || this.filters.has_no_email_exists;
    },
  },
  filters: {
    formatDate(date) {
      const match = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(date);
      if (match) return moment.tz(date, 'utc').format('YYYY/MM/DD');
      return '';
    },
  },
  methods: {
    ...mapActions('dashboard', ['updateActiveMenu']),
    buildNationalityNames() {
      const nationalityMap = {};
      for (const i in nationalities.en) {
        const enItem = nationalities.en[i];
        const jaItem = nationalities.ja[i];
        const hasEnDefined = typeof nationalityMap[enItem.CountryCode];
        const hasJaDefined = typeof nationalityMap[jaItem.CountryCode];
        if (hasEnDefined == 'undefined') {
          nationalityMap[enItem.CountryCode] = { en: null, ja: null };
        }
        if (hasJaDefined == 'undefined') {
          nationalityMap[jaItem.CountryCode] = { en: null, ja: null };
        }
        nationalityMap[enItem.CountryCode].en = enItem.Nationality;
        nationalityMap[jaItem.CountryCode].ja = jaItem.Nationality;
      }
      this.nationalityNames = nationalityMap;
    },
    buildPrefectureNames() {
      const prefectureList = {};
      for (const prefecture of prefectures) {
        const code = parseInt(prefecture.code.replace('JP-', ''));
        prefectureList[code] = {
          en: prefecture.en,
          ja: prefecture.ja,
        };
      }
      this.prefectureNames = prefectureList;
    },

    async showDetails(index) {
      //guest_index
      const currentLog = this.guest_logs.data[index];
      if (!currentLog) return;
      if (!currentLog.log_id) return;
      this.guest_index = index;
      try {
        const details = await guestsApi.getOne({
          log_id: this.currentLogId,
          facility_id: this.facility.id,
        });

        details.booking_status = {
          ...currentLog.booking_status,
          log_id: currentLog.status_log_id,
        };
        this.current_details = details;
        this.$bvModal.show('modal-guest-details');
        this.$nextTick(this.updateSeenStatus);
      } catch (e) {
        console.error(e.message);
        this.$toast.error($t('common.error-generic'), { position: 'top' });
      }
    },
    showPhoto(data) {
      this.current_photo_src = data;
      this.$bvModal.show('modal-view-photo');
    },
    handlePassportImageLoaded() {
      this.showPassportButton = true;
    },
    handlePassportNoImage() {
      this.showPassportButton = false;
    },
    handleQrImageLoaded() {
      this.showQrButton = true;
    },
    handleQrNoImage() {
      this.showQrButton = false;
    },
    downloadFile(type) {
      try {
        const rsv_no = this.current_details.booking.pms_reservation_no;
        let source;

        switch (type) {
          case 'passport':
            source = this.current_details.passport_image;
            break;
          case 'qr':
            source = this.current_details.booking.qr_file_path;
            break;
          default:
            throw new Error('Invalid type specified');
        }

        const baseurl = this.$http.defaults.baseURL;
        const link = `${baseurl}/log/download-image?path=${source}&rsv_no=${rsv_no}`;
        const anchor = document.createElement('a');
        anchor.setAttribute('href', link);
        anchor.setAttribute('download', link);
        anchor.setAttribute('style', 'display: none');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t(`common.error-generic`), {
          position: 'top',
        });
      }
    },

    confirmDeletePassportPhoto() {
      this.confirm(this.$t(`dashboard-list.confirm-passport-delete`), {
        yes: this.deletePassportPhoto,
      });
    },
    async deletePassportPhoto() {
      try {
        const params = { type: 'main', facility_id: this.facility.id };
        await logsApi.deletePassportPhoto(this.current_details.log_id, params);
        await this.showDetails(this.guest_index);
        this.item_modified = true;
        this.$toast.success(this.$t(`dashboard-list.message-passport-deleted`), {
          position: 'top',
        });
      } catch (e) {
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },
    checkEnableSendGuideMail() {
      // 予約が選択されていない場合は無効
        if (this.checkedReservationNumbers.length === 0) return false;

        // 停止されているガイドメールを持つ予約があるかを確認
        const checkedStopGuideMailReservations = this.guest_logs.data.filter((guest) => guest.stop_send_guide_mail === 1 && guest.guide_mail_check === true);
        if (checkedStopGuideMailReservations.length !== 0) return false;

        if (this.selectedEmailGuideData === '' && this.selectedSmsGuideData === '') return false;
        if (this.checkedSmsReservationNumbers.length !== 0 && this.selectedSmsGuideData === '') return false;
        return true;
    },
    checkEmailExists(guest) {
      if (guest.stop_send_guide_mail === 1) return '×';
      const emailNotExistsPattern = ['null', '', '0', 'undefined'];
      const emailExists = !emailNotExistsPattern.includes(guest.email);
      const smsStatus = this.sms_status ? 'SMS' : '--';
      //TODO: eMail有無表示情報の改修で削除された予約のemailsmsの保持・表示対応を行う
      return guest.past_send_method ? (guest.past_send_method === 'SMS' ? smsStatus : guest.past_send_method) : emailExists ? 'eMail' : smsStatus;
    },
    checkeSmsBookingExists() {
      const smsBookings = this.guest_logs.data.filter((guest) => this.checkEmailExists(guest) === 'SMS');
      const result = smsBookings.length !== 0;
      if (result === false) this.selectedSmsGuideData = '';
      return result;
    },
    displayOperationLogs(type) {
      if (this.operationLogs[type] === null) return '';
      const message = this.formatDate(this.operationLogs[type]['updated_at']) + ' ' + this.operationLogs[type]['value']['username'];
      return message;
    },
    displayControlGuideMailForm() {
      // return true;
      const checkedReservations = this.guest_logs.data.filter((guest) => guest.guide_mail_check === true);
      return checkedReservations.length !== 0;
    },
    //チェックボックスの表示判定
    displayGuideMailCheck(guest) {
      //チェックアウト日を過ぎた予約はチェックボックスを表示しない
      if (new Date(guest.check_out_date) < this.now) return false;
      //eMail欄が--の場合はチェックボックスを表示しない
      if (this.checkEmailExists(guest) === '--') return false;

      switch (guest.status.toLocaleLowerCase()) {
        case 'input':
          return false;
          break;
        case 'seen':
          return false;
          break;
        case 'confirmed':
          return false;
          break;
        default:
          return true;
      }
    },
    changedGuideMailCheck() {
      this.checkedReservationNumbers = this.guest_logs.data.filter((data) => data.guide_mail_check === true).map((data) => data.reservation_id);
      this.checkedEmailReservationNumbers = this.guest_logs.data.filter((data) => data.guide_mail_check === true && this.checkEmailExists(data) === 'eMail').map((data) => data.reservation_id);
      this.checkedSmsReservationNumbers = this.guest_logs.data.filter((data) => data.guide_mail_check === true && this.checkEmailExists(data) === 'SMS').map((data) => data.reservation_id);
      // すべてチェック済みかどうかを調べる
      // 結果に応じて一番上のチェックボックスの状態を変更する
      const result = this.checkedReservationNumbers.length !== 0 && this.checkedReservationNumbers.length === this.guideMailAvailableReservationsLength;
      this.toggleGuideMailCheck = result;
    },
    toggleAllGuideMailCheck() {
      this.checkedReservationNumbers = [];
      this.checkedEmailReservationNumbers = [];
      this.checkedSmsReservationNumbers = [];
      if (this.toggleGuideMailCheck === true) {
        // すべてチェックを入れる
        this.guest_logs.data.forEach((guest) => {
          if (this.displayGuideMailCheck(guest)) {
            guest.guide_mail_check = this.displayGuideMailCheck(guest);
          }
        });
      } else {
        // チェックをすべて外す
        this.guest_logs.data.forEach((guest) => {
          guest.guide_mail_check = false;
        });
      }
      this.changedGuideMailCheck();
    },
    async sendGuideMail() {
      try {
        const isEnable = this.checkEnableSendGuideMail();
        if (isEnable === false) {
          alert(this.$t(`dashboard-guide-mail.alert-disable-send-guide-mail`));
          return false;
        }
        const params = {
          facility_id: this.facility.id,
          reservationNumbers: this.checkedReservationNumbers,
          selectedEmailGuideData: this.selectedEmailGuideData,
          selectedSmsGuideData: this.selectedSmsGuideData,
        };
        const result = await guideMailApi.sendGuideMail(params);
        this.operationLogs['send_guide_mail'] = result.data.data.sendGuideMailOperationLog;
        this.$toast.success(this.$t(`dashboard-guide-mail.send-guide-mail-succeed`), {
          position: 'top',
          duration: 5000,
          onDismiss: () => {
            this.toggleGuideMailCheck = false;
            this.toggleAllGuideMailCheck();
          },
        });
      } catch (e) {
        this.$toast.error(this.$t(`dashboard-common.failed-send`), {
          position: 'top',
        });
      }
    },
    async registrationStopGuideMail() {
      try {
        if (this.checkedReservationNumbers.length === 0) {
          alert(this.$t(`dashboard-guide-mail.alert-please-select-bookings`));
          return false;
        }
        const checkedStopGuideMailReservations = this.guest_logs.data.filter((guest) => guest.stop_send_guide_mail === 1 && guest.guide_mail_check === true);
        if (checkedStopGuideMailReservations.length !== 0) {
          alert(this.$t(`dashboard-guide-mail.alert-please-clear-select-stopped-guide-mail`));
          return false;
        }
        const params = {
          facility_id: this.facility.id,
          reservationNumbers: this.checkedReservationNumbers,
        };
        const result = await guideMailApi.registrationStopGuideMail(params);
        const checkedReservations = this.guest_logs.data.filter((data) => data.guide_mail_check === true);
        checkedReservations.forEach((guest) => {
          guest.stop_send_guide_mail = 1;
        });
        this.toggleGuideMailCheck = false;
        this.toggleAllGuideMailCheck();
        this.operationLogs['registration_stop_guide_mail'] = result.data.data.stopGuideMailOperationLog;
        this.$toast.success(this.$t(`dashboard-guide-mail.complete-registration`), {
          position: 'top',
        });
      } catch (e) {
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },
    async restartSendGuideMail() {
      try {
        if (this.checkedReservationNumbers.length === 0) {
          alert(this.$t(`dashboard-guide-mail.alert-please-select-bookings`));
          return false;
        }
        const checkedStopGuideMailReservations = this.guest_logs.data.filter((guest) => guest.stop_send_guide_mail === 1 && guest.guide_mail_check === true);
        if (checkedStopGuideMailReservations.length === 0) {
          alert(this.$t(`dashboard-guide-mail.alert-please-select-stopped-guide-mail`));
          return false;
        }
        const params = {
          facility_id: this.facility.id,
          reservationNumbers: this.checkedReservationNumbers,
        };
        const result = await guideMailApi.restartSendGuideMail(params);
        const checkedReservations = this.guest_logs.data.filter((data) => data.guide_mail_check === true);
        checkedReservations.forEach((guest) => {
          guest.stop_send_guide_mail = 0;
        });
        this.toggleGuideMailCheck = false;
        this.toggleAllGuideMailCheck();
        this.operationLogs['restart_send_guide_mail'] = result.data.data.restartGuideMailOperationLog;
        this.$toast.success(this.$t(`dashboard-guide-mail.complete-deregistration`), {
          position: 'top',
        });
      } catch (e) {
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },
    async removeDeniedReservation() {
      try {
        this.$toast.success(this.$t(`dashboard-guide-mail.restart-guide-mail-succeed`), {
          position: 'top',
        });
      } catch (e) {
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },
    expansion(data) {
      this.current_qr_src = data;
      this.$bvModal.show('modal-view-qr');
    },
    print() {
      const originalImage = document.getElementById('qr-image-preview-id').querySelector('img');
      const clone = originalImage.cloneNode(true);
      clone.style.width = '200px';
      clone.style.height = '200px';
      const iframe = document.createElement('iframe');
      iframe.srcdoc = '<!DOCTYPE html>';
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      iframe.onload = () => {
        iframe.contentDocument.body.appendChild(clone);
        let reservationNumber = this.current_details.booking.pms_reservation_no;
        if (reservationNumber.startsWith('PMS')) {
          reservationNumber = reservationNumber.replace('PMS', '');
        }
        const reservationNumberDiv = iframe.contentDocument.createElement('div');
        reservationNumberDiv.textContent = '予約番号: ' + reservationNumber;
        reservationNumberDiv.style.textAlign = 'left';
        reservationNumberDiv.style.marginTop = '10px';
        iframe.contentDocument.body.appendChild(reservationNumberDiv);
        setTimeout(() => {
          iframe.contentWindow.print();
          iframe.contentWindow.addEventListener('afterprint', () => {
            iframe.remove();
          });
        }, 100);
      };
    },
    openConfirmModal() {
      this.confirmModal.show = true;
    },
    closeConfirmModal() {
      this.confirmModal.show = false;
      ß;
    },
    async resend() {
      const data = {
        facility_id: this.facility.id,
        pms_code: this.current_details.booking.pms_code,
        pms_reservation_no: this.current_details.booking.pms_reservation_no,
        qr: this.current_details.qrCodeData,
      };
      try {
        await emailsApi.resend(data, false);
        this.$toast.success(this.$t('dashboard-common.success-resend'), {
          position: 'top',
        });
        this.confirmModal.show = false;
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
        });
      }
    },

    async toggleConfirmStatusLog(yes) {
      const guestLog = this.current_details;
      const seen = this.statusNameToObjects.seen.id;
      const confirmed = this.statusNameToObjects.confirmed.id;
      const booking_status_id = yes ? confirmed : seen;
      const statusText = yes ? 'confirmed' : 'seen';
      const statusCode = yes ? 122 : 121;
      this.item_modified = true;
      if (yes) {
        try {
          this.loading_item = true;
          await bookingsApi.createStatusLog(
            {
              reservation_no: guestLog.booking.pms_reservation_no,
              pms_code: guestLog.booking.pms_code,
              booking_status_id,
            },
            true
          );
          this.loading_item = false;
        } catch (e) {
          console.error(e.message);
        }
      } else {
        try {
          this.loading_item = true;
          await bookingsApi.deleteStatusLog(guestLog.booking_status.log_id, true);
          this.loading_item = false;
        } catch (e) {
          console.error(e.message);
        }
      }
      // Update status of current item.
      const updateBookingStatus = {
        ...this.current_details.booking_status,
        name: statusText,
        code: statusCode,
      };
      this.current_details.booking_status = updateBookingStatus;
    },
    getStatusText(booking_status) {
      if (booking_status.name) {
        return this.$t(`dashboard-common.status-${booking_status.name.toLocaleLowerCase()}`);
      }
      return '--';
    },
    translateAnswer(detail) {
      const yesnoQuestions = ['doctor_certificate', 'pass_affection', 'internation_travel', 'domestic_travel', 'coughing'];
      const question = detail.question.system_name;
      const answer = detail.answer.trim();
      const notDisplayQuestions = [
        'doctor_certificate',
        'pass_affection',
        'internation_travel',
        'domestic_travel',
        'coughing',
        'body_temperature',
        'transportation',
        'foreign_travel',
        'close_contact_corona_patient',
      ];

      if (notDisplayQuestions.includes(question)) {
        return '';
      }
      // Gender
      if (question == 'gender') {
        if (answer.length < 1) return '';
        return genders[$i18n.locale][answer];
      }
      // Nationality
      if (question == 'nationality') {
        try {
          if (answer.length < 1) return '';
          const nationalityObj = this.nationalityNames[answer];
          if (typeof nationalityObj == 'undefined') return '';
          if (typeof nationalityObj[this.$i18n.locale] == 'undefined') return '';
          return nationalityObj[this.$i18n.locale];
        } catch (e) {
          console.error(e);
          return 'Atlantis';
        }
      }
      // Occupation
      if (question == 'occupation') {
        if (answer.length < 1) return '';
        if (typeof occupations[$i18n.locale][answer] == 'undefined') return '';
        return occupations[$i18n.locale][answer];
      }
      // Prefecture
      if (question == 'prefecture') {
        if (answer.length < 1) return '';
        if (typeof this.prefectureNames[answer] == 'undefined') return answer;
        return this.prefectureNames[answer][$i18n.locale];
      }
      if (question == 'receipt_address') {
        if (answer.length < 1) return '';
        return receipt_addresses[$i18n.locale][answer];
      }
      return answer;
    },
    async updateSeenStatus() {
      const guestLog = this.current_details;
      const doNotUpdateCodes = [121, 122]; // [seen, confirmed]
      if (!doNotUpdateCodes.includes(Number(guestLog.booking_status.code))) {
        try {
          await bookingsApi.createStatusLog(
            {
              reservation_no: guestLog.booking.pms_reservation_no,
              pms_code: guestLog.booking.pms_code,
              booking_status_id: this.statusNameToObjects.seen.id,
            },
            true
          );
          this.item_modified = true; // refresh list when changed from input to seen.
          this.current_detail_confirmed = guestLog.booking_status.code == 122;
        } catch (e) {
          console.error(e.message);
        }
      } else {
        this.current_detail_confirmed = guestLog.booking_status.code == 122;
      }
    },
    async updateListWhenItemChanged() {
      if (!this.item_modified) return;
      await this.list();
      this.item_modified = false;
    },
    prevDetails() {
      const nextIndex = this.guest_index - 1;
      this.showDetails(nextIndex);
    },
    nextDetails() {
      const nextIndex = this.guest_index + 1;
      this.showDetails(nextIndex);
    },
    toggleDelete() {
      this.selecting.mode = 'deleting';
      this.selecting.showSelecting = !this.selecting.showSelecting;
      this.selecting.selected = [];
      //
      this.deleting.confirming = true;
      this.$refs['modal-delete-confirmation'].hide();
    },
    setTodayDate() {
      const date = new Date();
      const dd = ('0' + date.getDate()).slice(-2);
      const mm = ('0' + (date.getMonth() + 1)).slice(-2);
      const yyyy = date.getFullYear();

      this.filters.from_checkin_date = `${yyyy}-${mm}-${dd}`;
      this.filters.from_checkin_time = `00:00:00`;

      this.filters.to_checkin_date = `${yyyy}-${mm}-${dd}`;
      this.filters.to_checkin_time = `23:59:59`;
    },
    clearFilter() {
      this.$storage.remove('list_filter_' + this.facility.id);
      const clearedFilters = {};
      for (let key in this.filters) clearedFilters[key] = null;
      clearedFilters.status = '';
      this.filters = clearedFilters;
      this.goPage(1);
    },
    async saveFilter() {
      this.$storage.set('list_filter_' + this.facility.id, this.filters);
      this.filtersChanged = false;
      this.$toast.success(this.$t('dashboard-common.alert-filter-saved'), {
        position: 'top',
      });
    },
    syncStoredFilters() {
      const storedFilters = this.$storage.get('list_filter_' + this.facility.id);
      this.filters = { ...this.filters, ...storedFilters };
    },
    async list() {
      let filters = {};
      for (let filter of Object.keys(this.filters)) {
        if (this.filters[filter]) filters[filter] = this.filters[filter];
      }
      const guests = await bookingsApi.get(this.list_pagination, filters);
      this.guest_logs.data = guests.data.data;
      this.list_pagination.per_page = guests.data.per_page;
      this.guest_logs.total = guests.data.total == 0 ? '0' : guests.data.total;
      this.toggleGuideMailCheck = false;
      this.toggleAllGuideMailCheck();
      this.guideMailAvailableReservationsLength = this.guest_logs.data.filter((guest) => this.displayGuideMailCheck(guest)).length;
    },
    async getStatuses() {
      const statusList = await bookingsApi.getStatuses();
      const statusNameToObjects = {};
      const statusIdToNames = {};
      if (Array.isArray(statusList)) {
        for (let status of statusList) {
          statusNameToObjects[status.name.toLocaleLowerCase()] = status;
          statusIdToNames[status.id] = status.name;
        }
      }
      this.statusNameToObjects = statusNameToObjects;
      this.statusIdToNames = statusIdToNames;
    },
    async search() {
      this.list_pagination.page = 1;
      await this.list();
    },
    async goPage(page) {
      this.list_pagination.page = page;
      await this.getStatuses();
      await this.list();
    },
    async exportExcel() {
      const params = {
        facility_id: this.facility.id,
        ...this.filters,
      };
      try {
        const response = await logsApi.downloadExcel(params);
        const file = moment().format('YYYYMMDD');
        let blob = new Blob([response.data], { type: 'application/xls' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${file}_logs.xlsx`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t(`dashboard-common.error-generic`), {
          position: 'top',
          duration: 0,
        });
      }
    },
    updateFilterChange() {
      const latestFilters = JSON.stringify(this.filters);
      let savedFilters = '{}';
      const settingsName = `list_filter_${this.facility.id}`;
      if (this.$storage.has(settingsName)) {
        savedFilters = JSON.stringify(this.$storage.get(settingsName));
      }
      if (latestFilters !== savedFilters) this.filtersChanged = true;
      else this.filtersChanged = false;
      this.goPage(1);
    },
    formatDate(date) {
      return moment.tz(date, 'utc').format('YYYY/MM/DD');
    },
    pageClick(e, page) {
      e.preventDefault();
      if (this.list_pagination.page != page) {
        this.goPage(page);
      }
    },
  },
};
</script>
