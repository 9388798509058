import http, { httpmock } from '@/utils/http';

const endpoint = 'facility-question';

export default {
  async init (params = {}, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/init`, { ...params }, config);
    return response.data;
  },
  async fget (params = {}, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/f/list`, { ...params }, config);
    const questions = [];
    response.data.data.forEach((question) => {
      const filterIds = [];
      question.has_many_localized_filter.forEach((item) => {
        if (item.status > 0) filterIds.push(item.facilities_localized_category_id);
      });
      question.has_many_localized_filter = filterIds;
      questions.push(question);
    });
    response.data.data = questions;
    return response.data;
  },
  async get (params = {}, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/list`, { ...params }, config);
    return response.data;
  },
  async save (facility_id, questions = [], filters = [], no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const modified = [];
    const filterIds = [];

    for (let filter of filters) filterIds.push(filter.value);
    console.log('filterIds', filterIds);
    for (let question of questions) {
      const item = { ...question }
      const filterItems = [];
      for (let filter_id of filterIds) {
        filterItems.push({
          facilities_localized_category_id: filter_id,
          status: question.has_many_localized_filter.includes(filter_id) ? 1 : 0
        });
      }
      item.has_many_localized_filter = filterItems;
      modified.push(item);
    }
    const response = await http.put(`facility-optional-questions/${facility_id}`, modified, config);
    return response.data;
  },
}