<template>
  <div id="checkin_facility_wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    console.log('Updated: 2022-06-13 05:10PM PHT');
  }
}
</script>