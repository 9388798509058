<template>
  <div v-if="question">
    <template v-if="question.input_type === 'string'">
      <text-field
        v-if="question.system_name === 'email'"
        :data-qid="id"
        type="email"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : ''
        "
        @input="(v) => $emit('input', v)"
      />
      <text-field
        v-else-if="question.system_name === 'address'"
        :data-qid="id"
        type="text"
        :error="error"
        :label="$t('check-in-form.label-address')"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        @input="(v) => $emit('input', v)"
      />
      <phone-field
        v-else-if="question.system_name === 'phone'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'occupation'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="occupationOptions"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <text-field
        v-else
        :data-qid="id"
        type="text"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyMost(question)"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
    </template>
    <template v-else-if="question.input_type === 'number'">
      <zip-search-field
        v-if="question.system_name === 'zip_code'"
        :data-qid="id"
        type="text"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
        @search-zip="(v) => $emit('search-zip', v)"
      />
      <phone-field
        v-else-if="question.system_name === 'phone'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <text-field
        v-else
        :data-qid="id"
        type="number"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :placeholder="
          form_mask
            ? $t(`dashboard-settings.checkin-form-mask-masked-placeholder`)
            : question.placeholder
        "
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
    </template>
    <template v-else-if="question.input_type === 'date'">
      <date-field-drop-down
        :data-qid="id"
        :error="error"
        :label="question.label"
        :value="value ? value : null"
        :required="isRequired"
        type="date"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
    </template>
    <template v-else-if="question.input_type === 'choices'">
      <select-field
        v-if="question.system_name === 'receipt_address'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="receiptAddressOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        :empty-field-value="0"
        :label-right-comments="receiptAddressLabelRightComments"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-if="yesNoFields.includes(question.system_name)"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="yesNoOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="durationFields.includes(question.system_name)"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="durationOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <check-box-field
        v-else
        :data-qid="id"
        :error="error"
        :label="question.label"
        :value="value"
        :options="regularOptions(question.metadata.options)"
        :required="isRequired"
        @input="(v) => $emit('input', v)"
      />
    </template>
    <template v-else-if="question.input_type === 'image_file_upload'">
      <photo-field
        :data-qid="id"
        :error="error"
        :label="question.label"
        :value="value"
        :required="isRequired"
        :id="id"
        @input="(v) => $emit('input', v)"
      />
    </template>
    <template v-else-if="question.input_type === 'dropdown'">
      <select-field
        v-if="question.system_name === 'nationality'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="nationalityOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'age'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="ageOptions"
        :customEmptyFieldValue="true"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'gender'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="genderOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'prefecture'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="prefectureOptions"
        :value="form_mask && value == null ? null : value"
        :form_mask="form_mask"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'occupation'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="occupationOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="question.system_name === 'receipt_address'"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="receiptAddressOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        :empty-field-value="0"
        :label-right-comments="receiptAddressLabelRightComments"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="yesNoFields.includes(question.system_name)"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="yesNoOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else-if="durationFields.includes(question.system_name)"
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="durationOptions"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
      <select-field
        v-else
        :data-qid="id"
        :error="error"
        :label="question.label"
        :options="regularOptions(question.metadata.options)"
        :value="value"
        :required="isRequired"
        :copy-button="copyButton"
        @input="(v) => $emit('input', v)"
        @copy="() => $emit('copy')"
      />
    </template>
    <template v-else>
      <div class="form-group">
        <label>{{ label ? label : 'Field' }}</label>
        <p class="form-control">Input not supported</p>
      </div>
    </template>
  </div>
</template>

<script>
import translation from '@/mixins/translation';

import CheckBoxField from '@/components/form-fields/CheckBoxField';
import DateFieldDropDown from '@/components/form-fields/DateFieldDropDown';
import PhoneField from '@/components/form-fields/PhoneField';
import PhotoField from '@/components/form-fields/PhotoField';
import SelectField from '@/components/form-fields/SelectField';
import TextField from '@/components/form-fields/TextField';
import ZipSearchField from '@/components/form-fields/ZipSearchField';

import nationalities from '@/utils/mockups/nationalities';
import prefectures from '@/utils/mockups/prefectures';
import occupations from '@/utils/mockups/occupations';

export default {
  components: {
    CheckBoxField,
    DateFieldDropDown,
    PhoneField,
    PhotoField,
    SelectField,
    TextField,
    ZipSearchField,
  },
  props: {
    question: { type: Object, default: () => null },
    value: { type: Object | String | Number | Boolean },
    error: { type: String, default: '' },
    copyButton: { type: Boolean, default: false },
    id: String,
    isMain: { type: Boolean, default: true },
    form_mask: { type: Boolean, default: false },
  },
  mixins: [translation],
  computed: {
    isRequired() {
      if (this.isMain == true) {
        return this.question.required == true;
      } else {
        return this.question.required_companion == true;
      }
    },
    ageOptions() {
      const list = [{ value: 0, text: '< 1' }];
      for (let i = 1; i < 100; i++) list.push({ value: i, text: i });
      list.push({ value: 100, text: '> 99' });
      Array.prototype.splice.apply(
        list,
        [41, 0].concat({ value: null, text: '--' })
      );
      return list;
    },
    nationalityOptions() {
      const langCode = this.resolveLanguageCode($i18n.locale, 'en');
      const supported = ['en', 'ja'];
      const code = supported.includes(langCode) ? langCode : 'en';
      const list = nationalities[code];
      const filtered = [];
      if (list) {
        for (let entry of list) {
          filtered.push({
            value: entry.CountryCode,
            text: `${entry.Nationality} (${entry.CountryCode})`,
          });
        }
      }
      return filtered;
    },
    genderOptions() {
      return [
        { value: 'male', text: this.$t('common.choice-male') },
        { value: 'female', text: this.$t('common.choice-female') },
      ];
    },
    occupationOptions() {
      const source = occupations[this.$i18n.locale];
      const list = [];
      for (const key of Object.keys(source)) {
        list.push({ value: key, text: source[key] });
      }
      return list;
    },
    prefectureOptions() {
      const list = [];
      prefectures.forEach((item) => {
        const code = parseInt(item.code.replace('JP-', ''));
        list.push({ value: code, text: `${item.ja} (${item.en})` });
      });
      return list;
    },
    yesNoOptions() {
      return [
        { value: 'yes', text: this.$t('common.word-yes') },
        { value: 'no', text: this.$t('common.word-no') },
      ];
    },
    durationOptions() {
      return [
        { value: 'na', text: this.$t('common.word-none') },
        { value: '1wk', text: this.$t('common.choice-week-1') },
        { value: '2wk', text: this.$t('common.choice-week-2') },
      ];
    },
    receiptAddressOptions() {
      const options = [
        { value: '1', text: this.$t('common.choice-guest-name') },
      ];
      // 会社名の質問がある場合のみ会社名の選択肢を表示する
      const hasCompanyNameQuestion = this.$parent.questions.some((question) => {
        return question.system_name == 'company_name';
      });
      if (hasCompanyNameQuestion)
        options.push({
          value: '2',
          text: this.$t('common.choice-company-name'),
        });
      return options;
    },
    receiptAddressLabelRightComments() {
      return this.value == 2
        ? this.$t('check-in-form.warning-required-company-name')
        : '';
    },
  },
  data() {
    return {
      yesNoFields: [],
      durationFields: ['foreign_travel', 'close_contact_corona_patient'],
      noCopyFields: ['full_name', 'email'],
    };
  },
  methods: {
    regularOptions(options = []) {
      const list = [];
      for (let entry of options) list.push(entry.name);
      return list;
    },
    copyMost(question) {
      if (!this.copyButton) return false;
      if (this.noCopyFields.includes(question.system_name)) return false;
      return true;
    },
  },
};
</script>
