<template>
  <error-page-layout>
    <span class="display-1 d-block text-primary pt-4">
      <template v-if="type == 'checkin-used'">
        <!-- <i class="far fa-sad-tear" /> -->
      </template>
      <template v-else-if="type == 'checkin-expired'">
        <!-- <i class="far fa-sad-tear" /> -->
      </template>
      <i v-else class="fas fa-car-crash" />
    </span>
    <div class="mb-4 lead">{{ message }}</div>
    <router-link v-if="this.type == 'generic'" to="/login" class="btn btn-link">
      {{ $t("error-pages.footer-link") }}
    </router-link>
  </error-page-layout>
</template>

<script>
import ErrorPageLayout from "@/layouts/ErrorPageLayout";

export default {
  components: { ErrorPageLayout },
  mounted() {
    const query = this.$query.parse(location.search);
    if (query.status == 'checkin-used') {
      this.type = 'checkin-used';
      this.message = this.$t('error-pages.body-422-checkin-used');
    } else if (query.status == 'checkin-expired') {
      this.type = 'checkin-expired';
      this.message = this.$t('error-pages.body-422-checkin-expired');
    } else this.message = this.$t('error-pages.body-422-generic');
  },
  data() {
    return {
      type: 'generic',
      message: ''
    }
  }
};
</script>