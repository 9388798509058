import Vue from 'vue'
import Vuex from 'vuex'
import CheckinFacility from '@/store/checkin-facility'
import CheckinSite from '@/store/checkin-site'
import Checkin from '@/store/stores.checkin'
import Dashboard from '@/store/stores.dashboard'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    languages: []
  },
  mutations: {
    update_languages (state, payload) { state.languages = payload }
  },
  actions: {
    updateLanguages ({ commit }, payload) { commit('update_languages', payload); }
  },
  modules: {
    'checkin-site': CheckinSite,
    'checkin-facility': CheckinFacility,
    'checkin': Checkin,
    'dashboard': Dashboard,
  }
});

window.$store = store;

export default store;
