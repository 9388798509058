<template>
  <form @submit.prevent="submitForm">
    <!-- SMS通知設定 -->
    <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
      <p class="pre-wrap">
        {{ $t('dashboard-settings.sms-title') }}
      </p>
      <p class="pre-wrap">
        <small>{{ $t('dashboard-settings.sms-notification') }}</small>
      </p>
      <toggle-button
        v-model="data.value.sms_status"
        :labels="{
          checked: 'ON',
          unchecked: 'OFF',
        }"
        :sync="true"
        :value="true"
        :color="{ checked: $theme.purple, unchecked: $theme.purple }"
        :disabled="!this.data.isSecretUser"
        @change="onChangeSmsStatus"
      />
    </div>

    <!-- SMS優先設定 -->
    <div class="px-3 pt-3 pb-1 mb-3 border rounded">
      <p class="pre-wrap">
        {{ $t('dashboard-settings.email-priority-title') }}/{{ $t('dashboard-settings.sms-priority-title') }}
      </p>
      <p class="pre-wrap">
        <small>{{ $t('dashboard-settings.sms-priority-notification') }}</small>
      </p>
      <b-form-group :disabled="!this.data.isSecretUser || !data.value.sms_status">
        <b-form-radio v-model="data.value.sms_priority" :value="false">{{ $t('dashboard-settings.email-priority-title') }}</b-form-radio>
        <b-form-radio v-model="data.value.sms_priority" :value="true">{{ $t('dashboard-settings.sms-priority-title') }}</b-form-radio>
      </b-form-group>
    </div>
    
    <div class="row">
      <div class="col-lg-12" align="right">
        <button type="submit" class="btn btn-warning btn-wide mx-1" :disabled="!this.data.isSecretUser">
          <i class="fas fa-save mr-1" />
          {{ $t('dashboard-settings.button-save') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import settingsApi from '@/api/settings';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('dashboard', ['facility', 'userData']),
  },
  data() {
    return {
      data: {
        name: 'notification_frequency_settings',
        isSecretUser: false,
        value: {
          sms_status: false,
          sms_priority: false,
        },
      },
    };
  },
  async mounted() {
    this.data.facility_id = this.facility.id;
    this.data.isSecretUser = this.userData.id == this.facility.user_id;
    try {
      const response = await settingsApi.getNotifications(this.facility.id);
      if (response.total > 0) {
        const data = { ...this.data, ...response.data[0] };
        //sms_priorityがsettingにない場合を考慮
        if(typeof data.value.sms_priority == 'undefined'){
          data.value.sms_priority = false;
        }
        this.data = data;
      }
    } catch (e) {
      console.error(e);
      this.$toast.error(this.$t('dashboard-common.error-generic'), {
        position: 'top',
      });
    }
  },
  methods: {
    async submitForm() {
      try {
        const response = await settingsApi.updateSms(this.data);
        this.$toast.success(this.$t('dashboard-common.updated-generic'), {
          position: 'top',
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t('dashboard-common.error-generic'), {
          position: 'top',
        });
      }
    },
    onChangeSmsStatus(){
      if (!this.data.value.sms_status) {
        this.data.value.sms_priority = false;
      }
    }
  },
};
</script>
