<template>
    <div class="quillWrapper">
      <div ref="editor"></div>
    </div>
  </template>
  
  <script>
  import Quill from 'quill';
  export default {
    name: 'QuillEditor',
    props: {
      value: {
        type: String,
        default: ''
      },
      options: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        quill: null
      };
    },
    mounted() {
        this.initializeQuill();
    },
    beforeDestroy() {
      if (this.quill) {
        this.quill.off('text-change', this.handleTextChange);
      }
    },
    methods: {
      initializeQuill() {
        const editorOptions = {
          theme: 'snow',
          modules: {
            toolbar: this.customToolbar || [
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['link', 'clean']
            ],
            ...this.options.modules
          },
          ...this.options,
          readOnly: this.disabled
        };
        this.quill = new Quill(this.$refs.editor, editorOptions);
        if (this.value) {
        this.quill.clipboard.dangerouslyPasteHTML(this.value);
      }

      this.quill.on('text-change', this.handleTextChange);
    },
    handleTextChange() {
      const html = this.quill.root.innerHTML;
      this.$emit('input', html);
    }
  },
  
    watch: {
      value(newValue) {
        if (this.quill && newValue !== this.quill.root.innerHTML) {
          this.quill.root.innerHTML = newValue;
        }
      },
      disabled(newDisabled) {
        if (this.quill) {
          this.quill.enable(!newDisabled);
        }
      }
    }
  };
  </script>
  
  <style>
  .quillWrapper {
    min-height: 200px;
  }
  .ql-editor{
    min-height: 200px;
  }
  .ql-editor p {
    margin-bottom: 0;
    padding: 0;
  }
  </style>