<template>
  <div class="page-wrap d-flex flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translation from "@/mixins/translation";

export default {
  mixins: [ translation ],
  beforeMount() {
    this.autoSetLanguage();
  }
}
</script>