import http, { httpmock } from '@/utils/http';

const endpoint = 'guide-mail';
const defaults = {
  author: '',
  updated_at: ''
}

function normalizeData(data) {
  const { id, name, value, updated_at, created_at, facility_id, activity_log } = data;
  const normalized = { id, name, value, updated_at, created_at, facility_id }
  if (activity_log) {
    normalized.author = activity_log.user ? activity_log.user.username : '';
  }
  return normalized;
}

export default {
  async sendGuideMail(params, no_loader){
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/send-guide-mail`, params, { ...config });
    return response;

  },

  async registrationStopGuideMail(params, no_loader){
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/registration-stop-guide-mail`, params, { ...config });
    return response;
  },

  async restartSendGuideMail(params, no_loader){
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/restart-send-guide-mail`, params, { ...config });
    return response;
  },

  async fetchOperationLogs(params, no_loader){
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const response = await http.post(`${endpoint}/fetch-operation-logs`, params, { ...config });
    return response;
  }
}