<template>
  <!-- Step-7: Completion -->
  <div>
    <div class="d-flex justify-content-center py-3">
      <checkin-stepper step="complete" />
    </div>
    <div>
      <b-card>
        <b-card-title class="text-center">
          {{ $t("check-in-form.title-completion") }}
        </b-card-title>
        <b-card-text>
          <p>{{ $t("check-in-form.instruction-completion-1") }}</p>
          <p>{{ $t("check-in-form.instruction-completion-2") }}</p>
          <p>{{ $t("check-in-form.instruction-completion-3") }}</p>
        </b-card-text>
        <div class="text-center">
          <img class="qr-code" :src="qrData" />
        </div>
      </b-card>
      <b-card v-if="landing2Enabled" class="mt-3">
        <div v-html="landingText" />
        <div class="text-center">
          <a :href="landingLinks.play_store" target="_blank">
            <img
              width="145"
              :src="require('@/assets/img/google-play-en.svg')"
              alt="google-play"
            />
          </a>
          &nbsp;
          <a :href="landingLinks.app_store" target="_blank">
            <img
              width="145"
              :src="require('@/assets/img/app-store-en.svg')"
              alt="app-store"
            />
          </a>
        </div>
      </b-card>
      <div v-if="facility.url" class="p-2 mt-2 text-right">
        <a :href="facility.url" target="_blank">{{
          $t(`check-in-form.text-homepage-link`)
        }}</a>
      </div>
    </div>
    <div class="text-center"></div>
  </div>
</template>

<style lang="scss" scoped>
.qr-code {
  width: 100%;
  max-width: 450px;
}
</style>

<script>
import CheckinStepper from "@/components/CheckinStepper";
import CircleNavButton from "@/components/CircleNavButton";
import settingsApi from "@/api/settings";

import { mapState } from "vuex";

import scrollers from "@/mixins/scrollers";

export default {
  beforeMount() {
    if (!this.facility.id) return this.$router.replace({ path: "/error-404" });
  },
  components: { CheckinStepper, CircleNavButton },
  async mounted() {
    this.scrollToTop();
    try {
      this.qrData = sessionStorage.getItem("qr_data");
      const response = await settingsApi.getLanding2State(this.facility.id);
      if (response.data.length > 0) {
        if (response.data[0].value) {
          await this.fetchLandingData();
          this.landing2Enabled = response.data[0].value;
        }
      }
    } catch (e) {
      console.error(e.message);
    }
  },
  computed: {
    ...mapState("checkin", ["facility"]),
    locale() {
      return this.$i18n.locale;
    }
  },
  mixins: [scrollers],
  data() {
    return {
      qrData: "",
      landing2Enabled: false,
      landingText: "",
      landingLinks: {
        app_store: "",
        play_store: ""
      }
    };
  },
  methods: {
    goBack() {
      this.$router.replace({ name: "checkin-step-6" });
    },
    async fetchLandingData() {
      try {
        const landing = await settingsApi.getLanding2(
          this.facility.id,
          this.$i18n.locale
        );
        const links = await settingsApi.getLinks(
          this.facility.id,
          this.$i18n.locale
        );
        if (landing.data.length > 0) this.landingText = landing.data[0].value;
        if (links.data.length > 0) this.landingLinks = links.data[0].value;
      } catch (e) {
        console.error(e.message);
      }
    }
  },
  watch: {
    async locale() {
      if (this.landing2Enabled) await this.fetchLandingData();
    }
  }
};
</script>