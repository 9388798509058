<template>
  <div class="form-group" :id="id">
    <label v-if="label" class="flex-center-label">
      {{ label }}
      <span v-if="required" class="badge text-white custom-required-mark"> {{ $t('common.word-required') }}</span>
    </label>
    <div class="phone-field-wrapper">
      <vue-tel-input
        validCharactersOnly
        :placeholder="placeholder"
        :autoFormat="false"
        @input="
          (num, obj) => {
            $emit('input', num, obj);
          }
        "
        :value="value"
        @validate="
          (v) => {
            $emit('validate', v);
          }
        "
        @country-changed="
          (v) => {
            $emit('validate', v);
          }
        "
        :class="{
          'border-primary': error.length < 1,
          'border-danger': error.length > 0,
          errored: error.length > 0,
        }"
        class="form-control"
      >
        <template v-slot:arrow-icon>
          <i class="fas fa-caret-down"></i>
        </template>
      </vue-tel-input>
      <button
        v-show="value && value.length > 0 && error.length < 1"
        @click="(v) => $emit('input', '', defaultObject)"
        type="button"
        class="form-icon form-icon-clear"
        title="clear"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        v-show="error.length > 0"
        @click="(v) => $emit('input', '', defaultObject)"
        type="button"
        class="form-icon form-icon-exclamation"
        title="clear"
      >
        <i class="fas fa-exclamation-circle"></i>
      </button>
    </div>
    <small
      v-if="!noErrorMessage & (error.length > 0)"
      class="form-message text-danger"
      >{{ error }}</small
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: "",
      options: {
        showDialCodeInSelection: true
      },
      defaultObject: {
        country: {
          areaCodes: null,
          dialCode: "",
          iso2: "",
          name: ""
        },
        isValid: false,
        number: {
          e164: "",
          input: "",
          international: "",
          national: "",
          rfc3966: "",
          significant: ""
        },
        possibility: "",
        possible: false,
        regionCode: null,
        valid: false
      }
    };
  },
  props: {
    value: { type: Number | String, default: "" },
    noErrorMessage: { type: Boolean, default: false },
    error: { type: String, default: "" },
    required: { type: Boolean, default: false },
    label: { type: String, default: "" },
    placeholder: { type: String, default: ""},
    id: String
  }
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "~@/assets/scss/variables";

.phone-field-wrapper {
  position: relative;
  .vue-tel-input {
    border-radius: 0.25rem !important;
    input {
      border-radius: 0.25rem !important;
    }
    .vti__input {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
    }
    .vti__dropdown:focus {
      outline: none !important;
    }
    .vue-tel-input:focus-within {
      box-shadow: $input-focus-box-shadow !important;
    }
    .vti__dropdown {
      .fa-caret-down {
        transition: transform 100ms ease;
      }
      &.open {
        .fa-caret-down {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>