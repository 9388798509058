<template>
  <div>
    <!-- Confirm Lock Field -->
    <form @submit.prevent="submitLockForm">
      <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
        <p class="pre-wrap">
          {{ $t("dashboard-settings.instruction-login-lock") }}
        </p>
        <!-- Lock Limit -->
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.login-label-lock-limit") }}
          </label>
          <div class="col-sm-8">
            <input
              v-model="lock_limit"
              type="number"
              required
              class="form-control display-spinner"
              :readonly="!hasAccess('setting.basic_information.edit')"
              :disabled="!hasAccess('setting.basic_information.edit')"
            />
          </div>
        </div>
        <!-- Lock Release Time -->
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.login-label-lock-release-time") }}
          </label>
          <div class="col-sm-8">
            <input 
              v-model="lock_release_time"
              class="form-control watch-icon-left-form watch-icon-left-icon"
              type="time"
              max="23:59"
              min="00:01"
              step="60"
              required
              :readonly="!hasAccess('setting.basic_information.edit')"
              :disabled="!hasAccess('setting.basic_information.edit')"
            />
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-12" align="right">
          <button
            type="submit"
            class="btn btn-warning btn-wide mx-1"
            v-if="hasAccess('setting.basic_information.edit')"
          >
            <i class="fas fa-save mr-1" />
            {{ $t("dashboard-settings.button-save") }}
          </button>
        </div>
      </div>
    </form>
  
    <!-- Form Mask Field -->
    <form @submit.prevent="submitMaskForm">
      <div class="pt-3 px-3 pb-1 mt-3 mb-3 border rounded">
        <p class="pre-wrap">
          {{ $t("dashboard-settings.instruction-login-mask") }}
        </p>

        <!-- Authentication method -->
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.checkin-authentication-method-label") }}
          </label>
          <div class="col-sm-8">
              <select
              v-model="authentication_method"
              class="form-control"
              required
              :readonly="!hasAccess('setting.basic_information.edit')"
              :disabled="!hasAccess('setting.basic_information.edit')"
            >
              <option
                :value="value"
                v-for="(value, key) in [0, 2, 3, 1]"
                :key="key"
              >
              {{ $t(`dashboard-settings.checkin-authentication-method-value${value}`) }}
              </option>
            </select>
          </div>
        </div>

        <!-- Form Mask Method -->
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.checkin-form-mask-method-label") }}
          </label>
          <div class="col-sm-8">
            <select
            v-model="form_mask_method"
            class="form-control"
            required
            :readonly="!hasAccess('setting.basic_information.edit')"
            :disabled="!hasAccess('setting.basic_information.edit')"
          >
            <option
              :value="value"
              v-for="(value, key) in [0, 1, 2]"
              :key="key"
            >
              {{ $t(`dashboard-settings.checkin-form-mask-method-value${value}`) }}
            </option>
          </select>
          <template>
            <label class="text-danger pt-1">
              {{ $t(`dashboard-settings.instruction-form-mask-method-value${form_mask_method}`) }}
            </label>
          </template>
        </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-12" align="right">
          <button
            type="submit"
            class="btn btn-warning btn-wide mx-1"
            v-if="hasAccess('setting.basic_information.edit')"
          >
            <i class="fas fa-save mr-1" />
            {{ $t("dashboard-settings.button-save") }}
          </button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import settingsApi from "@/api/settings";
import moment, { min } from "moment-timezone";
import { nationalities } from "@/utils/mockups/";
import { mapActions, mapGetters } from "vuex";
import uac from "@/mixins/uac";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"])
  },
  mixins: [uac],
  data() {
    return {
      user: { email: "" },
      facility_id: null,
      lock_limit: null,
      lock_release_time: null,
      form_mask_method: 0,
      form_mask_method_label: [0, 1, 2],
      authentication_method: 0,
      authentication_method_label: [0, 2, 3, 1],
    };
  },
  beforeMount() {
    this.facility_id = this.facility.id;
  },
  async mounted() {
    const responseConfirmLock = await settingsApi.getConfirmLock(this.facility.id);
    const responseForm = await settingsApi.getForm(this.facility.id);
    this.lock_limit = 3;
    this.lock_release_time = '00:15';
    if (responseConfirmLock.total > 0) {
      const confirm_lock_settings = responseConfirmLock.data[0].value;
      this.lock_limit = confirm_lock_settings['lock_limit'];
      this.lock_release_time = confirm_lock_settings['lock_release_time'];
    };
    if (responseForm.total > 0) {
      const form_settings = responseForm.data[0].value;
      console.log('form_settings:::', form_settings);
      this.authentication_method = form_settings['authentication_method'] | 0;
      this.form_mask_method = form_settings['form_mask_method'] | 0;
    };
    this.facility_id = this.facility.id
  },
  watch: {
    lock_limit() {
      if(this.lock_limit > 99) this.lock_limit = 99;
      if(this.lock_limit < 1) this.lock_limit = 1;
    },
    lock_release_time(input) {
      const format = 'hh:mm';
      const minTime = '00:01'
      const maxTime = '23:59'
      const minTimeFormat = moment(minTime, format);
      const maxTimeFormat = moment(maxTime, format);
      if(moment(input, format).isAfter(maxTimeFormat)) this.lock_release_time = maxTime;
      if(moment(input, format).isBefore(minTimeFormat)) this.lock_release_time = minTime;
    },
  },
  methods: {
    ...mapActions("dashboard", ["updateFacilities"]),
    async submitLockForm(e) {
      try {
          const data = {
            facility_id: this.facility_id,
            name: 'confirm_lock',
              value: {
                lock_limit: this.lock_limit,
                lock_release_time: this.lock_release_time
              },
          };
        const response = await settingsApi.save(data);
        // console.log('this.facility.facility_setting:::', this.facility.facility_setting)
        // let confirm_lock = this.facility.facility_setting.find((setting) => setting['name'] === 'confirm_lock').value['lock_limit'];
        // confirm_lock = this.confirm_lock;
        // this.facility.facility_setting.find((setting) => setting['name'] === 'confirm_lock') = this.lock_limit;
        // this.facility.facility_setting.find((setting) => setting['name'] === 'confirm_lock') = this.lock_release_time;
        // this.lock_limit = lock_limit ? lock_limit.value['lock_limit'] : 3;
        // const lock_release_time = this.facility.facility_setting.find((setting) => setting['name'] === 'confirm_lock');
        // this.lock_release_time = lock_release_time ? lock_release_time?.value['lock_release_time'] : "00:15";

        console.log('response', response);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async submitMaskForm(e) {
      try {
          const data = {
            facility_id: this.facility_id,
            name: 'form',
              value: {
                authentication_method: this.authentication_method,
                form_mask_method: this.form_mask_method,
              },
          };
        const response = await settingsApi.save(data);
        this.$toast.success(this.$t("dashboard-common.updated-generic"), {
          position: "top"
        });
      } catch (e) {
        console.error(e);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
  }
};
</script>