
import Index from '@/pages/checkin/Index'
import Init from '@/pages/checkin/contents/Init'
import Step_1 from '@/pages/checkin/contents/Step_1'
import Step_2 from '@/pages/checkin/contents/Step_2'
import Step_3 from '@/pages/checkin/contents/Step_3'
import Step_4 from '@/pages/checkin/contents/Step_4'
import Step_5 from '@/pages/checkin/contents/Step_5'
import Step_6 from '@/pages/checkin/contents/Step_6'
import Step_7 from '@/pages/checkin/contents/Step_7'
import Step_confirm from '@/pages/checkin/contents/Step_confirm'

export default [
  {
    path: '/check-in',
    component: Index,
    children: [
      { path: '/', component: Init },
      { path: 'confirm', component: Step_confirm, name: 'checkin-confirm' },
      { path: 'step-1', component: Step_1, name: 'checkin-step-1' },
      { path: 'step-2', component: Step_2, name: 'checkin-step-2' },
      { path: 'step-3', component: Step_3, name: 'checkin-step-3' },
      { path: 'step-4', component: Step_4, name: 'checkin-step-4' },
      { path: 'step-5', component: Step_5, name: 'checkin-step-5' },
      { path: 'step-6', component: Step_6, name: 'checkin-step-6' },
      { path: 'step-7', component: Step_7, name: 'checkin-step-7' },
    ]
  },
]