<template>
  <b-modal
    :visible="show"
    :title="modal_title"
    centered
    :no-close-on-esc="loading"
    :no-close-on-backdrop="loading"
    :header-class="{ 'modal-loading': loading }"
    @hide="close"
  >
    <form ref="user_form" @submit.prevent="submit">
      <!-- User ID -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-userid") }}
          <a
            tabindex="1"
            class="tip text-primary"
            v-b-tooltip.focus
            :title="$t('dashboard-users.tip-userid')"
          >
            <i class="far fa-question-circle"></i>
          </a>
        </label>
        <div class="col-sm-8">
          <input
            ref="username"
            v-model="data.username"
            @input="validateUsername"
            type="text"
            required
            :disabled="loading"
            class="form-control"
            :placeholder="$t('dashboard-users.label-userid')"
          />
        </div>
      </div>
      <!-- Password -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-password") }}
          <!-- <a
            tabindex="1"
            class="tip text-primary"
            v-b-tooltip.focus
            :title="$t('dashboard-users.tip-password')"
          >
            <i class="far fa-question-circle"></i>
          </a> -->
        </label>
        <div class="col-sm-8">
          <div class="input-group">
            <input
              ref="password"
              v-model="data.password"
              @input="validatePassword"
              type="password"
              required
              minlength="6"
              :disabled="loading || !enabled_pass"
              class="form-control"
              :placeholder="$t('dashboard-users.label-password')"
            />
            <div v-if="mode == 'edit'" class="input-group-append">
              <span class="input-group-text">
                <b-form-checkbox
                  v-model="enabled_pass"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="loading"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-email") }}
        </label>
        <div class="col-sm-8">
          <input
            ref="email"
            v-model="data.email"
            @input="validateEmail"
            type="email"
            required
            :disabled="loading"
            class="form-control"
            :placeholder="$t('dashboard-users.label-email')"
          />
        </div>
      </div>
      <!-- Last Name -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-last-name") }}
        </label>
        <div class="col-sm-8">
          <input
              ref="last_name"
              v-model="data.last_name"
              type="text"
              required
              :disabled="loading"
              class="form-control"
              :placeholder="$t('dashboard-users.label-last-name')"
          />
        </div>
      </div>
      <!-- First Name -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-first-name") }}
        </label>
        <div class="col-sm-8">
          <input
            ref="first_name"
            v-model="data.first_name"
            type="text"
            required
            :disabled="loading"
            class="form-control"
            :placeholder="$t('dashboard-users.label-first-name')"
          />
        </div>
      </div>

      <!-- Role -->
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          {{ $t("dashboard-users.label-role") }}
        </label>
        <div class="col-sm-8">
          <select
            ref="role_id"
            v-model="data.role_id"
            class="form-control"
            required
            :disabled="loading"
          >
            <option
              v-for="(role, index) in roles"
              :value="role.id"
              :key="`r_${index}`"
            >
              {{ $t(`dashboard-users.role-${role.name}`) }}
            </option>
          </select>
        </div>
      </div>
      <!-- Submit Button -->
      <button
        type="submit"
        ref="names_form_submit"
        style="display: none"
      ></button>
    </form>
    <template v-slot:modal-footer>
      <div class="text-right">
        <button
          @click="
            () => {
              $refs.names_form_submit.click();
            }
          "
          class="btn btn-warning btn-min ml-3 text-white"
          :disabled="loading"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm" />
          <i v-else class="fas fa-check mr-1" />
          {{ $t("dashboard-users.button-save") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import * as EmailValidator from 'email-validator';
import usersApi from "@/api/users";
const specialCharRegex = /[\.\,\+\*\?\^\$\(\)\[\]\{\}\|\\!@#%&~`]+/;
export default {
  props: {
    facility: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: "create"
    },
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    roles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    modal_title() {
      const key = this.mode === "create" ? "action-create" : "action-update";
      return this.$t(`dashboard-users.modal-title-${key}`);
    }
  },
  data() {
    return {
      data: {
        id: null,
        username: "",
        password: "",
        email: "",
        first_name: "",
        last_name: "",
        role_id: 0
      },
      enabled_pass: true,
      loading: false
    };
  },
  methods: {
    async submit(e) {
      // Validate form
      const formValid = this.validateForm();
      if(!formValid) {
        this.$nextTick(() => {
          this.$refs.names_form_submit.click();
        });
      }
      // Submit data
      let data = { ...this.data };
      if (this.mode === "create") delete data.id;
      delete data.role_id;
      data.user_role = { id: this.data.role_id };
      if (this.mode === "create") {
        await this.create(data);
      } else {
        await this.update(data);
      }
    },
    clearValidations() {
      const fieldNames = [
        "username",
        "password",
        "email",
        "first_name",
        "last_name",
        "role_id"
      ];
      for (let name of fieldNames) {
        const field = this.$refs[name];
        field.setCustomValidity("");
      }
    },
    async create(data) {
      try {
        this.loading = true;
        const params = { facility_id: this.facility.id };
        await usersApi.create(data, params, true);
        this.loading = false;
        this.$emit("close");
        setTimeout(() => {
          this.$emit("done", "create");
        }, 300);
      } catch (e) {
        console.error(e.message);
        this.loading = false;
        if (e.response.status === 422) {
          setTimeout(() => {
            this.displayInputError(e.response.data.errors);
          }, 100);
        } else {
          console.error("users -> UserModal -> create", e.message);
          this.$toast.error(this.$t("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      }
    },
    async update(data) {
      try {
        this.loading = true;
        const updates = { ...data };
        if (!this.enabled_pass) delete updates.password;
        const params = { facility_id: this.facility.id };
        await usersApi.update(updates, params, true);
        this.loading = false;
        this.$emit("close");
        setTimeout(() => {
          this.$emit("done", "edit");
        }, 300);
      } catch (e) {
        console.error(e.message);
        this.loading = false;
        if (e.response.status === 422) {
          setTimeout(() => {
            this.displayInputError(e.response.data.errors);
          }, 100);
        } else {
          console.error("users -> UserModal -> update", e.message);
          this.$toast.error(this.$t("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      }
    },
    close(e) {
      this.$emit("close");
    },
    validateUsername(e) {
      const target = typeof e.target != 'undefined' ? e.target : e;
      const value = target.value;
      let hasError = true;
      if (value.length < 1) {
        target.setCustomValidity(this.$t("validations.required"));
      } else if (!/[a-z]+/.test(value)) {
        target.setCustomValidity(this.$t("validations.require-lowercase"));
      } else if (!/[A-Z]+/.test(value)) {
        target.setCustomValidity(this.$t("validations.require-uppercase"));
      } else if (!/\d+/.test(value)) {
        target.setCustomValidity(this.$t("validations.require-number"));
      } else if (value.length < 6) {
        target.setCustomValidity(this.$t("validations.require-min-6"));
      } else {
        target.setCustomValidity("");
        hasError = false;
      }
      // if (hasError) target.checkValidity();
    },
    validatePassword(e) {
      const target = typeof e.target != 'undefined' ? e.target : e;
      const value = target.value;
      let hasError = true;
      if (value.length < 1) {
        target.setCustomValidity(this.$t("validations.required"));
      } else if (!/[a-z]+/.test(value)) {
        // target.setCustomValidity(this.$t("validations.require-lowercase"));
        target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!/[A-Z]+/.test(value)) {
        // target.setCustomValidity(this.$t("validations.require-uppercase"));
        target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!/\d+/.test(value)) {
        // target.setCustomValidity(this.$t("validations.require-number"));
        target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (!specialCharRegex.test(value)) {
        // target.setCustomValidity(this.$t("validations.require-special"));
        target.setCustomValidity(this.$t("validations.invalid-field"));
      } else if (value.length < 8) {
        // target.setCustomValidity(this.$t("validations.require-min-8"));
        target.setCustomValidity(this.$t("validations.invalid-field"));
      } else {
        target.setCustomValidity("");
        hasError = false;
      }
      // if (hasError) target.checkValidity();
    },
    validateEmail(e) {
      const target = typeof e.target != 'undefined' ? e.target : e;
      const email = target.value;
      let isValid = false;
      if (!this.validateEmailFormat(email)) {
        target.setCustomValidity(this.$t("validations.invalid-email"));
      } else {
        target.setCustomValidity("");
        isValid = true;
      }
      // target.checkValidity();
    },
    validateEmailFormat(v) {
      if(/\+/.test(v)) return false;
      return EmailValidator.validate(String(v).toLowerCase());
    },
    validateForm() {
      this.validateUsername(this.$refs.username);
      this.enabled_pass ? this.validatePassword(this.$refs.password) : true;
      this.validateEmail(this.$refs.email);
      return this.$refs.user_form.checkValidity();
    },
    displayInputError(errors) {
      const toCheck = ["username", "email"];
      const keys = Object.keys(errors);
      let field = null;
      for (let key of toCheck) {
        const found = keys.some(k => k == key);
        if (found) {
          field = this.$refs[key];
          break;
        }
      }
      if (field) {
        field.setCustomValidity(this.$t("validations.already-taken"));
        field.checkValidity();
        this.$refs.names_form_submit.click();
      }
    }
  },
  watch: {
    show(yes) {
      if (yes) {
        this.$nextTick(this.clearValidations);
        this.data = { ...this.data, ...this.value };
        if (this.mode == "edit") this.enabled_pass = false;
        else this.enabled_pass = true;
      }
    }
  }
};
</script>