<template>

  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-5">
        <h1> {{ $t("tac.title") }}</h1>
      </div>
      <div class="col-md-12 mb-3">
        <hr>
      </div>
      <div class="col-md-12">
        <p>{{ $t("tac.body") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import translation from "@/mixins/translation";
export default {
  name: "tac.vue",
  mixins: [ translation ],
  beforeMount() {
    this.autoSetLanguage();
  },
  mounted() {
    const query = this.$query.parse(location.search);
    this.$i18n.locale = query.lang;
  },
}
</script>

<style scoped>

</style>