export default {
  methods: {
    resolveLanguageCode (locale, defaultLang = "ja") {
      const code = locale.toLocaleLowerCase();
      const map = {
        "en": "en",
        "ja": "ja",
        "zh-cn": "zh-CN",
        "zh-tw": "zh-TW",
        "zh-hk": "zh-TW",
        "zh-my": "zh-CN",
        "zh-sg": "zh-CN"
      }
      try {
        const mcode = map[code];
        return (typeof mcode == "undefined") ? defaultLang : mcode;
      } catch (e) {
        return defaultLang;
      }
    },
    autoDetectLanguageCode (defaultLang = "ja") {
      const langCode = /^en-[A-Z]{2}$/.test(navigator.language)
        ? "en"
        : navigator.language;
      return this.resolveLanguageCode(langCode, defaultLang);
    },
    autoSetLanguage (
      support = ["en", "ja", "zh-CN", "zh-TW", "zh-MY", "zh-HK", "zh-SG"],
      defaultlang = "ja"
    ) {
      const detected = this.autoDetectLanguageCode();
      const supported = support.some(code => code == detected);
      this.$i18n.locale = supported ? detected : defaultlang;
    }
  }
}