<template>
  <!-- Step 2: Select residence -->
  <div>
    <div class="nav-buttons">
      <circle-nav-button direction="prev" @click="goBack" />
    </div>
    <div class="text-center">
      <p>
        {{ $t("check-in-form.instruction-filter") }}
      </p>
    </div>
    <template v-if="localizedFilters.length > 0">
      <div
        v-for="(filter, index) in localizedFilters"
        :key="`f${index}`"
        class="form-group text-center"
      >
        <button
          class="btn-filter btn btn-outline-primary"
          :class="{ active: filterId === filter.id }"
          @click="() => chooseFilter(index)"
        >
          {{ filter.name }}
        </button>
      </div>
    </template>
    <template v-else>
      <div class="bg-light px-4 py-2 border rounded text-center">
        {{ $t(`check-in-form.message-filters-disabled`) }}
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.btn-filter {
  min-width: 200px;
}
</style>

<script>
import filtersApi from "@/api/localized-filters";
import questionsApi from "@/api/questions";

import CircleNavButton from "@/components/CircleNavButton";
import { mapState, mapActions } from "vuex";

export default {
  async beforeMount() {
    // Ensure previous step got processed.
    if (!this.facility.id) this.$router.replace({ path: "/error-404" });
    if (!this.isFiltersEnabled()) {
      this.ensureQuestions();
      this.goNext();
    }
    if (this.localizedFilters.length > 0) return;
    const filters = await filtersApi.showPublic(this.facility.id);
    this.updateLocalizedFilters(filters);
  },
  components: { CircleNavButton },
  computed: {
    ...mapState("checkin", [
      "facility",
      "filtersIndex",
      "localizedFilters",
      "questions"
    ]),
    filterId() {
      const filter = this.localizedFilters[this.filtersIndex];
      if (filter) return filter.id;
      return "";
    }
  },
  methods: {
    ...mapActions("checkin", [
      "updateSavedAnswers",
      "updateLocalizedFilters",
      "updateFiltersIndex",
      "updateQuestions",
      "updateQuestionMap"
    ]),
    async chooseFilter(index) {
      if (this.filtersIndex != index) {
        this.updateFiltersIndex(index);
        this.updateSavedAnswers({});
        await this.ensureQuestions();
      }
      this.$nextTick(this.goNext);
    },
    async ensureQuestions() {
      const options = { facility_id: this.facility.id, language: $i18n.locale };
      const filter = this.localizedFilters[this.filtersIndex];
      if (filter) options.facilities_localized_category_id = filter.id;
      const questions = await questionsApi.get(options);
      this.updateQuestions(questions.data);
      this.updateQuestionMap(this.buildQuestionMap(questions.data));
    },
    isFiltersEnabled() {
      const localizedFilter = this.facility.facility_setting.find(
        setting => setting.name == "localized-filter"
      );
      return localizedFilter.value.visibility;
    },
    buildQuestionMap(questionList) {
      const questionMap = {};
      questionList.forEach(question => {
        const { input_type, rank, system_name } = question;
        questionMap[question.id] = { input_type, rank, system_name };
      });
      return questionMap;
    },
    goBack() {
      // token無しURLから来た場合はstep-1には入れないようにする
      const confirmBooking = this.facility.confirmBooking;
      if(confirmBooking == true) return window.location.href = 'confirm?slug=' + this.facility.slug_name;
      return this.$router.replace({ name: "checkin-step-1" });
    },
    goNext() {
      this.$router.replace({ name: "checkin-step-3" });
    }
  }
};
</script>