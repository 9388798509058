<template>
  <div>
    <!-- Sidebar -->
    <ul
      ref="sideBar"
      class="navbar-nav bg-gradient-warning sidebar sidebar-dark accordion"
    >
      <!-- Sidebar - Brand -->
      <a
        class="sidebar-brand d-flex align-items-center justify-content-center"
        href="/dashboard"
      >
        <div
          class="sidebar-brand-icon text-left mr-1"
          style="width: 25px; margin-left: -10px"
        >
          <logo class="w-100" style="height: 48px" />
        </div>
        <div
          class="sidebar-brand-text my-0 no-wrap text-left"
          style="font-size: 11px; width: 154px"
        >
          <span class="no-wrap">{{ $t("account-page.title-1") }}</span>
          {{ $t("account-page.title-2") }}
        </div>
      </a>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Hotels -->
      <!--      <li class="nav-item pt-3">-->
      <!--        <div class="nav-link">-->
      <!--          <select class="form-control">-->
      <!--            <option-->
      <!--              v-for="(facility, index) in facilities"-->
      <!--              :key="`f${index}`"-->
      <!--              :value="index"-->
      <!--            >-->
      <!--              {{ facility.name }}-->
      <!--            </option>-->
      <!--          </select>-->
      <!--        </div>-->
      <!--      </li>-->

      <!-- Nav Item - Check in list -->
      <li class="nav-item" :class="{ active: activeMenu == 'list' }">
        <router-link to="/dashboard/" class="nav-link">
          <i class="fas fa-list"></i>
          <span>{{ $t("dashboard-sidebar.list") }}</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider" />

      <!-- Heading - General -->
      <div class="sidebar-heading mb-2">
        {{ $t("dashboard-sidebar.general") }}
      </div>

      <!-- Nav Items - General -->
      <!-- Menu - Form -->
      <li
        v-if="hasAccess('checkin_form_setting.questionnaire.view')"
        class="nav-item"
        :class="{ active: activeMenu == 'form' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/form">
          <i class="fab fa-wpforms"></i>
          <span>{{ $t("dashboard-sidebar.form") }}</span>
        </router-link>
      </li>
      <!-- Menu - Filters -->
      <li
        v-if="hasAccess('filter_setting.localized_filter.view')"
        class="nav-item"
        :class="{ active: activeMenu == 'filters' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/filters">
          <i class="fas fa-filter"></i>
          <span>{{ $t("dashboard-sidebar.filters") }}</span>
        </router-link>
      </li>
      <!-- Menu - Downloads -->
      <li
        v-if="hasAccess('setting.picture.download')"
        class="nav-item"
        :class="{ active: activeMenu == 'downloads' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/downloads">
          <i class="fas fa-download"></i>
          <span>{{ $t("dashboard-sidebar.downloads") }}</span>
        </router-link>
      </li>
      <!-- Menu - CMS -->
      <li
        v-if="canCMS"
        class="nav-item"
        :class="{ active: activeMenu == 'cms' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/cms">
          <i class="fas fa-edit"></i>
          <span>{{ $t("dashboard-sidebar.cms") }}</span>
        </router-link>
      </li>
      <!-- Menu - Notifications -->
      <li
        v-if="hasAccess('notice_setting.frequency.view')"
        class="nav-item"
        :class="{ active: activeMenu == 'notifications' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/notifications">
          <i class="fas fa-bell"></i>
          <span>{{ $t("dashboard-sidebar.notifications") }}</span>
        </router-link>
      </li>
      <!-- Menu - Users -->
      <li
        v-if="hasAccess('setting.user.view')"
        class="nav-item"
        :class="{ active: activeMenu == 'users' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/users">
          <i class="fas fa-users-cog"></i>
          <span>{{ $t("dashboard-sidebar.users") }}</span>
        </router-link>
      </li>
      <!-- Menu - Terms -->
      <li
        v-if="hasAccess('tac.terms_information.edit')"
        class="nav-item"
        :class="{ active: activeMenu == 'terms' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/terms">
          <i class="fas fa-handshake"></i>
          <span>{{ $t("dashboard-sidebar.terms") }}</span>
        </router-link>
      </li>
      <!-- Menu - Settings -->
      <li
        v-if="canSettings"
        class="nav-item"
        :class="{ active: activeMenu == 'settings' }"
      >
        <router-link class="nav-link py-1" to="/dashboard/settings">
          <i class="fas fa-cogs"></i>
          <span>{{ $t("dashboard-sidebar.settings") }}</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider mt-3" />

      <!-- Nav Items - Links -->
      <li class="nav-item">
        <a href="/help.pdf" class="nav-link" target="_blank">
          <span> {{ $t("dashboard-sidebar.help") }}</span>
        </a>
        <!--        <router-link target="_blank" to="/dashboard/help" class="nav-link py-1">-->
        <!--          <i class=""></i>-->
        <!--          <span> {{ $t("account-page.help") }}</span>-->
        <!--        </router-link>-->
      </li>
      <li class="nav-item">
        <!-- <router-link
          target="_blank"
          to="/dashboard/terms"
          class="nav-link py-1"
        > -->
        <a href="" class="nav-link" @click.prevent="openSCTermsUrl">
          <span> {{ $t("dashboard-sidebar.stay-concierge-terms") }}</span>
        </a>
        <!-- </router-link> -->
      </li>

      <!-- 
      <li class="nav-item">
        <a
          target="_blank"
          class="nav-link pb-1 pt-1"
          href="https://docs.google.com/forms/d/1BE73quw1CYVcBPe2RtLL-LciKaHypg45V3RE6KnnEKE/viewform?edit_requested=true"
        >
          <i class=""></i>
          <span>{{ $t("account-page.feedback-title") }}</span></a
        >
      </li>

      <li class="nav-item">
        <a
          target="_blank"
          class="nav-link pt-1"
          :href="`/${$i18n.locale}/privacy-policy`"
        >
          <i class=""></i>
          <span>{{ $t("account-page.policy") }}</span></a
        >
      </li> -->

      <!-- Sidebar Toggler (Sidebar) -->
      <!-- <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div> -->
    </ul>
    <!-- End of Sidebar -->
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  transition: width 300ms ease;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  }
}
</style>

<script>
import { mapState } from "vuex";
import uac from "@/mixins/uac";
import logsApi from "@/api/logs";

import logo from "./logo";

const SCTermsUrl = process.env.VUE_APP_FACILITY_TERMS_URL;

export default {
  mixins: [uac],
  components: { logo },
  computed: {
    ...mapState("dashboard", ["activeMenu", "facilities"]),
    canCMS() {
      return (
        this.hasAccess("notice_setting.sms.view") ||
        this.hasAccess("notice_setting.landing.view") ||
        this.hasAccess("notice_setting.email.view")
      );
    },
    canSettings() {
      return (
        this.hasAccess("setting.basic_information.view") ||
        this.hasAccess("setting.facility_information.view") ||
        this.hasAccess("setting.language_setting.view")
      );
    }
  },
  mounted() {
    if (this.$route.name == "dashboard-questions") {
      this.adjustSideNavVh("100vh");
    }
    if (this.$route.name == "dashboard-message-form") {
      this.adjustSideNavVh("120vh");
    }
  },
  methods: {
    adjustSideNavVh(height) {
      this.$refs.sideBar.style.height = height;
    },
    openSCTermsUrl() {
      window.open(SCTermsUrl, "_blank");
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "dashboard-questions") {
        this.adjustSideNavVh("140vh");
      }
      if (to.name == "dashboard-message-form") {
        this.adjustSideNavVh("120vh");
      }
    }
  }
};
</script>