import t from "typy";

export default {
  namespaced: true,
  state: () => ({
    savedAnswers: {
      visitor_id: null,
      first_name: null,
      last_name: null,
      address: null,
      phone_number: null,
      nationality: null,
      other_form: {},
      companions: []
    },
    initialized: false,
    companionsLeft: 0,
    bookingData: {},
    phoneNumber: '',
    countryCode: '',
    enabledFaceCapture: false,
    enabledSignature: false,
    skipFaceCapture: false,
    signatureImageData: '',
    faceImageData: '',
    facility: {},
    questions: [],
    questionMap: {}, // question_id: question_value (input_type, rank, system_name)
    loading: true,
    filtersIndex: -1,
    localizedFilters: [],
    savedRoomLeaders: [],
    token: '',
    maxRetryReached: false,
  }),
  getters: {
    facilityName ({ facility }) {
      let facilityName = "N/A";
      if (facility.name) {
        facilityName = facility.name;
        if (facility.facility_name.length > 0) {
          const fn = facility.facility_name.find((f) => f.language == $i18n.locale);
          if (fn) facilityName = fn.name;
        }
      }
      return facilityName;
    },
    checkInDateTime ({ bookingData }) {
      const checkInDate = t(bookingData, 'checkin_date').isDefined;
      const checkInTime = t(bookingData, 'checkin_time').isDefined;
      if (checkInDate && checkInTime) {
        return `${bookingData.checkin_date} ${bookingData.checkin_time}`;
      }
      return '';
    },
    checkOutDateTime ({ bookingData }) {
      const checkOutDate = t(bookingData, 'checkout_date').isDefined;
      const checkInOutTime = t(bookingData, 'checkout_time').isDefined;
      if (checkOutDate && checkInOutTime) {
        return `${bookingData.checkout_date} ${bookingData.checkout_time}`;
      }
      return '';
    },
    companionCount ({ bookingData }) {
      if (!t(bookingData, 'meta.number_of_guests').isDefined) return 0;
      if (Number(bookingData.meta.number_of_guests) < 1) return 0;
      return Number(bookingData.meta.number_of_guests) - 1;
    },
    localizedFilter ({ localizedFilters, filtersIndex }) {
      let filter = null
      if (localizedFilters[filtersIndex]) {
        filter = localizedFilters[filtersIndex];
      }
      return filter;
    },
    companionsAdded ({ savedAnswers }) {
      return savedAnswers.companions.length;
    },
    maxRoom ({ bookingData }) {
      if (t(bookingData, 'meta.room_quantity').isDefined) return bookingData.meta.room_quantity;
      return 1;
    },
    maxGuest ({ bookingData }) {
      if (t(bookingData, 'meta.number_of_guests').isDefined) return bookingData.meta.number_of_guests;
      return 1;
    },
  },
  mutations: {
    set_loading (state, payload) { state.loading = payload; },
    set_token (state, payload) { state.token = payload; },
    set_max_retry_reached (state, payload) { state.maxRetryReached = payload },
    update_country_code (state, payload) { state.countryCode = payload; },
    update_companions_left (state, payload) { state.companionsLeft = payload; },
    update_saved_answers (state, payload) { state.savedAnswers = payload; },
    update_booking_data (state, payload) { state.bookingData = payload; },
    update_facility (state, payload) { state.facility = payload; },
    update_initialized (state, payload) { state.initialized = payload; },
    update_phone (state, payload) { state.phoneNumber = payload; },
    update_questions (state, payload) { state.questions = payload; },
    update_question_map (state, payload) { state.questionMap = payload; },
    update_filters_index (state, payload) { state.filtersIndex = payload; },
    update_enabled_face_capture (state, payload) { state.enabledFaceCapture = payload; },
    update_enabled_signature (state, payload) { state.enabledSignature = payload; },
    update_required_face_capture (state, payload) { state.requiredFaceCapture = payload; },
    update_required_signature (state, payload) { state.requiredSignature = payload; },    
    update_skip_face_capture (state, payload) { state.skipFaceCapture = payload; },
    update_face_image_data (state, payload) { state.faceImageData = payload; },
    update_localized_filters (state, payload) { state.localizedFilters = payload },
    update_signature_image_data (state, payload) { state.signatureImageData = payload },
    update_saved_room_leaders (state, payload) { state.savedRoomLeaders = payload },
  },
  actions: {
    initialize({ commit, getters }) {
      const companionsCount = getters.companionCount;
      commit('update_companions_left', companionsCount);
      commit('update_initialized', true);
    },
    reduceCompanions({ commit, state }, payload) {
      if (state.companionsLeft > 0) {
        const reduced = state.companionsLeft - 1;
        commit('update_companions_left', reduced);
      }
    },
    updatePhone ({ commit }, payload) {
      commit('update_phone', payload.number);
      commit('update_country_code', payload.code);
    },
    updateFiltersIndex ({ commit }, payload) {
      commit('update_filters_index', payload);
    },
    updateSkipFaceCapture ({ commit }, payload) {
      commit('update_skip_face_capture', payload);
    },
    updateFaceImageData ({ commit }, payload) {
      commit('update_face_image_data', payload);
    },
    updateEnabledFaceCapture ({ commit }, payload) {
      commit('update_enabled_face_capture', payload);
    },
    updateEnabledSignature ({ commit }, payload) {
      commit('update_enabled_signature', payload);
    },
    updateRequiredFaceCapture ({ commit }, payload) {
      commit('update_required_face_capture', payload);
    },
    updateRequiredSignature ({ commit }, payload) {
      commit('update_required_signature', payload);
    },
    updateLocalizedFilters ({ commit }, payload) {
      commit('update_localized_filters', payload);
    },
    updateSignatureImageData ({ commit }, payload) {
      commit('update_signature_image_data', payload);
    },
    updateSavedAnswers ({ commit }, payload) {
      commit('update_saved_answers', payload);
    },
    updateBookingData ({ commit }, payload) {
      commit('update_booking_data', payload);
    },
    updateQuestions ({ commit }, payload) {
      commit('update_questions', payload);
    },
    updateQuestionMap ({ commit }, payload) {
      commit('update_question_map', payload);
    },
    updateSavedRoomLeaders ({ commit }, payload) {
      commit('update_saved_room_leaders', payload);
    },
    setToken ({ commit }, payload) {
      commit('set_token', payload);
    },
    setMaxRetryReached ({ commit }, payload) {
      commit('set_max_retry_reached', payload);
    },
  }
}