export default {
  en: {
    government_worker: "Government Worker",
    management_director: "Management Director",
    employed_worker: "Employed Worker",
    self_employed: "Self Employed",
    freelance: "Freelance",
    house_wife: "Housewife",
    part_time: "Part Time",
    student: "Student",
    others: "Others"
  },
  ja: {
    government_worker: "公務員",
    management_director: "経営者・役員",
    employed_worker: "会社員",
    self_employed: "自営業",
    freelance: "自由業",
    house_wife: "専業主婦",
    part_time: "パート・アルバイト",
    student: "学生",
    others: "その他"
  },
  ko: {
    government_worker: "공무원",
    management_director: "경영자 · 임원",
    employed_worker: "직장인",
    self_employed: "자영업자",
    freelance: "프리랜서",
    house_wife: "주부",
    part_time: "아르바이트",
    student: "학생",
    others: "기타"
  },
  'zh-CN': {
    government_worker: "公务员",
    management_director: "管理/执行人员",
    employed_worker: "员工",
    self_employed: "自雇人士",
    freelance: "自由职业者",
    house_wife: "家庭主妇",
    part_time: "兼职工作",
    student: "学生",
    others: "其他"
  },
  'zh-TW': {
    government_worker: "公務員",
    management_director: "管理/執行人員",
    employed_worker: "員工",
    self_employed: "自僱人士",
    freelance: "自由職業者",
    house_wife: "家庭主婦",
    part_time: "兼職工作",
    student: "學生",
    others: "其他"
  }
}