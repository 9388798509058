<template>
  <error-page-layout>
    <span class="display-1 d-block text-primary pt-4">
      <i class="fas fa-user-lock"></i>
    </span>
    <div class="mb-4 mt-4 lead">{{ $t("error-pages.body-401") }}</div>
    <router-link to="/login" class="btn btn-link">
      {{ $t("error-pages.footer-link") }}
    </router-link>
  </error-page-layout>
</template>

<script>
import ErrorPageLayout from "@/layouts/ErrorPageLayout";

export default {
  components: { ErrorPageLayout }
};
</script>