<template>
  <auth-layout>
    <template v-slot:header>
      <div class="text-center">
        <i
          v-if="link_okay && !password_saved"
          class="fas fa-lock header-icon"
        />
        <i
          v-else-if="link_okay && password_saved"
          class="fas fa-check-circle header-icon"
        />
        <i v-else class="fas fa-ban header-icon" />
      </div>
    </template>
    <template v-if="link_okay && !password_saved">
      <h4 class="text-center">{{ $t(`auth.title-new-password`) }}</h4>
      <form @submit.prevent="submit">
        <div>
          <text-field
            type="password"
            :label="$t(`auth.label-new-password`)"
            v-model="data.password"
            placeholder="e.g. PassWord100%"
            required
            @input="validatePassword"
            :error="error_msg"
          />
        </div>
        <div>
          <text-field
            type="password"
            :label="$t(`auth.label-confirm-password`)"
            v-model="data.confirm_password"
            :placeholder="$t(`auth.label-confirm-password`)"
            required
            @input="confirmPassword"
            :error="error_msg"
          />
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary btn-min">
            {{ $t(`auth.button-save`) }}
          </button>
        </div>
      </form>
    </template>
    <div v-else-if="link_okay && password_saved">
      <h4 class="text-center">{{ $t(`auth.title-success`) }}</h4>
      <p>{{ $t(`auth.message-password-saved`) }}</p>
    </div>
    <div v-else>
      <h4 class="text-center">{{ $t(`auth.title-invalid`) }}</h4>
      <p>{{ $t(`auth.error-link-expired`) }}</p>
    </div>
    <template v-slot:footer>
      <div class="d-flex justify-content-around px-3 mt-n1">
        <router-link class="small" to="login">{{
          $t(`auth.label-login`)
        }}</router-link>
      </div>
    </template>
  </auth-layout>
</template>

<script>
import authApi from "@/api/auth";
import AuthLayout from "@/layouts/AuthLayout";
import TextField from "@/components/form-fields/TextField";

const specialCharRegex = /[\.\,\+\*\?\^\$\(\)\[\]\{\}\|\\!@#%&~`]+/;

export default {
  components: { AuthLayout, TextField },
  async mounted() {
    if (!this.$route.query.email || !this.$route.query.token) {
      return this.$router.replace({ path: "/error-404" });
    }
    Object.assign(this.data, this.$route.query);
    try {
      const verify = await authApi.verifyResetToken(this.$route.query);
      if (!verify.status.success) return (this.link_okay = false);
      this.link_okay = true;
    } catch (e) {
      this.$toast.error(this.$t("common.error-generic"), {
        position: "top"
      });
    }
  },
  data() {
    return {
      error_msg: "",
      data: {
        password: "",
        confirm_password: ""
      },
      link_okay: false,
      password_saved: false
    };
  },
  methods: {
    async submit() {
      const data = Object.assign({}, this.data);
      delete data.confirm_password;

      try {
        const verify = await authApi.updatePassword(data);
        if (!verify.status.success) {
          this.$toast.error(verify.message, {
            position: "top"
          });
          this.error_msg = "  ";
          return;
        }
        this.password_saved = true;
      } catch (e) {
        if (!verify.status.success) return (this.link_okay = false);
        this.$toast.error(this.$t("common.error-generic"), {
          position: "top"
        });
      }
    },
    validatePassword(v, e) {
      const value = e.target.value;
      let error = false;
      if (value.length < 1) {
        e.target.setCustomValidity(this.$t("validations.required"));
        error = true;
      } else if (!/[a-z]+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-lowercase"));
      } else if (!/[A-Z]+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-uppercase"));
      } else if (!/\d+/.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-number"));
      } else if (!specialCharRegex.test(value)) {
        e.target.setCustomValidity(this.$t("validations.require-special"));
      } else if (value.length < 8) {
        e.target.setCustomValidity(this.$t("validations.require-min-8"));
      } else {
        e.target.setCustomValidity("");
      }
      if (error) e.target.checkValidity();
    },
    confirmPassword(v, e) {
      const value = e.target.value;
      if (value !== this.data.password) {
        e.target.setCustomValidity(this.$t("validations.confirm-password"));
      } else {
        e.target.setCustomValidity("");
      }
      e.target.checkValidity();
    }
  }
};
</script>
