<template>
  <div>
    <centered-form-layout>
      <template v-if="facility.id" v-slot:header>
        <div class="mb-3">
          <b-dropdown
            id="language_select"
            :text="languages[locale]"
            variant="primary"
            :disabled="langloading"
          >
            <b-dropdown-item
              v-for="code in languageCodeSupport"
              :key="code"
              @click="changeLanguage(code)"
            >
              {{ languages[code] }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <h2 class="mb-0">{{ facilityName }}</h2>
      </template>
      <router-view @show-terms="showTerms" />
      <template v-slot:overlay>
        <div v-if="loading" class="overlay">
          <loading
            :active="true"
            :can-cancel="false"
            :is-full-page="false"
            loader="bars"
            :width="70"
            :height="70"
            :color="$theme.primary"
          />
        </div>
      </template>
    </centered-form-layout>
    <b-modal
      size="lg"
      id="terms_modal"
      :title="$t('check-in-form.terms-conditions')"
    >
      <div class="ql-editor" v-html="termsAndConditions"></div>
      <template v-slot:modal-footer>
        <div class="text-right">
          <button
            type="button"
            class="btn btn-primary btn-min"
            @click="() => $bvModal.hide('terms_modal')"
          >
            OK
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/variables";
#language_select {
  min-width: $btn-min;
}
.checkin-form-header {
  padding-bottom: 10px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ced4da;
}
.accordion-card {
  .controls {
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn:focus {
    box-shadow: none !important;
  }
  .card-body {
    padding: 0 !important;
    overflow-y: hidden;
    .body-wrapper {
      padding: 1.5rem;
    }
  }
  &.collapsed {
    .toggle-icon {
      transform: rotate(-180deg);
    }
    .card-header {
      border-bottom: none !important;
    }
    .card-body {
      max-height: 0;
    }
  }
}
.aspect-ratio {
  width: 100%;
  position: relative;
  &.ratio-5x3 {
    padding-top: 60%;
  }
}
</style>

<script>
import CenteredFormLayout from "@/layouts/CenteredFormLayout";
import { mapState, mapGetters } from "vuex";
import settingsApi from "@/api/settings";
import { languageMaps } from "@/utils/mockups/language-options";
import translation from "@/mixins/translation";
// import "quill/dist/quill.snow.css";

const redirectDomainFrom = process.env.VUE_APP_REDIRECT_URL_FROM;
const redirectDomainTo = process.env.VUE_APP_REDIRECT_URL_TO;

export default {
  components: { CenteredFormLayout },
  mixins: [ translation ],
  beforeMount() {
    this.languages = languageMaps.universal;
    this.autoSetLanguage();
  },
  // mounted() {
  //   this.applyRedirect();
  // },
  computed: {
    ...mapState("checkin", ["facility", "loading"]),
    ...mapGetters("checkin", ["facilityName"]),
    locale() {
      return this.$i18n.locale;
    }
  },
  data() {
    return {
      langloading: false,
      languages: {},
      languageCodeSupport: ["ja"],
      termsAndConditions: ""
    };
  },
  methods: {
    applyRedirect() {
      if (!redirectDomainFrom || !redirectDomainTo) return;
      const {host, protocol, search} = window.location;
      if (host == redirectDomainFrom) {
        const redirectToUrl = `${protocol}//${redirectDomainTo}/check-in${search}`;
        top.location = redirectToUrl;
      }
    },
    changeLanguage(code) {
      this.$i18n.locale = code;
    },
    async getLanguageSupport(facility_id) {
      try {
        this.langloading = true;
        const response = await settingsApi.getLanguages(facility_id, true);
        const languages = response.data[0].value;
        const codeSupport = [];
        for (let language of languages) codeSupport.push(language.code);
        this.languageCodeSupport = codeSupport;
        this.langloading = false;
      } catch (e) {
        this.$toast.error(this.$("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async showTerms() {
      try {
        const response = await settingsApi.getTerms(
          this.facility.id,
          this.$i18n.locale
        );
        if (response.total > 0) {
          this.termsAndConditions = response.data[0].value.content;
        }
        this.$bvModal.show("terms_modal");
      } catch (e) {
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    }
  },
  watch: {
    async facility(f) {
      if (f.id) await this.getLanguageSupport(f.id);
    }
  }
};
</script>