<template>
  <b-card
    class="accordion-card"
    :class="{
      collapsed: hasCompanionQuestion ? collapsed : true,
      closing: closing }"
  >
    <template #header>
      <div
        v-if="hasCompanionQuestion"
        class="click-overlay"
        @click="$emit('update:collapsed', !collapsed)"
      ></div>
      <span class="text-info"><i class="far fa-user-circle" /></span>
      {{ $t("check-in-form.word-companion") }} # {{ Number(index + 1) }}
      <div class="controls px-2 py-1">
        <button
          v-if="hasCompanionQuestion"
          type="button"
          class="btn text-primary"
          title="toggle"
          @click="$emit('update:collapsed', !collapsed)"
        >
          <i class="toggle-icon fas fa-angle-up" />
        </button>
        <button
          type="button"
          class="btn text-danger"
          title="remove"
          @click="() => $emit('remove', index)"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </template>
    <div class="body-wrapper">
      <template v-for="(question, cindex) in questions">
        <div v-if="question.visibility_companion == 1">
          <question-field
            v-if="question.system_name == 'email'"
            v-model="data[index].answers[question.system_name]"
            :id="`input${index}_${question.id}`"
            :key="`q${cindex}`"
            :question="question"
            :error="data[index].errors[question.id]"
            :copy-button="true"
            :is-main="false"
            @input="$set(data[index].errors, question.id, updateErrors(question, index, $event))"
            @copy="() => copyFromMain('email')"
          />
          <question-field
            v-else-if="!questionsForMainGuest().includes(question.system_name)"
            v-model="data[index].answers.other_form[question.id]"
            :id="`input${index}_${question.id}`"
            :key="`q${cindex}_${index}`"
            :question="question"
            :error="data[index].errors[question.id]"
            :copy-button="true"
            :is-main="false"
            @input="$set(data[index].errors, question.id, updateErrors(question, index, $event))"
            @copy="() => copyFromMain(question.id)"
            @search-zip="(v) => $emit('search-zip', v, index)"
          />
        </div>
      </template>
    </div>
  </b-card>
</template>

<style lang="scss" scoped>
.btn {
  opacity: 0.8;
  box-shadow: none !important;
  &:hover {
    opacity: 1;
  }
}
.closing {
  animation: exit-out 0.5s linear;
}
@keyframes exit-out {
  0% {
    transform: translate(0px) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(0px, -100px) scale(0.5);
  }
  100% {
    transform: translate(-100px, -400px) scale(0);
    opacity: 0;
  }
}
</style>

<script>
import QuestionField from "@/components/QuestionField";
import { questionsForMainGuest } from "../../../utils/constants/questions_for_main_guest";

export default {
  components: { QuestionField },
  props: {
    index: { type: Number, default: 0 },
    questions: { type: Array, default: () => [] },
    data: { type: Array, default: () => [] },
    collapsed: { type: Boolean, default: false },
    closing: { type: Boolean, default: false },
    hasCompanionQuestion: { type: Boolean, default: true}
  },
  methods: {
    updateErrors(question, index, value){
      if(question.required) return value ? '' : this.data[index].errors[question.id];
    },
    questionsForMainGuest(){
      return questionsForMainGuest;
    },
    copyFromMain(datakey) {
      this.$emit("copy", this.index, datakey);
    },
  }
};
</script>