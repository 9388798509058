<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M535 5106 c-110 -28 -188 -90 -234 -186 l-26 -55 -3 -2302 -2 -2303
      1170 0 c643 0 1170 2 1170 5 0 2 -20 26 -43 53 -24 27 -67 83 -97 125 l-52 77
      -203 0 -203 0 -4 278 c-3 263 -5 280 -26 328 -27 60 -79 113 -142 146 -60 31
      -215 33 -270 4 -63 -34 -106 -74 -136 -128 l-29 -53 -3 -287 -3 -288 -435 0
      -434 0 0 2135 c0 1574 3 2141 11 2159 7 14 25 30 42 36 20 7 386 10 1124 10
      1163 0 1139 1 1157 -45 3 -9 6 -552 6 -1206 0 -654 2 -1189 4 -1189 2 0 30 15
      63 33 32 18 88 46 126 61 l67 29 0 74 0 73 785 0 785 0 0 955 0 955 -784 0
      -784 0 -4 138 c-3 121 -6 143 -28 187 -33 69 -105 136 -176 166 l-59 24 -1140
      2 c-884 1 -1151 -1 -1190 -11z m2775 -1461 l0 -695 -90 0 -90 0 0 695 0 695
      90 0 90 0 0 -695z m1125 0 l0 -690 -432 -3 -433 -2 0 695 0 695 433 -2 432 -3
      0 -690z m-2695 -2625 c7 -12 9 -105 8 -257 l-3 -238 -42 -3 -43 -3 0 254 c0
      139 3 257 7 260 14 15 63 7 73 -13z"
      />
      <path
        d="M3740 3645 l0 -345 45 0 45 0 0 170 0 170 170 0 170 0 0 -170 0 -170
      45 0 45 0 0 345 0 345 -45 0 -45 0 0 -130 0 -130 -170 0 -170 0 0 130 0 130
      -45 0 -45 0 0 -345z"
      />
      <path
        d="M930 4587 c-48 -15 -113 -83 -128 -133 -8 -29 -12 -142 -12 -383 l0
      -341 215 0 215 0 0 355 c0 386 -1 394 -56 449 -60 59 -149 80 -234 53z"
      />
      <path
        d="M1615 4581 c-51 -23 -101 -74 -117 -120 -10 -27 -13 -127 -13 -381
      l0 -345 218 -3 217 -2 0 347 c0 344 0 349 -24 398 -27 59 -46 77 -106 104 -56
      25 -123 26 -175 2z"
      />
      <path
        d="M2316 4585 c-49 -17 -93 -59 -117 -110 -17 -37 -19 -71 -19 -392 l0
      -353 215 0 215 0 0 353 c0 319 -2 356 -19 392 -46 102 -165 149 -275 110z"
      />
      <path
        d="M936 3545 c-61 -21 -88 -46 -118 -105 l-28 -54 0 -348 0 -348 215 0
      215 0 0 356 c0 398 1 394 -70 458 -60 54 -137 69 -214 41z"
      />
      <path
        d="M1626 3546 c-50 -19 -112 -80 -128 -125 -10 -26 -13 -128 -13 -381
      l0 -345 218 -3 217 -2 0 347 c0 328 -2 351 -21 393 -11 24 -28 53 -37 63 -48
      54 -164 79 -236 53z"
      />
      <path
        d="M2340 3551 c-72 -22 -119 -63 -145 -123 -12 -31 -15 -94 -15 -388 l0
      -350 215 0 215 0 0 350 c0 386 -1 394 -63 458 -47 49 -141 73 -207 53z"
      />
      <path
        d="M952 2510 c-57 -13 -108 -55 -136 -111 l-26 -53 0 -348 0 -348 215 0
      215 0 0 355 c0 334 -1 358 -20 394 -27 54 -84 98 -142 111 -54 11 -59 11 -106
      0z"
      />
      <path
        d="M1644 2509 c-50 -12 -115 -65 -140 -116 -17 -35 -19 -70 -22 -390
      l-4 -353 222 0 221 0 -3 358 -3 359 -30 48 c-48 78 -146 116 -241 94z"
      />
      <path
        d="M2343 2511 c-51 -13 -96 -44 -128 -90 l-30 -43 -3 -364 -3 -365 52 3
      51 3 28 75 c47 129 148 298 249 415 l51 60 0 73 c0 109 -33 172 -110 212 -43
      22 -114 31 -157 21z"
      />
      <path
        d="M3410 2456 c-265 -47 -479 -161 -675 -360 -226 -230 -340 -493 -352
      -812 -9 -227 27 -393 128 -599 242 -493 807 -770 1344 -660 489 100 868 480
      971 975 25 118 25 354 1 470 -104 487 -447 847 -922 967 -124 31 -373 41 -495
      19z m360 -257 c100 -16 252 -71 342 -125 95 -58 231 -182 294 -270 61 -86 129
      -234 154 -334 106 -437 -95 -885 -493 -1095 -303 -160 -640 -151 -954 26 -108
      61 -270 223 -338 339 -55 94 -109 241 -124 340 -94 585 360 1125 949 1129 58
      1 134 -4 170 -10z"
      />
      <path
        d="M3783 1378 l-393 -393 -148 148 c-81 81 -152 147 -157 147 -6 0 -63
      -53 -128 -118 l-117 -117 275 -275 275 -275 517 517 518 518 -120 120 c-66 66
      -122 120 -125 120 -3 0 -182 -177 -397 -392z"
      />
    </g>
  </svg>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    color: { type: String, default: "#ffffff" }
  }
};
</script>