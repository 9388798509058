<template>
  <div>
    <div class="px-3 pt-3 pb-1 mb-3 border rounded-bottom tab-container">
      <p>
        {{ $t("dashboard-settings.instruction-names") }}
      </p>
      <div class="table-responsive mb-2">
        <table class="table table-bordered hoverable table-sm">
          <thead>
            <tr>
              <th>{{ $t("dashboard-settings.names-column-name") }}</th>
              <th>{{ $t("dashboard-settings.names-column-language") }}</th>
              <th style="width: 200px">
                {{ $t("dashboard-settings.names-column-operation") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(fn, index) in facility.facility_name"
              :key="`fn_${index}`"
            >
              <td>{{ fn.name }}</td>
              <td>
                {{ $t(`language-options.${fn.language}`) }}
              </td>
              <td align="left" class="controls">
                <button
                  v-if="hasAccess('setting.facility_information.edit')"
                  @click.prevent="showForm('update', fn)"
                  class="btn btn-primary btn-round m-1"
                  :title="$t('common.word-edit')"
                >
                  <i class="fas fa-edit" />
                </button>
                <button
                  v-if="hasAccess('setting.facility_information.edit')"
                  @click.prevent="removeName(fn.id)"
                  class="btn btn-outline-danger btn-round m-1"
                  :title="$t('dashboard-common.word-delete')"
                >
                  <i class="fas fa-minus" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-1 text-center">
        <button
          v-if="hasAccess('setting.facility_information.edit')"
          @click="showForm('create')"
          class="btn btn-primary btn-sm btn-min"
          :disabled="names_maxed"
        >
          <i class="fas fa-plus mr-1" />
          {{ $t("dashboard-settings.button-add") }}
        </button>
      </div>
    </div>
    <!-- Modal - Names -->
    <b-modal id="names_modal" scrollable centered :title="modal_title">
      <form ref="names_form" @submit.prevent="submitForm">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.names-modal-label-name") }}
            <a
              tabindex="1"
              class="tip text-primary"
              v-b-tooltip.focus
              :title="$t('dashboard-settings.names-modal-label-name-help')"
            >
              <i class="far fa-question-circle"></i>
            </a>
          </label>
          <div class="col-sm-8">
            <input
              v-model="facilityName.name"
              type="text"
              required
              class="form-control"
              :placeholder="$t('dashboard-settings.names-modal-label-name')"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            {{ $t("dashboard-settings.names-modal-label-language") }}
          </label>
          <div class="col-sm-8" v-if="mode == 'update'">
            <input
              :value="
                $t(`language-options.${facilityName.language.toLowerCase()}`)
              "
              type="text"
              readonly
              class="form-control"
            />
          </div>
          <div class="col-sm-8" v-if="mode == 'create'">
            <select
              v-model="facilityName.language"
              required
              class="form-control"
            >
              <option value="" selected>--</option>
              <option
                v-for="(code, index) in availableLanguages"
                :value="code"
                :key="`code_${index}`"
              >
                {{ $t(`language-options.${code}`) }}
              </option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          ref="names_form_submit"
          style="display: none"
        ></button>
      </form>
      <template v-slot:modal-footer>
        <div class="text-right">
          <button
            @click="
              () => {
                $refs.names_form_submit.click();
              }
            "
            class="btn btn-warning btn-min ml-3 text-white"
          >
            <i class="fas fa-check mr-1" />
            {{ $t("dashboard-settings.button-save") }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.table {
  td,
  th {
    vertical-align: middle !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  td.controls {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>

<script >
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import facilitiesApi from "@/api/facilities";
import uac from "@/mixins/uac";

export default {
  computed: {
    ...mapGetters("dashboard", ["facility"]),
    names_maxed() {
      try {
        return this.facility.facility_name.length >= 5;
      } catch (e) {
        return true;
      }
    },
    modal_title() {
      return this.mode === "create"
        ? this.$t("common.word-add")
        : this.$t("common.word-edit");
    }
  },
  mixins: [uac],
  data() {
    return {
      submit_btn: "",
      remove_msg: "",
      mode: "create",
      facilityNames: [],
      facilityName: {},
      availableLanguages: [],
      supportedLanguages: ["en", "ja", "ko", "zh-CN", "zh-TW"]
    };
  },
  mounted() {
    this.loadNames();
  },
  methods: {
    ...mapActions("dashboard", ["updateFacilities"]),
    async loadNames() {
      try {
        const facility = await facilitiesApi.get({ with: "user" });
        this.updateFacilities(facility.data);
        this.$nextTick(() => {
          const languages = [];
          const availableLanguages = [];
          this.facility.facility_name.forEach(fn => {
            languages.push(fn.language);
          });
          this.supportedLanguages.forEach(lang => {
            if (!languages.includes(lang)) availableLanguages.push(lang);
          });
          this.availableLanguages = availableLanguages;
        });
      } catch (e) {
        this.$toast.error(this.$("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    showForm(mode, obj) {
      this.facilityName = {};
      this.mode = mode;
      this.$bvModal.show("names_modal");

      if (mode == "update") {
        this.facilityName = _.clone(obj);
      }
    },
    async removeName(id) {
      const data = {
        id: id,
        facility_id: this.facility.id
      };

      try {
        await facilitiesApi.deleteName({ data });
        await this.loadNames();
        this.$bvModal.hide("names_modal");
      } catch (e) {
        this.$bvModal.hide("names_modal");
        this.$toast.error(this.$("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async submitForm() {
      this.facilityName.facility_id = this.facility.id;

      if (this.mode == "create") {
        try {
          await facilitiesApi.addName(this.facilityName);
          await this.loadNames();
          this.$bvModal.hide("names_modal");
        } catch (e) {
          this.$bvModal.hide("names_modal");
          this.$toast.error(this.$("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      }

      if (this.mode == "update") {
        const data = {
          facility_id: this.facility.id,
          name: this.facilityName.name,
          id: this.facilityName.id
        };
        try {
          await facilitiesApi.updateName(data);
          await this.loadNames();
          this.$bvModal.hide("names_modal");
        } catch (e) {
          this.$bvModal.hide("names_modal");
          this.$toast.error(this.$("dashboard-common.error-generic"), {
            position: "top"
          });
        }
      }
    }
  }
};
</script>