<template>
  <div class="d-flex w-100">
    <arrow-step :class="{ active: step == 'user' }">
      <div class="step-content">
        <span class="short">
          <i class="fas fa-user-alt"></i>
        </span>
        <!-- <span class="long">{{ $t("check-in-form.word-input") }}</span> -->
      </div>
    </arrow-step>
    <hr class="flex-grow-1 mx-1" />
    <arrow-step :class="{ active: step == 'facility' }">
      <div class="step-content">
        <span class="short">
          <i class="fas fa-building"></i>
        </span>
        <!-- <span class="long">{{ $t("check-in-form.word-confirm") }}</span> -->
      </div>
    </arrow-step>
    <hr class="flex-grow-1 mx-1" />
    <arrow-step :class="{ active: step == 'complete' }">
      <div class="step-content">
        <span class="short">
          <i class="fas fa-check"></i>
        </span>
        <!-- <span class="long">{{ $t("check-in-form.word-complete") }}</span> -->
      </div>
    </arrow-step>
  </div>
</template>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
.step-content {
  // min-width: 100px;
  min-width: 45px;
}
hr {
  border-top: none;
  padding-top: 2px;
  border-bottom: 1px solid $gray-400;
}
// @media only screen and (max-width: 450px) {
//   .step-content {
//     min-width: 50px;
//   }
//   .long {
//     display: none;
//   }
// }
</style>

<script>
import ArrowStep from "@/components/ArrowStep";

export default {
  components: { ArrowStep },
  props: {
    step: String // 'input', 'confirm', 'complete'
  }
};
</script>