import http, { httpmock } from '@/utils/http';

const endpoint = 'log';

function flattenGuestData (raw) {
  const { facility_id, id, created_at, updated_at, timezone, visitor_id } = raw;
  const data = { facility_id, log_id: id, visitor_id, created_at, updated_at, timezone, }
  for (let key in raw.visitor) {
    if (key === 'user') data['email'] = raw.visitor.user.email;
    else data[key] = raw.visitor[key];
  };
  return data;
}

export default {
  async getGuestDetails (guest_id, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    const facility = $store.getters['dashboard/facility'];
    const response = await http.post(`${endpoint}/get/facility-visitor`, {
      id: guest_id,
      facility_id: facility.id,
      paginate: false,
      version: 2,
    }, config);
    // Flatten the data.
    try {
      return flattenGuestData(response.data.data[0]);
    } catch (e) {
      console.log('Something went wrong.');
      return false;
    }
  },
  async getGuestList (page, per_page, filters = {}) {
    const facility = $store.getters['dashboard/facility'];
    const response = await http.post(`${endpoint}/get/facility-visitor`, {
      facility_id: facility.id,
      paginate: true,
      page, per_page, ...filters,
    });

    // Flatten the data.
    try {
      const data = { data: [] }
      const body = response.data.data;
      for (let key in body) if (key !== 'data') data[key] = body[key];
      for (let item of body.data) data.data.push(flattenGuestData(item));
      return data;
    } catch (e) {
      console.log('Something went wrong.');
    }
  },
  async downloadExcel (params) {
    return await $http.post(`logs/checkin`, null, { params, responseType: 'arraybuffer' });
  },
  async downloadGovID (params) {
    return await $http.post(`${endpoint}/ids-downloads`, null, { params, responseType: 'arraybuffer' });
  },
  async getDownloadHistory (params) {
    const response = await $http.get(`${endpoint}/ids-download-list`, null, { params, responseType: 'arraybuffer' });
    return response.data;
  },
  async deletePassportPhoto (log_id, params, no_loader) {
    const config = no_loader ? { headers: { 'no-loader': true } } : {};
    config.params = params
    const response = await $http.delete(`${endpoint}/delete/${log_id}`, config);
    return response.data;
  },
  async downloadFile (path) {
    return await $http.get(`${endpoint}/download-file?path=`+path);
  },
}