<template>
  <div>
    <h1 class="h3 mb-2 text-gray-800 d-flex justify-content-between">
      {{ $t("dashboard-users.title") }}
      <button
        v-if="hasAccess('setting.user.create')"
        type="button"
        class="btn btn-warning btn-wide"
        @click="showCreate"
      >
        <i class="fas fa-user-plus mr-1"></i>
        {{ $t("dashboard-users.button-create") }}
      </button>
    </h1>
    <!-- <p>{{ $t("dashboard-terms.description") }}</p> -->
    <div class="px-0 pt-3 pb-3 mb-3">
      <table class="table table-bordered hoverable table-sm">
        <thead>
          <tr>
            <th>{{ $t("dashboard-users.label-userid") }}</th>
            <th>{{ $t("dashboard-users.label-email") }}</th>
            <th>{{ $t("dashboard-users.label-last-name") }}</th>
            <th>{{ $t("dashboard-users.label-first-name") }}</th>
            <th>{{ $t("dashboard-users.label-role") }}</th>
            <th>
              {{ $t("dashboard-users.label-action") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="`user_${index}`">
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.user_role ? roleName(user.user_role.id) : '' }}</td>
            <td>
              <button
                v-if="hasAccess('setting.user.edit')"
                class="btn btn-primary btn-round"
                :title="$t('common.word-edit')"
                @click="() => showEdit(user.id)"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                v-if="
                  hasAccess('setting.user.delete') && currentUser.id != user.id
                "
                class="btn btn-outline-danger btn-round ml-1"
                :title="$t('common.word-delete')"
                @click="() => confirmDelete(user.id)"
              >
                <i class="fas fa-minus"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="text-right">
      <button type="submit" class="btn btn-warning btn-wide">
        <i class="fas fa-save mr-1"></i>
        {{ $t("dashboard-common.word-save") }}
      </button>
    </div> -->

    <!-- Modal - Add/Edit User -->
    <user-modal
      :value="userData"
      :mode="editor.mode"
      :roles="roles"
      :facility="facility"
      :show="editor.show"
      @close="editor.show = false"
      @done="done"
    />
  </div>
</template>

<style lang="scss" scoped>
th {
  line-height: 38px;
}
th,
td {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import usersApi from "@/api/users";
import rolesApi from "@/api/roles";
import dialogs from "@/mixins/dialogs";
import uac from "@/mixins/uac";

import UserModal from "./UserModal";

export default {
  beforeMount() {
    if (!this.hasAccess("setting.user.view")) {
      return this.router.replace({ path: "/error-404" });
    }
    const auth = this.$storage.get("auth");
    this.currentUser = auth.user;
  },
  async mounted() {
    this.updateActiveMenu("users");
    this.getRoles();
    await this.listUsers();
  },
  components: { UserModal },
  computed: {
    ...mapState(["languages"]),
    ...mapGetters("dashboard", ["facility"])
  },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        per_page: 100
      },
      userData: {
        id: null,
        username: "",
        password: "",
        email: "",
        first_name: "",
        last_name: "",
        role_id: 1,
        facility_id: null
      },
      currentUser: {},
      editor: {
        mode: "create",
        show: false
      },
      roles: [],
      users: []
    };
  },
  mixins: [dialogs, uac],
  methods: {
    ...mapActions("dashboard", ["updateActiveMenu"]),
    done(operation) {
      if (operation == "create") {
        this.$toast.success(this.$t("dashboard-users.toast-created"), {
          position: "top"
        });
        setTimeout(this.listUsers, 150);
      }
      if (operation == "edit") {
        this.$toast.success(this.$t("dashboard-users.toast-updated"), {
          position: "top"
        });
        setTimeout(this.listUsers, 150);
      }
    },
    showCreate() {
      this.userData = {
        id: null,
        username: "",
        password: "",
        email: "",
        first_name: "",
        last_name: "",
        role_id: 1,
        facility_id: this.facility.id
      };
      this.editor.mode = "create";
      this.editor.show = true;
    },
    showEdit(id) {
      const user = this.users.find(user => user.id === id);
      const data = { ...user };
      data.password = "";
      data.role_id = data.user_role.id;
      delete data.user_role;
      this.userData = data;
      this.editor.mode = "edit";
      this.editor.show = true;
    },
    confirmDelete(id) {
      const user = this.users.find(user => user.id === id);
      const message = `
        ${this.$t("dashboard-users.action-delete")}
        <p class="text-monospace">
          ${this.$t("dashboard-users.label-userid")}:
          <span class="text-danger">${user.username}</span>
        </p>
      `;
      this.confirm(message, {
        yes: () => this.doDelete(id)
      });
    },
    async doDelete(id) {
      try {
        const params = { facility_id: this.facility.id };
        await usersApi.delete(id, params);
        this.$toast.success(this.$t("dashboard-users.toast-deleted"), {
          position: "top"
        });
        setTimeout(this.listUsers, 150);
      } catch (e) {
        console.error("users -> UserModal -> doDelete", e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async listUsers() {
      try {
        const params = { facility_id: this.facility.id, ...this.pagination };
        const response = await usersApi.list(params);
        this.users = response.data.data;
        this.pagination.page = response.data.current_page;
      } catch (e) {
        console.error("users -> listUsers", e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    async getRoles() {
      try {
        const params = { facility_id: this.facility.id };
        const response = await rolesApi.list(params);
        this.roles = response.data;
      } catch (e) {
        console.error("users -> getRoles", e.message);
        this.$toast.error(this.$t("dashboard-common.error-generic"), {
          position: "top"
        });
      }
    },
    roleName(id) {
      const role = this.roles.find(r => r.id == id);
      return this.$t(`dashboard-users.role-${role.name}`);
    }
  }
};
</script>
