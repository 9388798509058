import Vue from 'vue'
import i18n from '@/i18n'
import axios from 'axios'
import t from 'typy';

axios.defaults.withCredentials = true

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`
})

http.interceptors.request.use(
  (config) => {
    const noLoader = config.headers['no-loader'] !== true;
    if (noLoader) $store.commit('checkin-site/loading', true);
    config.headers['Lang'] = `${i18n.locale}`

    if (Vue.$storage.has('auth')) {
      config.headers['Authorization'] = `Bearer ${Vue.$storage.get('auth').token}`
    }

    return config
  },

  (error) => { return Promise.reject(error) }
)

function inArray (needle, haystack = []) {
  let found = false;
  for (let hay of haystack) {
    if (needle == hay) { found = true; break; }
  }
  return found;
}

// Response
http.interceptors.response.use(
  (response) => {
    try {
      // if (response.data && response.data.data.access_token) {
      //     //auto refresh auth token when change on each request
      //     localStorage.setItem('auth', JSON.stringify(response.data.data));
      // }    
    } catch (e) {
      console.info(e)
    }

    $store.commit('checkin-site/loading', false);
    return response
  },
  (error) => {
    let exludeCodes = [];
    let redirect = '';
    const hasExclude = t(error, 'response.config.exclude-status').isArray;
    if (hasExclude) exludeCodes = error.response.config['exclude-status'];
    if (error.response.status === 401) {
      if (!inArray(401, exludeCodes)) {
        Vue.$storage.remove('auth')
        redirect = '/error-401'
      }
    }
    if (error.response.status === 429) {
      if (!inArray(429, exludeCodes)) {
        redirect = '/error-429'
      }
    }
    if (redirect) return window.location = redirect;
    $store.commit('checkin-site/loading', false);
    return Promise.reject(error)
  }
)

const httpmock = function (data, no_loader) {
  return new Promise((resolve, reject) => {
    if (data) {
      if (!no_loader) $store.commit('checkin-site/loading', true);
      setTimeout(() => {
        resolve(data);
        $store.commit('checkin-site/loading', false);
      }, 1000)
    } else {
      reject('Sample data is required');
    }
  });
}

export default http
export { httpmock }
