<template>
  <!-- Step 3: Hotel checkin form -->
  <div>
    <form @submit.prevent="goNext" novalidate>
      <div class="nav-buttons">
        <circle-nav-button direction="prev" @click="goBack" />
      </div>
      <div class="d-flex justify-content-center py-3">
        <checkin-stepper step="input" />
      </div>
      <div class="mb-2">
        <!-- Check-in & check-out dates -->
        <b-card class="mb-2">
          <table>
            <tr>
              <td>{{ $t("check-in-form.check-in-date") }}</td>
              <td class="px-1">:</td>
              <td>{{ checkInDateTime | formatDate }}</td>
            </tr>
            <tr>
              <td>{{ $t("check-in-form.check-out-date") }}</td>
              <td class="px-1">:</td>
              <td>{{ checkOutDateTime | formatDate }}</td>
            </tr>
          </table>
        </b-card>
        <!-- Main Guest Card -->
        <p class="mb-2 mt-3 font-weight-bold">
          {{ $t("check-in-form.title-main-guest-form") }}
        </p>
        <b-card
          class="accordion-card"
          :class="{ collapsed: mainGuest.collapsed }"
        >
          <template #header>
            <div
              class="click-overlay"
              @click="mainGuest.collapsed = !mainGuest.collapsed"
            ></div>
            <span class="text-info"><i class="far fa-user-circle" /></span>
            {{ $t("check-in-form.label-main-guest") }}
            <div class="controls px-2">
              <button
                type="button"
                class="btn text-primary"
                title="toggle"
                style="margin-top: 5px"
                @click="mainGuest.collapsed = !mainGuest.collapsed"
              >
                <i class="toggle-icon fas fa-angle-up" />
              </button>
            </div>
          </template>
          <div class="body-wrapper">
            <template v-for="(question, index) in questions">
              <div v-if="question.visibility == 1">
                <!-- Legacy field -->
                <question-field
                  v-if="question.system_name == 'email'"
                  v-model="mainGuest.answers[question.system_name]"
                  :id="`input_${question.id}`"
                  :key="`q${index}`"
                  :question="question"
                  :form_mask="needFormMasking(question.system_name)"
                  :error="mainGuest.errors[question.id]"
                  @input="mainGuest.errors[question.id] = updateErrors(question, $event)"
                />
                <!-- Common field (exclude special fields) -->
                <question-field
                  v-else-if="
                    question.system_name !== 'signature' &&
                    question.system_name !== 'face_recognition'
                  "
                  v-model="mainGuest.answers.other_form[question.id]"
                  :id="`input_${question.id}`"
                  :key="`q${index}`"
                  :question="question"
                  :form_mask="needFormMasking(question.system_name)"
                  :error="mainGuest.errors[question.id]"
                  @input="mainGuest.errors[question.id] = updateErrors(question, $event)"
                  @search-zip="autofillAddrFromZip"
                />
              </div>
            </template>
          </div>
        </b-card>
        <!-- Companion Cards -->
        <p v-if="companions.length > 0" class="mb-0 mt-3 font-weight-bold">
          {{ $t("check-in-form.title-companion") }}
            <a
              v-if="hasCompanionQuestion"
              tabindex="1"
              class="tip text-primary"
              v-b-tooltip.focus
              :title="$t('check-in-form.instruction-companion')"
            >
              <i class="far fa-question-circle" />
            </a>
        </p>
        <div v-if="companions.length > 0">
          <small>
            {{ hasCompanionQuestion
              ? $t("check-in-form.instruction-companion")
              : $t("check-in-form.instruction-no-companion-question")
            }}
          </small>
        </div>
          <companion-card
            v-for="(companion, index) of companions"
            :data="companions"
            :index="index"
            :questions="questions"
            :key="`c_${index}`"
            :collapsed.sync="companions[index].collapsed"
            :closing="companions[index].closing"
            :hasCompanionQuestion="hasCompanionQuestion"
            @remove="() => reduceCompanionsHere(index)"
            @copy="copyQuestionFromMain"
            @search-zip="autofillAddrFromZip"
            class="my-2"
          />
        <!-- Tour Leaders Cards -->
        <!-- <p v-show="roomLeaders.length > 0" class="mb-2 mt-3 font-weight-bold">
          {{ $t("check-in-form.title-room-leader") }}
          <a
            tabindex="1"
            class="tip text-primary"
            v-b-tooltip.focus
            :title="$t('check-in-form.instruction-room-leader')"
          >
            <i class="far fa-question-circle" />
          </a>
        </p>
        <b-card
          v-for="(leader, index) in roomLeaders"
          :key="`r${index}`"
          class="accordion-card mb-2"
          :class="{ collapsed: roomLeaders[index].collapsed }"
        >
          <template #header>
            <div
              class="click-overlay"
              @click="
                roomLeaders[index].collapsed = !roomLeaders[index].collapsed
              "
            ></div>
            <span class="text-info"><i class="far fa-user-circle" /></span>
            {{ $t("check-in-form.word-room") }} # {{ Number(index + 2) }}
            <div class="controls px-2 py-1">
              <button
                type="button"
                class="btn text-primary"
                title="toggle"
                style="margin-top: 5px"
                @click="
                  roomLeaders[index].collapsed = !roomLeaders[index].collapsed
                "
              >
                <i class="toggle-icon fas fa-angle-up" />
              </button>
              <button
                class="btn text-danger"
                type="button"
                title="remove"
                @click="() => roomLeaders.splice(index, 1)"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </template>
          <div class="body-wrapper">
            <text-field
              :label="$t('check-in-form.word-name')"
              v-model="roomLeaders[index].answers.name"
              :placeholder="$t('check-in-form.placeholder-name')"
              :error="roomLeaders[index].errors.name"
            />
            <text-field
              :label="$t('check-in-form.word-email')"
              type="email"
              placeholder="user@domain.net"
              v-model="roomLeaders[index].answers.email"
              :error="roomLeaders[index].errors.email"
            />
          </div>
        </b-card> -->
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-min">
          {{ $t("check-in-form.word-next") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import * as EmailValidator from "email-validator";
import t from "typy";
import questionsApi from "@/api/questions";
const postal_code = require("jp-postalcode-lookup");

import CheckinStepper from "@/components/CheckinStepper";
import CircleNavButton from "@/components/CircleNavButton";
import CompanionCard from "./CompanionCard";
import QuestionField from "@/components/QuestionField";
import SelectField from "@/components/form-fields/SelectField";
import TextField from "@/components/form-fields/TextField";
import scrollers from "@/mixins/scrollers";
import { questionsForMainGuest } from "../../../utils/constants/questions_for_main_guest";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    CheckinStepper,
    CircleNavButton,
    CompanionCard,
    SelectField,
    QuestionField,
    TextField
  },
  computed: {
    ...mapState("checkin", [
      "countryCode",
      "phoneNumber",
      "initialized",
      "bookingData",
      "companionsLeft",
      "enabledFaceCapture",
      "requiredFaceCapture",
      "enabledSignature",
      "requiredSignature",
      "facility",
      "filtersIndex",
      "questions",
      "questionMap",
      "localizedFilters",
      "savedAnswers",
      "savedRoomLeaders"
    ]),
    ...mapGetters("checkin", [
      "checkInDateTime",
      "checkOutDateTime",
      "localizedFilter",
      "companionCount",
      "companionsAdded",
      "maxGuest",
      "maxRoom"
    ]),
    locale() {
      return this.$i18n.locale;
    }
  },
  mixins: [scrollers],
  beforeMount() {
    // Ensure facility is set.
    if (!this.facility.id) return this.$router.replace({ path: "/error-404" });
    if (!this.initialized) this.initialize();
    if(this.facility.confirmBooking === true){
      const facility_setting = this.facility.facility_setting.find(d => d.name == "form");
      this.form_mask = facility_setting.value.form_mask_method == 1 ? true : false;
    }
  },
  beforeRouteLeave(to, from, next) {
    const newData = { ...this.savedAnswers, ...this.mainGuest.answers };
    newData.companions = this.extractCompanionLocalAnswers();

    this.updateSavedAnswers(newData);
    this.updateSavedRoomLeaders(this.extractRoomLeaderLocalAnswers());
    sessionStorage.landed = true;
    next();
  },
  async mounted() {
    this.initQuestionSettings();
    this.updateQuestionMap(this.buildQuestionMap(this.questions));
    this.$nextTick(() => {
      this.populateMainGuest();
      this.populateCompanions();
      this.populateRoomLeaders();
      this.scrollToTop();
    });
  },
  data() {
    return {
      mainGuest: {
        answers: { other_form: {} },
        errors: {},
        validations: {},
        collapsed: false
      },
      companions: [],
      roomLeaders: [],
      hasCompanionQuestion: false,
      form_mask: false,
    };
  },
  filters: {
    formatDate(date) {
      return moment.tz(date, "utc").format("YYYY/MM/DD");
    }
  },
  methods: {
    ...mapActions("checkin", [
      "initialize",
      "reduceCompanions",
      "updateEnabledFaceCapture",
      "updateEnabledSignature",
      "updateRequiredFaceCapture",
      "updateRequiredSignature",
      "updateSavedAnswers",
      "updateQuestions",
      "updateQuestionMap",
      "updateSavedRoomLeaders"
    ]),
    needFormMasking(system_name){
      const result = this.form_mask && this.bookingData.need_masking_keys.includes(system_name)
      return result;
    },
    updateErrors(question, value){
      if(question.required) return value ? '' : this.mainGuest.errors[question.id];
    },
    questionsForMainGuest(){
      return questionsForMainGuest;
    },
    autofillAddrFromZip(v, index) {
      try {
        postal_code.get(v, (address) => {
          const prefectureId = String(address.prefectureId);
          const addressLine = `${address.city} ${address.area} ${address.street}`;
          const prefectureQstn = this.findQuestionBySysName('prefecture');
          const addressQstn = this.findQuestionBySysName('address');

          if (index != undefined) { // Update companion values
            const companionData = { ...this.companions[index] };
            if (prefectureQstn) companionData.answers.other_form[prefectureQstn.id] = prefectureId;
            if (addressQstn) companionData.answers.other_form[addressQstn.id] = addressLine;
            this.$set(this.companions, index, companionData);
          } else { // Update main guest values
            const mainGuestAnswers = this.mainGuest.answers.other_form;
            if (prefectureQstn) this.$set(mainGuestAnswers, prefectureQstn.id, prefectureId);
            if (addressQstn) this.$set(mainGuestAnswers, addressQstn.id, addressLine);
          }
          this.$toast.info(this.$t("check-in-form.message-zip-autofill"), {
            position: "top"
          });
        });
      } catch(e) {
        this.$toast.info(this.$t("check-in-form.message-zip-not-found"), {
          position: "top"
        });
      }
    },
    buildQuestionMap(questionList) {
      const questionMap = {};
      questionList.forEach(question => {
        const { input_type, label, rank, system_name, is_core } = question;
        questionMap[question.id] = {
          input_type,
          label,
          rank,
          system_name,
          is_core
        };
      });
      return questionMap;
    },
    copyQuestionFromMain(index, datakey) {
      if (Number.isInteger(Number(datakey))) {
        const mainAnswer = this.mainGuest.answers.other_form[datakey];
        this.companions[index].answers.other_form[datakey] = mainAnswer;
      } else {
        const mainAnswer = this.mainGuest.answers[datakey];
        this.companions[index].answers[datakey] = mainAnswer;
      }
    },
    canKeepSavedData() {
      if (sessionStorage.landed) return true;
      if (sessionStorage.locale == this.locale) return true;
      return false;
    },
    goBack() {
      this.$router.replace({ name: "checkin-step-2" });
    },
    goNext() {
      const isMainGuestValid = this.validateMainGuestInput();
      const isCompanionValid = this.validateCompanionsInput();
      const isTourLeadersValid = this.validateTourLeadersInput();

      if (isMainGuestValid && isCompanionValid && isTourLeadersValid) {
        if (this.enabledFaceCapture)
          this.$router.replace({ name: "checkin-step-4" });
        else if (this.enabledSignature)
          this.$router.replace({ name: "checkin-step-5" });
        else this.$router.replace({ name: "checkin-step-6" });
      } else {
        this.$nextTick(() => {
          this.scrollToClass("errored");
          this.$toast.error(this.$t("check-in-form.error-form-check"), {
            position: "top"
          });
        });
      }
    },
    buildDataReferenceBags(collapsed = false, isCompanion = false) {
      // Returns key-value of question_id as key and placeholders for.
      const answers = { other_form: {} };
      let errorsBag = {};
      let validationsBag = {};
      const skipQuestions = ["signature", "face_recognition"];
      this.questions.forEach(question => {
        // Populate data references.
        if(question.visibility == 0 && !isCompanion) skipQuestions.push(question.system_name);
        if(question.visibility_companion == 0 && isCompanion) skipQuestions.push(question.system_name);
        if(questionsForMainGuest.includes(question.system_name) && isCompanion) skipQuestions.push(question.system_name);
        if (question.system_name == "email") answers[question.system_name] = "";
        else if (!skipQuestions.includes(question.system_name)) {
          let defaultAnswer = "";
          if (question.system_name == "dob") defaultAnswer = null;
          if (question.system_name == "age") defaultAnswer = null;
          if (question.system_name == "receipt_address") defaultAnswer = "0";
          answers.other_form[question.id] = defaultAnswer;
        }
        // Populate error and validation references.
        if (!skipQuestions.includes(question.system_name)) {
          errorsBag[question.id] = "";
          validationsBag[question.id] = {
            datakey:
              question.system_name == "email"
                ? question.system_name
                : question.id,
            types: []
          };
          if ((question.required && !isCompanion) || (question.required_companion && isCompanion))
            validationsBag[question.id].types.push("required");
          if (question.system_name == "email")
            validationsBag[question.id].types.push("email");
        }
      });
      return {
        answers,
        errors: errorsBag,
        validations: validationsBag,
        collapsed: collapsed,
        closing: false
      };
    },
    buildRoomLeadersDataReferenceBags() {
      return {
        answers: { name: "", email: "" },
        errors: { name: "", email: "" },
        validations: { name: [], email: ["email"] },
        collapsed: false
      };
    },
    initQuestionSettings() {
      // Update vuex if face recognition or signature is enabled.
      this.questions.forEach(question => {
        if (question.system_name == "face_recognition") {
          this.updateEnabledFaceCapture(question.visibility == true);
          this.updateRequiredFaceCapture(question.visibility == true && question.required == true);
        } else if (question.system_name == "signature") {
          this.updateEnabledSignature(question.visibility == true);
          this.updateRequiredSignature(question.visibility == true && question.required == true);
        }
      });
    },
    extractCompanionLocalAnswers() {
      const answers = [];
      this.companions.forEach(companion => {
        answers.push(companion.answers);
      });
      return answers;
    },
    extractRoomLeaderLocalAnswers() {
      const answers = [];
      this.roomLeaders.forEach(leader => {
        const answer = leader.answers;
        const name = String(answer.name).trim();
        const email = String(answer.email).trim();
        if (name.length > 0 && email.length > 0) answers.push(answer);
      });
      return answers;
    },
    findQuestionBySysName(sysName) {
      return this.questions.find((question) => question.system_name == sysName);
    },
    populateMainGuest(clear = false) {
      // Generate body template.
      const mainGuest = this.buildDataReferenceBags();
      const answers = { ...mainGuest.answers };
      const autofills = [
        "email",
        "full_name",
        "full_name_for_katakana",
        "address",
        "zip_code",
        "prefecture",
      ]; // system_name only, not applicable to meta keys
      // Apply saved answers found in vuex or booking to local state.
      Object.keys(this.questionMap).forEach(key => {
        const question = this.questionMap[key];
        const isEmail = (question.system_name == "email");
        const isPhone = (question.system_name == "phone");
        const metaKey = this.resolveMetaKey(question.system_name);
        const isMetaPresent = t(this.bookingData, `meta.${metaKey}`).isDefined;
        const isSavedAnswerPresent = t(this.savedAnswers, `other_form.${key}`).isDefined;

        if (isEmail) {
          let email = null;
          if (this.savedAnswers.email) email = this.savedAnswers.email;
          answers.email = email;
        } else if (isPhone) {
          if (isSavedAnswerPresent) answers.other_form[key] = this.savedAnswers.other_form[key];
          else {
            const answer = this.bookingData['meta']['phone'] ? this.bookingData['meta']['phone']?.replace('+', '') : '';
            answers.other_form[key] = answer;
          }
        } else if (autofills.includes(question.system_name)) {
          if (isSavedAnswerPresent) answers.other_form[key] = this.savedAnswers.other_form[key];
          else if (isMetaPresent) {
            const answer = this.bookingData.meta[metaKey] ? String(this.bookingData.meta[metaKey]) : '';
            answers.other_form[key] = answer;
          }
        } else if(isSavedAnswerPresent) {
          answers.other_form[key] = this.savedAnswers.other_form[key];
        }
      })
      mainGuest.answers = answers;
      this.mainGuest = mainGuest;
    },
    populateCompanions(clear = false) {
      // Generate template based on companion count.
      const companions = [];
      for (let i = 0; i < this.companionsLeft; i++) {
        const data = this.buildDataReferenceBags(false, true);
        companions.push(data);
      }
      // Apply saved answers found in vuex to local state.
      if (
        !clear &&
        this.savedAnswers.companions &&
        Array.isArray(this.savedAnswers.companions) &&
        this.canKeepSavedData()
      ) {
        for (let index in this.savedAnswers.companions) {
          const answers = this.savedAnswers.companions[index];
          companions[index].answers = answers;
        }
      }
      this.companions = companions;
      this.hasCompanionQuestion = this.questions.some(question => question['visibility_companion'] == 1 && !questionsForMainGuest.includes(question['system_name']));
    },
    populateRoomLeaders() {
      // Generate template & apply answers from vuex if found.
      const roomLeaders = [];
      for (let i = 0; i < this.maxRoom - 1; i++) {
        const data = this.buildRoomLeadersDataReferenceBags();
        if (this.savedRoomLeaders[i] && this.canKeepSavedData()) {
          data.answers = this.savedRoomLeaders[i];
        }
        roomLeaders.push(data);
      }
      this.roomLeaders = roomLeaders;
    },
    reduceCompanionsHere(index) {
      this.companions[index].closing = true;
      setTimeout(() => {
        this.companions.splice(index, 1);
        this.reduceCompanions();
      }, 520);
    },
    resolveMetaKey(systemName) {
      // Resolve system name into key used in metadata
      if (systemName == "zip_code") return "postal_code";
      return systemName;
    },
    validateMainGuestInput() {
      const errors = {};
      let errorCount = 0;
      // 会社名の質問を確認
      const companyNameQuestion = Object.values(this.mainGuest.validations).find((validation) => {
        return this.questionMap[validation.datakey] ? this.questionMap[validation.datakey].system_name == "company_name" : false
      })
      const companyNameQuestionValue = companyNameQuestion ? this.mainGuest.answers.other_form[
            companyNameQuestion.datakey
          ] : null;
      Object.keys(this.mainGuest.validations).forEach(index => {
        const validation = this.mainGuest.validations[index];
        errors[index] = "";
        if (validation.datakey == "email") {
          const inputRaw = this.mainGuest.answers[validation.datakey];
          const inputString = t(inputRaw).isString ? inputRaw : '';
          if (inputString.trim().length < 1 && validation.types.includes("required")) {
            errors[index] = this.$t("check-in-form.error-field-required");
            errorCount++;
          } else if (inputString.trim().length > 0 && validation.types.includes("email")) {
            const validEmail = EmailValidator.validate(inputString);
            if (!validEmail) {
              errors[index] = this.$t("check-in-form.error-field-invalid");
              errorCount++;
            }
          }
        } else {
          const questionItem = this.questionMap[validation.datakey];
          const inputString = this.mainGuest.answers.other_form[
            validation.datakey
          ];
          // inputStringがnullの場合も対応するため
          if (!(inputString?.length > 0) && validation.types.includes("required")) {
            errors[index] = this.$t("check-in-form.error-field-required");
            errorCount++;
          } else if (questionItem.system_name == "zip_code" && inputString.trim().length > 0) {
            if (!/^[\d\-A-Z]+$/.test(inputString)) {
              errors[index] = this.$t("check-in-form.error-field-invalid");
              errorCount++;
            }
          }
          if(questionItem.system_name == "receipt_address" && inputString == 2 && companyNameQuestionValue.trim().length <= 0){
            errors[companyNameQuestion.datakey] = this.$t("check-in-form.error-required-company-name-for-receipt-address");
            errorCount++;
          }
          if(questionItem.system_name == "receipt_address" && inputString == 0 && validation.types.includes("required")){
            errors[index] = this.$t("check-in-form.error-field-required");
            errorCount++;
          }
        }
      });
      this.mainGuest.errors = { ...this.mainGuest.errors, ...errors };
      if (errorCount > 0) this.mainGuest.collapsed = false;
      return errorCount < 1;
    },
    validateCompanionsInput() {
      let errorCount = 0;

      for (let companionIndex in this.companions) {
        let companionErrorCount = 0;
        const errors = {};
        const companion = this.companions[companionIndex];
        Object.keys(companion.validations).forEach(index => {
          const validation = companion.validations[index];
          errors[index] = "";
          if (validation.datakey == "email") {
            const inputRaw = companion.answers[validation.datakey];
            const inputString = t(inputRaw).isString ? inputRaw : '';
            if (inputString.trim().length < 1 && validation.types.includes("required")) {
              errors[index] = this.$t("check-in-form.error-field-required");
              errorCount++;
              companionErrorCount++;
            } else if (inputString.trim().length > 0 && validation.types.includes("email")) {
              const validEmail = EmailValidator.validate(inputString);
              if (!validEmail) {
                errors[index] = this.$t("check-in-form.error-field-invalid");
                errorCount++;
                companionErrorCount++;
              }
            }
          } else {
            const questionItem = this.questionMap[validation.datakey];
            const inputRaw = companion.answers.other_form[validation.datakey];
            // const inputString = t(inputRaw).isString ? inputRaw : '';
            const inputString = companion.answers.other_form[validation.datakey];
            // inputStringがnullの場合も対応するため
            if (!(inputString?.length > 0) && validation.types.includes("required")) {
              errors[index] = this.$t("check-in-form.error-field-required");
              errorCount++;
              companionErrorCount++;
            } else if (questionItem.system_name == "zip_code" && inputString.trim().length) {
              if (!/^[\d\-A-Z]+$/.test(inputString)) {
                errors[index] = this.$t("check-in-form.error-field-invalid");
                errorCount++;
              }
            }
          }
          if (companionErrorCount > 0) companion.collapsed = false;
        });
        this.companions[companionIndex].errors = {
          ...companion.errors,
          ...errors
        };
      }

      return errorCount < 1;
    },
    validateTourLeadersInput() {
      let errorCount = 0;

      for (let leaderIndex in this.roomLeaders) {
        const errors = {};
        const roomLeader = this.roomLeaders[leaderIndex];
        Object.keys(roomLeader.validations).forEach(index => {
          const inputData = roomLeader.answers[index].trim();
          if (
            inputData.length < 1 &&
            roomLeader.validations[index].includes("required")
          ) {
            errors[index] = this.$t("check-in-form.error-field-required");
            errorCount++;
          } else if (
            inputData.length > 0 &&
            roomLeader.validations[index].includes("email")
          ) {
            const validEmail = EmailValidator.validate(inputData);
            if (!validEmail) {
              errors[index] = this.$t("check-in-form.error-field-invalid");
              errorCount++;
            }
          }
        });
        this.roomLeaders[leaderIndex].errors = {
          ...this.roomLeaders[leaderIndex].errors,
          ...errors
        };
      }

      return errorCount < 1;
    }
  },
  watch: {
    async locale() {
      // Reference to which language saved state answers are for.
      sessionStorage.locale = this.locale;

      const options = { facility_id: this.facility.id, language: this.locale };
      const filter = this.localizedFilters[this.filtersIndex];

      if (filter) options.facilities_localized_category_id = filter.id;
      const questions = await questionsApi.get(options);
      this.updateQuestions(questions.data);
      this.initQuestionSettings();
      this.updateQuestionMap(this.buildQuestionMap(questions.data));

      this.$nextTick(() => {
        this.populateMainGuest(true);
        this.populateCompanions(true);
        this.populateRoomLeaders();
        this.scrollToTop();
      });
    }
  }
};
</script>
