<template>
  <div class="center-form">
    <div class="d-flex justify-content-end mb-4 px-3 py-2">
      <div class="language-select">
        <a
          class="small"
          :class="{ active: $i18n.locale == 'en' }"
          @click="switchLang('en')"
        >
          English
        </a>
        <span class="px-1">|</span>
        <a
          class="small"
          :class="{ active: $i18n.locale == 'ja' }"
          @click="switchLang('ja')"
        >
          日本語
        </a>
      </div>
    </div>
    <div class="box mb-4">
      <header class="header">
        <slot name="header" />
      </header>
      <main class="border border-primary rounded p-3 m-3">
        <slot />
      </main>
      <footer>
        <slot name="footer" />
      </footer>
      <slot name="overlay" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "~@/assets/scss/variables";

.center-form {
  .language-select a {
    display: inline-block;
    text-align: center;
    min-width: 65px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 3.2px;
    &.active {
      color: white;
      background-color: $primary;
    }
    &:hover {
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
  min-height: 100vh;
  .box {
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>

<script>
import translation from "@/mixins/translation";

export default {
  mixins: [ translation ],
  beforeMount() {
    this.autoSetLanguage(["ja", "en"]);
  },
  methods: {
    switchLang(code) {
      this.$i18n.locale = code;
    }
  }
};
</script>
